import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import jsonAppData from 'utils/jsonAppData';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from 'context/auth';

const AdditionalPageNavigation = ({
	verticalLayoutState,
}) => {
	const { t } = useTranslation('additionalPageNavigation');
	const navigate = useNavigate();
	const location = useLocation();
	const { currentUser } = useContext(AuthContext);

	const pageNavClasses = classNames('additional_page_nav__list page_nav offset_mod', {
		vertical_mod: verticalLayoutState,
	});

	const handleClick = (url, label) => {
		if (label === 'inquireOrder') {
			if (currentUser) {
				navigate('/summary');
			} else {
				navigate('/get-my-glass');
			}

			return;
		}

		navigate(url);
	};

	return (
		<div className="additional_page_nav">
			<ul className={pageNavClasses}>
				{jsonAppData.additionalPageNavigation.map(({
					label,
					url,
				}, index) => {
					let isActive;

					if (label === 'inquireOrder' && (location.pathname === '/summary' || location.pathname === '/get-my-glass')) {
						isActive = true;
					} else {
						if (url === location.pathname) {
							isActive = true;
						}
					}

					const isActiveClassName = isActive ? 'additional_page_nav__link--active_state' : '';

					return (
						<li className="additional_page_nav__item" key={index}>
							<button
								className={`additional_page_nav__link ${isActiveClassName}`}
								type="submit"
								onClick={() => handleClick(url, label)}
							>
								{t(label)}
							</button>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default AdditionalPageNavigation;
