import React from 'react';

const ClientBlockInfo = ({
	title,
	text,
	type,
}) => {
	const renderInfoText = () => {
		const textValue = text || '';

		if (type === 'tel' || type === 'mailto') {
			const linkHref = `${type}:${textValue}`;

			return (
				<a href={linkHref} className="client_block_info__text">{textValue}</a>
			);
		}

		return (
			<div className="client_block_info__text">
				{textValue}
			</div>
		);
	};

	return (
		<div className="client_block_info">
			<div className="client_block_info__title">{title || ''}</div>
			{renderInfoText()}
		</div>
	);
};

export default ClientBlockInfo;
