import React, { useEffect, useState } from 'react';
import { DEFAULT_GLASS_SIZES, replaceZerosWithDash } from 'domain/glass';

import { View } from '@react-pdf/renderer';
import PdfGlassConfigTableRow from './PdfGlassConfigTableRow';
import PdfGlassConfigTableHeadingRow from './PdfGlassConfigTableHeadingRow';
import PdfGlassConfigTotal from './PdfGlassConfigTotal';

import styles from '../styles';

const PdfGlassConfigTable = ({
	configTableData,
	glassIndex,
	totalValues,
}) => {
	const [total, setTotal] = useState(DEFAULT_GLASS_SIZES);

	useEffect(() => {
		if (totalValues?.length) {
			const totalSum = totalValues.reduce((acc, {
				quantity, m2, kgPos, price,
			}) => {
				// totals for this glass type
				return {
					quantity: acc.quantity + quantity,
					m2: acc.m2 + m2,
					kg: acc.kg + kgPos,
					price: (price !== null ? acc.price + price : null),
				};
			}, {
				quantity: 0, m2: 0, kg: 0, price: 0,
			});

			setTotal(replaceZerosWithDash(totalSum));
		} else {
			setTotal(DEFAULT_GLASS_SIZES);
		}
	}, [totalValues]);

	return (
		<View>
			<View style={styles.glassConfigTableList}>
				{configTableData.positions && configTableData.positions.length ? (
					<>
						<PdfGlassConfigTableHeadingRow />
						<View style={styles.block}>
							{configTableData.positions.map((positionRow, rowIndex) => {
								const updatedRowIndex = rowIndex + 1;
								const positionIndex = glassIndex !== undefined ? `${glassIndex}.${updatedRowIndex}` : `1.${updatedRowIndex}`; // 1.1, 1.2 etc

								const shouldRenderRow = positionRow.row.every(
									item => !(item.name === "length" && (item.value === "" || item.value === "-")),
								);

								if (!shouldRenderRow) return null;

								return (
									<PdfGlassConfigTableRow
										key={rowIndex}
										tableRow={positionRow}
										attrKey={rowIndex}
										rowIndex={rowIndex}
										positionIndex={positionIndex}
										totalValues={totalValues?.[rowIndex]}
									/>
								);
							})}
						</View>
						<PdfGlassConfigTotal {...total} />
					</>
				) : null}
			</View>
		</View>
	);
};

export default PdfGlassConfigTable;
