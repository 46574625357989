import React, { useContext } from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { ConfigurationCodeForm } from 'components/Form';
import { UiContext } from 'context/ui';

const ConfigurationCodePopup = ({
	isOpen,
}) => {
	const { showPopupByKey } = useContext(UiContext);

	const closePopup = () => {
		showPopupByKey();
	};

	const onPopupEntering = () => {
		document.body.classList.add('filter_mod');
	};

	const onPopupExit = () => {
		document.body.classList.remove('filter_mod');
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			onEntering={onPopupEntering}
			onExit={onPopupExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in v2_mod">
					<button className="popup_close" type="button" onClick={closePopup}>
						<CloseIcon className="icon icon-close size_mod" />
					</button>
					<ConfigurationCodeForm />
				</div>
			</div>
		</CSSTransition>
	);
};

export default ConfigurationCodePopup;
