import { selectStyles } from 'utils/react-select-styles.js';
import AbstractUIElement from '../elementsUI/AbstractUIElement.js'

export default class FilterUIElement extends AbstractUIElement {
    constructor(props) {
        super(props, 'filters');
        this.filter = null;
        this.selectStyles = selectStyles;
    }

    setFilterHandler(filterHandler) {
        this.filter = filterHandler;
        this.options = filterHandler.filterOptions;
        if (!filterHandler.hasDefaultOption) {
            this.defaultOption = null;
        }
        this.recreate();
    }

    t(str, i18n_section = null) {
        return super.t(this.filter.i18n_prefix + str, i18n_section);
    }

    handleSelectChange = (selectedOption) => {
        this.set(selectedOption.value);
    }

    generateOptions() {
        return [
            ...(this.defaultOption !== null ? [this.defaultOption] : []),
            ...Object.keys(this.options).map(k => {
                return {
                    value: k,
                    label: this.t(this.options[k]).replace('{}', k),
                };
            }),
        ];
    }

    recreate(select_value = undefined) {
        super.recreate(select_value);
        let options = this.generateOptions();
        this.updateState({ options, selectedValue: select_value });
    }

    selectValue(selectedValue) {
        if (selectedValue !== this.state.selectedValue) {
            this.setState({ selectedValue });
        }
    }

    set(select_value) {
        let previouslySet = this.filter.selectedFilter !== null;
        this.filter.setFilter(select_value);
        this.props.uiHandlerRef.current.onFilterChanged(this.filter, previouslySet);
    }
}