import React, { useContext } from "react";
import { ReactComponent as PdfIcon } from "i/icons/pdf.svg";
import { useTranslation } from "react-i18next";
import { PDFDownloadLink } from "@react-pdf/renderer";
import {
	PdfConfigurationsDocument,
	PdfGlassDocument,
} from "components/PdfComponents";
import { UiContext } from "context/ui";
import { getCurrentDate } from "utils";

const SaveAsBlock = ({
	totalValuesSum, totalValues, pdfData, isHomePage,
}) => {
	const { t } = useTranslation("commonConfigurationAction");
	const { showPopupByKey } = useContext(UiContext);

	const showResetGlassPopup = () => {
		showPopupByKey("resetGlass");
	};
	const showResetConfigurationPopup = () => {
		showPopupByKey("resetConfiguration");
	};

	const renderPDFLink = () => {
		const currentDate = getCurrentDate();
		const document = isHomePage ? (
			<PdfGlassDocument glassData={pdfData} />
		) : (
			<PdfConfigurationsDocument
				configuration={pdfData}
				totalValuesSum={totalValuesSum}
				totalValues={totalValues}
			/>
		);
		const documentFileName = (
			isHomePage ? t("glassDotPdfFmt") : t("configurationDotPdfFmt")
		).replace("{date}", currentDate); // TODO: maybe add glass description / config code

		return (
			<PDFDownloadLink
				document={document}
				fileName={documentFileName}
				className="save_as_block__pdf_button"
			>
				{() => {
					return (
						<>
							<div className="save_as_block__icon">
								<PdfIcon className="icon icon-pdf size_mod" />
							</div>
							<span>{t("saveAsPdf")}</span>
						</>
					);
				}}
			</PDFDownloadLink>
		);
	};

	return (
		<div className="save_as_block">
			<ul className="save_as_block__list">
				{isHomePage && (
					<li className="save_as_block__list_item">
						<button
							className="btn_v4 save_as_block__glass_button"
							type="button"
							onClick={showResetGlassPopup}
						>
							{t("resetGlass")}
						</button>
					</li>
				)}
				<li className="save_as_block__list_item">
					<button
						className="btn_v4 save_as_block__glass_button"
						type="button"
						onClick={showResetConfigurationPopup}
					>
						{t("resetConfiguration")}
					</button>
				</li>
			</ul>
			{renderPDFLink()}
		</div>
	);
};

export default SaveAsBlock;
