import Insulation from '../Glass/InsulationValues.js';
import GasSpacerUIElement from './GasSpacerUIElement.js';

export default class GasUIElement extends GasSpacerUIElement {
  constructor(props) {
    super(props);

    this.i18n_option_key_format = 'gas_{}';
    this.options = ['argon', 'krypton', 'mixedGas'];
    this.expensiveOptions = { 'krypton': true, 'mixedgas': true };
    this.state = { ...this.state, isExpensive: false };
  }

  handleSelectChange = (gasOption) => {
    let val = gasOption.value;
    if (this.props.uiHandlerRef.current.onGasChanged(this.props.id, val)) {
      this.recreate(val);
    }
  }

  recreate(gas_value = undefined) {
    super.recreate(gas_value);

    const isExpensive = (gas_value && this.expensiveOptions[gas_value]);
    if (this.state.isExpensive !== isExpensive) {
      this.setState({ isExpensive });
    }
  }

  refreshAvailableOptions() {
    const uiHandler = this.uiHandlerRef.current;
    this.resetFilteredOptions();

    let glass_structure = uiHandler.structure.getGlassStructure();
    if (glass_structure === '1-glazed') {
      return;
    }

    let spacer1 = uiHandler.structure.spacerOutside.thickness;
    let spacer2 = (glass_structure === '3-glazed' ? uiHandler.structure.spacerMiddle.thickness : 0);

    let cmpfn = glass_structure === '3-glazed' ? (v => v.spacer1 === spacer1 && v.spacer2 === spacer2) : (v => v.spacer1 === spacer1);
    let gases = uiHandler.dbView['uValue'][glass_structure].filter(cmpfn).reduce((acc, o) => {
      if (!acc.includes(o.gas)) {
        acc.push(o.gas);
      }
      return acc;
    }, []);

    this.options.forEach(o => {
      if (gases.indexOf(o) < 0) {
        this.filteredOptions.push(o);
        this.filteredOptionsUValue.push(Insulation.calculateUValueForGlass(spacer1, spacer2, o));
      }
    });
  }

  render() {
    const selectComponent = super.render();
    const { isExpensive } = this.state;
    return (isExpensive) ? (
      <div className='glass_product_selection_warning expensive' data-warning-message={this.t('gas_expensive')}>
        {selectComponent}
      </div>
    ) : selectComponent;
  }
}