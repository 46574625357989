import { COMPANIES_API } from 'api';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import jsonAppData from 'utils/jsonAppData';
import ToastifyHandler from 'utils/ToastifyHandler';

const ClientBlockDropdown = ({
	clientCompanyId,
	fetchAllClientsCompanies,
}) => {
	const { clientsListDropdown } = jsonAppData;
	const { t } = useTranslation(["commonConfigurationAction", "firebaseErrorMessages"]);
	const notificationsHandler = useRef(new ToastifyHandler());
	const navigate = useNavigate();

	const deleteClientCompany = async () => {
		notificationsHandler.current.pending(t('notifications:clientCompanyDeletionInProgress'));

		try {
			await COMPANIES_API.deleteCompany(clientCompanyId);

			notificationsHandler.current.success(t('notifications:clientCompanyWasDeletedSuccessfully'));

			if (fetchAllClientsCompanies && typeof fetchAllClientsCompanies === 'function') {
				fetchAllClientsCompanies();
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const handleClick = (actionType) => {
		if (actionType === 'open' && clientCompanyId) {
			navigate(`/client-dashboard/${clientCompanyId}`);

			return;
		}

		if (actionType === 'delete') {
			if (confirm(t('notifications:confirmationOfClientCompanyDeletion')) && clientCompanyId) {
				deleteClientCompany();
			}
		}
	};

	return (
		clientsListDropdown ? (
			<div className="client_block_dropdown">
				<ul className="client_block_dropdown__list">
					{clientsListDropdown.map(({
						label,
					}) => {
						return (
							<li className="client_block_dropdown__item" key={label}>
								<button
									className="client_block_dropdown__button"
									type="button"
									onClick={() => handleClick(label)}
								>
									{t(label)}
								</button>
							</li>
						);
					})}
				</ul>
			</div>
		) : null

	);
};

export default ClientBlockDropdown;
