import React, { useRef, useEffect } from 'react';

function usePrevious(value) {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

function pushCrisp(method, parameters) {
	if (Object.keys(parameters).length > 0) {
		const items = Object.entries(parameters);
		items.map((item) => {
			const [key, value] = item;
			return global.$crisp.push([method, key, value]);
		});
	}
}

const Crisp = (props) => {
	const {
		crispWebsiteId,
		crispTokenId,
		crispRuntimeConfig,
		safeMode,
		configuration,
		attributes,
	} = props;

	const previousCrispWebsiteId = usePrevious(crispWebsiteId);
	if (previousCrispWebsiteId && previousCrispWebsiteId !== crispWebsiteId) {
		throw Error("crispWebsiteId can't be changed");
	}
	const previousCrispTokenId = usePrevious(crispTokenId);
	if (previousCrispTokenId && previousCrispTokenId !== crispTokenId) {
		throw Error("crispTokenId can't be changed");
	}

	// const previousCrispRuntimeConfig = usePrevious(crispRuntimeConfig);
	// if (previousCrispRuntimeConfig && previousCrispRuntimeConfig !== crispRuntimeConfig) {
	// 	throw Error("crispRuntimeConfig can't be changed");
	// }

	const previousSafeMode = usePrevious(safeMode);
	if (previousSafeMode && previousSafeMode !== safeMode) {
		throw Error("safeMode can't be changed");
	}

	if (global.$crisp === undefined) {
		// Must be call before any other $crisp method
		// https://help.crisp.chat/en/article/how-to-use-dollarcrisp-javascript-sdk-10ud15y/#1-disable-warnings-amp-errors
		global.$crisp = [['safe', safeMode]];
	}

	// Custom configuration
	if (attributes) {
		pushCrisp('set', attributes);
	}

	if (configuration) {
		pushCrisp('config', configuration);
	}

	const scriptUrl = 'https://client.crisp.chat/l.js';
	const scripts = document.querySelector(`script[src='${scriptUrl}']`);
	if (scripts === null) {
		// CRISP_WEBSITE_ID, CRISP_TOKEN_ID and CRISP_RUNTIME_CONFIG
		// must be declared before inserting the script
		// https://help.crisp.chat/en/article/how-to-restore-chat-sessions-with-a-token-c32v4t/
		// https://help.crisp.chat/en/article/how-to-use-crisp-with-reactjs-fe0eyz/

		global.CRISP_WEBSITE_ID = crispWebsiteId;
		global.CRISP_TOKEN_ID = crispTokenId;
		global.CRISP_RUNTIME_CONFIG = crispRuntimeConfig;

		// We are good start Crisp
		const script = document.createElement('script');
		script.src = scriptUrl;
		script.async = 1;
		document.head.appendChild(script);
	}

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <></>;
};

export default Crisp;
