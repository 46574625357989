/* eslint-disable indent */
export const TRANSLATION_IT = {
  visualizerComponent: {
    measurementArrowsLabelFormat: "SE {}", // Spessore elemento
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "Email",
    userPasswordPlaceholder: "Password",
    repeatPasswordPlaceholder: "Ripeti la password",
    newPasswordPlaceholder: "Nuova password",
    repeatNewPasswordPlaceholder: "Ripeti la nuova password",
    userGenderPlaceholder: "GENERE",
    userCompanyPlaceholder: "Azienda",
    userFirstNamePlaceholder: "Nome",
    userLastNamePlaceholder: "Cognome",
    userFunctionPlaceholder: "FUNZIONE",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "Via + nr",
    userZIPPlaceholder: "CAP",
    userCityPlaceholder: "Città",
    userPhonePlaceholder: "Telefono",
    searchField: "Ricerca",
    hideMessage:
      'Nascondi il messaggio di "cambio struttura" nel configuratore',
    userGenderMale: "Maschio",
    userGenderFemale: "Femmina",
    userGenderDiv: "Diverso",
    userFunctionOwner: "Proprietario",
    userFunctionCoOwner: "Co-proprietario",
    userFunctionManagement: "Direzione",
    userFunctionExecutiveManagement: "Direzione Esecutiva",
    userFunctionPurchasingProcurement: "Acquisti / Approvvigionamento",
    userFunctionSales: "Vendite",
    userFunctionProjectManagement: "Gestione Progetti",
    userFunctionCalculationCosting: "Calcolo / Costi",
    userFunctionAdministrativeProcessing: "Elaborazione Amministrativa",
    userFunctionOther: "Altro",
  },
  commonAppValues: {
    reference: "riferimento 1",
    building: "riferimento 2",
    types: "# tipi",
    created: "Creato",
    lastEdited: "Ultima modifica",
    configuration: "Configurazione",
    company: "Azienda",
    orders: "Ordini",
    openOrders: "Ordini aperti",
    openRequests: "Richieste aperte",
    drafts: "Bozze",
    requests: "Richieste",
    addPosition: "Aggiungi posizione",
    companies: "aziende",
    configurationCodeLabel: "codice",
  },
  notifications: {
    loadConfiguration: "Caricamento della configurazione...",
    configurationUpdatedSuccessfully: "Configurazione salvata",
    configurationIsSavedInTheCompany:
      "La configurazione è stata salvata nella vostra azienda!",
    configurationUpdatedError:
      "Si è verificato un errore, la configurazione non è stata aggiornata",
    configurationNotFound: "Configurazione non trovata!",
    confirmationOfPositionDeletion:
      "Sei sicuro di voler eliminare questa posizione?",
    confirmationOfGlassDeletion:
      "Sei sicuro di voler cancellare questa configurazione del vetro?",
    confirmationOfGlassEdit:
      "Si desidera modificare la configurazione del vetro?",
    errorAddingFileWrongFormat: "È possibile aggiungere solo immagini o pdf",
    confirmationOfDocumentDeletion:
      "Siete sicuri di voler eliminare questo documento?",
    confirmationOfConfigurationDeletion:
      "Siete sicuri di voler cancellare questa configurazione?",
    errorLargeFileSize: "Dimensione massima del file {}",
    accountCreationInProgress: "Creazione dell'account in corso",
    accountCreatedSuccessfully: "Ti sei iscritto con successo",
    emailSendedSuccessfully: "Il tuo ordine è stato inviato. Grazie",
    emailSendingInProgress: "Attendere prego, il messaggio è in corso di invio",
    confirmationOfResetingConfiguration:
      "Sei sicuro di voler cancellare questa configurazione con tutti i file caricati?",
    configurationDeletionInProgress:
      "Cancellazione della configurazione in corso",
    configurationWasDeletedSuccessfully:
      "La configurazione è stata eliminata con successo",
    passwordResetInProgress: "Reset della password in corso",
    passwordHasBeenSuccessfullyChanged:
      "La password è stata modificata con successo",
    pleaseWait: "Attendere prego...",
    companyDataWasUpdated: "I dati dell'azienda sono stati aggiornati!",
    emailWithInstuctionsToResetPasswordHasBeenSent:
      "Ti è stata inviata un'e- mail con le istruzioni per reimpostare la password.",
    confirmationOfChangeTypeConfiguration:
      "Sei sicuro di voler cambiare il tipo di configurazione?",
    configurationTypeChangeInProgress:
      "Modifica del tipo di configurazione in corso",
    configTypeChangedSuccessfully:
      "Il tipo di configurazione è stato cambiato con successo!",
    configurationStatusChangeInProgress:
      "Modifica dello stato di configurazione in corso",
    configurationStatusChangedSuccessfully:
      "Stato della configurazione cambiato con successo!",
    duplicatingConfigWithAllFilesInProgress:
      "Attendere prego. La duplicazione di una configurazione con tutti i suoi file è in corso",
    configurationDuplicationCompletedSuccessfully:
      "Duplicazione della configurazione completata con successo",
    confirmationOfClientCompanyDeletion:
      "Si è sicuri di voler eliminare questo client con i suoi utenti e le sue configurazioni?",
    clientCompanyDeletionInProgress:
      "Eliminazione dell'azienda cliente in corso",
    clientCompanyWasDeletedSuccessfully:
      "L'azienda del cliente è stata eliminata con successo",
    companyNotFound: "Azienda non trovata!",
    loadingCompanyData: "Caricamento dei dati dell'azienda...",
    dataLoadedSuccessfully: "Dati caricati con successo!",
    dataHasBeenSuccessfullyUpdated:
      "I dati sono stati aggiornati con successo!",
    userHasBeenSuccessfullyRemovedFromTheCompany:
      "L'utente è stato rimosso con successo dall'azienda!",
    confirmationOfUserDeletion:
      "Sei sicuro di voler rimuovere questo utente dall'azienda?",
    invitationLinkHasBeenCopied: "Il link è stato copiato negli appunti!",
    anErrorHasOccurred: "Si è verificato un errore",
    loadingDataOfAllCompaniesInProgress:
      "Caricamento dei dati di tutte le aziende in corso...",
    uploadDocAreaDragAndDrop: "Trascinare i file qui",
    uploadDocAreaOr: "oppure",
    uploadDocAreaBrowseFiles: "Sfogliare i file sul computer",
    uploadDocAreaMaxFileSizeFmt: "Dimensione massima del file {}",
  },
  formNotifications: {
    emailRequired: "L'email è richiesta",
    enterValidEmail: "Inserire un'email valida",
    fieldIsRequired: "è richiesto",
    nameFieldInvalidCharacters: "Caratteri non validi",
    nameFieldCharactersLength: "Deve essere di almeno tre caratteri",
    passwordFieldCharactersLong: "La password deve essere lunga 8 caratteri",
    passwordFieldMustContainNumber:
      "Password non valida. Deve contenere un numero",
    passwordNotMatched: "Password non corrispondente",
  },
  configurationCategory: {
    glassType: "Tipo di vetro",
    fireResistant: "Vetro resistente al fuoco",
    spacer1: "Distanziatore 1",
    gas1: "Gas",
    middleGlass: "Vetro centrale",
    spacer2: "Distanziatore 2",
    gas2: "Gas",
    outsideGlass: "Vetro esterno (rivestito)",
    structure: "Struttura",
    manufacturer: "Produttore",
    fireResistanceClass: "Resistenza al fuoco (EN 13501-2)",
    insulation: "Valore U [W/m²K] (EN 673)",
    application: "Applicazione",
    whiteGlass: "Vetro bianco",
    resistanceClass: "Classe di resistenza (EN 1627)",
    buttJoints: "giunti di testa",
    sound: "Silenziamento Rw (EN 140-3)",
  },
  filterName: {
    StructureFilter: "Struttura",
    ManufacturerFilter: "Produttore",
    FireClassFilter: "Resistenza al fuoco",
    InsulationFilter: "Valore U",
    ApplicationFilter: "Applicazione",
    WhiteGlassFilter: "Vetro bianco",
    ResistanceClassFilter: "Classe di resistenza",
    ButtJointsFilter: "giunti di testa",
    SoundFilter: "Silenziamento",
  },
  configurationTableHeading: {
    length: "larghezza",
    width: "altezza",
    quantity: "quantità",
    price: "prezzo",
    form: "forma", // or "modello" depending on the context
    formNote:
      "Per vetri di forma speciale, si prega di lasciare una nota o caricare un documento con le specifiche in basso",
    notes: "note",
    cantonalInsurance: "AEAI",
    cantonalInsuranceLink: "https://www.bsronline.ch/it/ricerca-dei-registri/",
    total: "totale",
    totalCart: "Totale carrello",
    pc: "pz",
    pos: "pos",
  },
  commonConfigurationAction: {
    addToCart: "Aggiungi al carrello",
    updateInCart: "Aggiornamento nel carrello",
    resetGlass: "Reset del vetro",
    resetConfiguration: "Reset del carrello",
    saveAsPdf: "salva come pdf",
    glassDotPdfFmt: "CDR_Vetro_{date}.pdf",
    configurationDotPdfFmt: "CDR_Configurazione_{date}.pdf",
    continue: "continua",
    delete: "Elimina",
    complete: "Completa",
    duplicate: "Duplica",
    edit: "Modifica",
    order: "Ordine",
    duplicateAsDraft: "Duplicare come bozza",
    firstSelectProperties: "Selezione delle proprietà",
    secondCustomize: "Adattare",
    addYourGlassToCart: "Aggiungi il tuo vetro al carrello", // corrected for context
    sizeValidationError:
      "Assicurati di compilare tutti i campi obbligatori: larghezza, altezza e quantità.",
    selectProductToContinue: "Seleziona un prodotto per continuare.",
  },
  registrationForm: {
    title: "Registrazione",
    registerButton: "Registrati",
    alreadyHaveAccount: "HAI GIÀ UN ACCOUNT? ",
    logIn: "ACCEDI",
  },
  loginForm: {
    title: "Accesso", // Adjusted
    dontHaveAccount: "Non hai un account?",
    loginButton: "Accedi", // Adjusted
    lostPassword: "Password persa", // Capitalized
    signUp: "Iscriviti",
    successfullyLoggedIn: "Hai effettuato l'accesso con successo!",
    loggingInProgress: "Registrazione in corso",
    successfullyLoggedOut: "Ti sei disconnesso con successo",
  },
  lostPasswordForm: {
    dontHaveAccount: "Non hai un account?",
    title: "Password persa", // Capitalized
    submitButton: "Ripristina password",
    signUp: "Iscriviti",
    loggingInProgress: "Reimpostazione in corso", // Adjusted
    successfullyLoggedIn: "Reimpostazione riuscita", // Adjusted
  },
  resetConfigurationForm: {
    title: "Reimpostare la configurazione",
    text: "Si desidera avviare una nuova configurazione e mantenere quella attuale? Il codice di configurazione è valido per 30 giorni",
    configurationCode: "Codice di configurazione",
    resetButton: "Reset",
    or: "o",
    textNew:
      "Vuoi avviare una nuova configurazione e cancellare tutti i contenuti e i file",
    resetDeleteButton: "Reset e cancellazione",
    configurationDoesNotExist: "La configurazione non esiste",
  },
  resetGlassForm: {
    title: "Azzeramento vetro",
    text: "Vuoi resettare il vetro e i filtri attuali?",
    resetButton: "Azzeramento",
  },
  confirmFilterChangesForm: {
    titleStructureChanged: "Modifica della struttura",
    textStructureChanged:
      "Questa opzione cambia la struttura del vetro da {oldStruct} a {newStruct} e può influenzare altri filtri. Si desidera continuare?",
    titleFiltersReset: "Azzeramento dei filtri",
    textFiltersReset:
      "Questa opzione ripristina i filtri {filters}. Volete continuare?",
    yesChange:
      "Sì, modifica la struttura del vetro e ripristina i filtri necessari",
    noAbort: "No, interrompiamo e proviamo qualcos'altro",
    hideMessage: "non mostrare più questo messaggio", // Corrected
  },
  guestForm: {
    title: "Ospite",
    additionalInfo: "Informazioni aggiuntive",
    continue: "Continua",
  },
  accountCompanyForm: {
    title: "Azienda",
  },
  accountPage: {
    companyFormTitle: "Azienda",
    userFormTitle: "Utente",
    usersInCompanyTitle: "utenti in azienda",
    registerNewUser: "Registra un nuovo utente per questa azienda",
    saveButton: "Salva",
  },
  configurationCodeForm: {
    title: "Codice di configurazione",
    enterYourCodePlaceholder: "Inserisci il tuo codice",
    overwriteConfigurationCheckbox:
      "questo sovrascriverà la configurazione attuale",
    submitButton: "carica", // Corrected
  },
  resetPasswordForm: {
    title: "Password persa",
    submitButton: "Reimposta password",
  },
  homePage: {
    myGlassButton: "il mio vetro",
  },
  cartPage: {
    configurationCodeTitle: "Codice di configurazione",
    configurationCodeLabel: "codice",
    configurationCodeDescription:
      "Questo è il codice della configurazione, se si desidera recuperarlo in seguito senza effettuare il login. È valido per 30 giorni",
    yourReference: "il vostro riferimento",
    yourReferenceInputLabel: "riferimento 1",
    yourReferenceOptionalTitle: "opzionale",
    buildingReferenceInputLabel: "riferimento 2",
    buildingReferenceInputLabelPlaceholder: "Edificio XYZ",
    uploadedDocuments: "Documenti caricati",
    addNewTypeTitle: "Aggiungi nuovo tipo di vetro",
    createFirstConfiguration: "Crea la tua prima configurazione",
    addNewTypePlaceholderTitle: "Tipo di vetro",
    addNewTypePlaceholderValue: "Vetro resistente al fuoco",
    addNewTypeBtn: "Aggiungi",
    cartIsEmpty: "Il carrello è vuoto",
    termsText:
      "Accetto le <a href='#' target='_blank'>condizioni generali</a> e <a href='#' target='_blank'>l'esclusione di responsabilità</a>",
  },
  pageNavigation: {
    login: "Accedi",
    myCode: "Il mio codice",
    dashboard: "Cruscotto",
    account: "Account",
    logout: "Esci", // Corrected
    adminDashboard: "Cruscotto amministratore",
    configurator: "Configuratore",
  },
  additionalPageNavigation: {
    configure: "Configura",
    cart: "Carrello",
    inquireOrder: "Sintesi",
  },
  requestOrOrderPage: {
    request: "richiesta",
    order: "Ordine",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Conferma ordine",
    formSubmittedText: "L'ordine è stato inviato. Grazie.",
  },
  adminDashboardPage: {
    clientsTitle: "Clienti",
  },
  contactCompanyInformation: {
    company: "azienda",
    address: "indirizzo",
    zip: "CAP",
    city: "città",
    tel: "Tel",
    email: "Email",
  },
  deletePositionPopup: {
    title: "Cancella posizione",
    description: "Sei sicuro di voler cancellare questa posizione?",
    deleteButton: "Elimina",
  },
  deleteAttachmentPopup: {
    title: "Cancella allegato",
    description: "Sei sicuro di voler eliminare questo allegato?",
    deleteButton: "Elimina",
  },
  supportedDevicesPopup: {
    title: "Mobile",
    description:
      "Questo configuratore non è attualmente supportato dai dispositivi mobili. Si prega di utilizzare un computer desktop o di ridimensionare il browser",
  },
  glassCharacteristics: {
    glassStructure: "Struttura",
    fireResistance: "Resistenza al fuoco",
    uValue: "Valore U",
    thickness: "Spessore",
    clearGlass: "Vetro trasparente",
    resistanceClass: "Classe di resistenza",
    buttJoints: "Adatto per giunzioni di testa",
    application: "Applicazioni adatte",
    sound: "Smorzamento del rumore",
    valid: "Configurazione valida",
    glassMaxSizeWarningFormat:
      "Dimensione massima {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Non disponibile a causa del filtro",
  },
  filters: {
    changesUValue: "Cambia il valore U",
    gas_argon: "Argon",
    gas_krypton: "Kripton",
    gas_mixedGas: "Gas misto",
    gas_expensive:
      "Il prezzo di questo gas è attualmente molto volatile e costoso.",
    spacer_format: "{} mm",
    structure_1Glazed: "Vetro singolo (MONO)",
    structure_2Glazed: "Vetro doppio (ISO)",
    structure_3Glazed: "Triplo vetro (ISO)",
    manufacturer_indep: "Produttore indipendente",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "Valore U {}",
    application_in: "Interno",
    application_inOut: "Interno/Esterno",
    application_outOut: "Esterno/Esterno",
    whiteGlass_yes: "Sì",
    whiteGlass_no: "No",
    resistanceClass_none: "Nessuna",
    resistanceClass_rc2: "RC2 (P4A)",
    resistanceClass_rc3: "RC3 (P5A)",
    resistanceClass_rc4: "RC4 (P6B)",
    buttJoints_yes: "Sì",
    buttJoints_no: "No",
    sound_format: "{} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Pagina {pageNum} di {numPages}",
  },
  firebaseErrorMessages: {
    // errors for Cloud Storage
    "storage/unknown": "Si è verificato un errore sconosciuto.",
    "storage/object-not-found": "Nessun oggetto esiste al riferimento desiderato.",
    "storage/bucket-not-found": "Nessun bucket è configurato per lo Storage Cloud.",
    "storage/project-not-found": "Nessun progetto è configurato per lo Storage Cloud.",
    "storage/quota-exceeded": "La quota sul tuo bucket di Storage Cloud è stata superata. Se sei sul piano gratuito, passa a un piano a pagamento. Se sei su un piano a pagamento, contatta il supporto Firebase.",
    "storage/unauthenticated": "L'utente non è autenticato, si prega di autenticarsi e riprovare.",
    "storage/unauthorized": "L'utente non è autorizzato a eseguire l'azione desiderata, controlla le tue regole di sicurezza per assicurarti che siano corrette.",
    "storage/retry-limit-exceeded": "Il limite di tempo massimo su un'operazione (caricamento, download, cancellazione, ecc.) è stato superato. Prova a caricare di nuovo.",
    "storage/invalid-checksum": "Il file sul client non corrisponde al checksum del file ricevuto dal server. Prova a caricare di nuovo.",
    "storage/canceled": "L'utente ha annullato l'operazione.",
    "storage/invalid-event-name": "Nome evento non valido fornito. Deve essere uno dei seguenti [`running`, `progress`, `pause`]",
    "storage/invalid-url": "URL non valido fornito a refFromURL(). Deve essere nella forma: gs://bucket/oggetto o https://firebasestorage.googleapis.com/v0/b/bucket/o/oggetto?token=<TOKEN>",
    "storage/invalid-argument": "L'argomento passato a put() deve essere un `File`, `Blob` o un array `UInt8`. L'argomento passato a putString() deve essere una stringa grezza, `Base64` o `Base64URL`.",
    "storage/no-default-bucket": "Nessun bucket è stato impostato nella proprietà storageBucket della tua configurazione.",
    "storage/cannot-slice-blob": "Si verifica spesso quando il file locale è cambiato (cancellato, salvato di nuovo, ecc.). Prova a caricare di nuovo dopo aver verificato che il file non sia cambiato.",
    "storage/server-file-wrong-size": "Il file sul client non corrisponde alla dimensione del file ricevuto dal server. Prova a caricare di nuovo.",

    // authentication API Errors
    "auth/claims-too-large": "Il payload delle claims fornito a setCustomUserClaims() supera la dimensione massima consentita di 1000 byte.",
    "auth/email-already-exists": "L'email fornita è già in uso da un utente esistente. Ogni utente deve avere un indirizzo email unico.",
    "auth/id-token-expired": "Il token ID Firebase fornito è scaduto.",
    "auth/id-token-revoked": "Il token ID Firebase è stato revocato.",
    "auth/insufficient-permission": "Il certificato utilizzato per inizializzare l'SDK Admin non ha i permessi necessari per accedere alla risorsa di autenticazione richiesta. Consulta la documentazione su Configurare un Progetto Firebase per informazioni su come generare un certificato con i permessi appropriati e usarlo per autenticare gli SDK Admin.",
    "auth/internal-error": "Il server di autenticazione ha incontrato un errore imprevisto durante il tentativo di elaborare la richiesta. Il messaggio di errore dovrebbe contenere la risposta del server di autenticazione con informazioni aggiuntive. Se l'errore persiste, si prega di segnalare il problema al nostro canale di supporto Bug Report.",
    "auth/invalid-argument": "È stato fornito un argomento non valido a un metodo di autenticazione. Il messaggio di errore dovrebbe contenere ulteriori informazioni.",
    "auth/invalid-claims": "Gli attributi delle claims personalizzate forniti a setCustomUserClaims() sono invalidi.",
    "auth/invalid-continue-uri": "L'URL di continuazione deve essere una stringa URL valida.",
    "auth/invalid-creation-time": "Il tempo di creazione deve essere una stringa di data UTC valida.",
    "auth/invalid-credential": "Il certificato utilizzato per autenticare gli SDK Admin non può essere utilizzato per eseguire l'azione desiderata. Alcuni metodi di autenticazione come createCustomToken() e verifyIdToken() richiedono che l'SDK sia inizializzato con un certificato come token di aggiornamento o un token di applicazione predefinito. Consulta la documentazione sull'inizializzazione dell'SDK per sapere come autenticare gli SDK Admin con un certificato.",
    "auth/invalid-disabled-field": "Il valore fornito per la proprietà disabilitato dell'utente è invalido. Deve essere un booleano.",
    "auth/invalid-display-name": "Il valore fornito per la proprietà displayName dell'utente è invalido. Deve essere una stringa non vuota.",
    "auth/invalid-dynamic-link-domain": "Il dominio del link dinamico fornito non è configurato o autorizzato per il progetto corrente.",
    "auth/invalid-email": "Il valore fornito per la proprietà email dell'utente è invalido. Deve essere un indirizzo email.",
    "auth/invalid-email-verified": "Il valore fornito per la proprietà emailVerified dell'utente è invalido. Deve essere un booleano.",
    "auth/invalid-hash-algorithm": "L'algoritmo di hashing deve corrispondere a una delle stringhe dell'elenco degli algoritmi supportati.",
    "auth/invalid-hash-block-size": "La dimensione del blocco di hash deve essere un numero valido.",
    "auth/invalid-hash-derived-key-length": "La lunghezza della chiave derivata dall'hash deve essere un numero valido.",
    "auth/invalid-hash-key": "La chiave di hash deve essere un buffer di byte valido.",
    "auth/invalid-hash-memory-cost": "Il costo della memoria dell'hash deve essere un numero valido.",
    "auth/invalid-hash-parallelization": "La parallelizzazione dell'hash deve essere un numero valido.",
    "auth/invalid-hash-rounds": "Il numero di round dell'hash deve essere un numero valido.",
    "auth/invalid-hash-salt-separator": "Il campo separatore di sale dell'algoritmo di hash deve essere un buffer di byte valido.",
    "auth/invalid-id-token": "Il token ID fornito non è un token ID Firebase valido.",
    "auth/invalid-last-sign-in-time": "L'ora dell'ultimo accesso deve essere una stringa di data UTC valida.",
    "auth/invalid-page-token": "Il token della pagina successiva fornito in listUsers() è invalido. Deve essere una stringa valida non vuota.",
    "auth/invalid-password": "Il valore fornito per la proprietà password dell'utente è invalido. Deve essere una stringa di almeno sei caratteri.",
    "auth/invalid-password-hash": "L'hash della password deve essere un buffer di byte valido.",
    "auth/invalid-password-salt": "Il sale della password deve essere un buffer di byte valido.",
    "auth/invalid-phone-number": "Il valore fornito per il numero di telefono è invalido. Deve essere una stringa conforme allo standard E.164.",
    "auth/invalid-photo-url": "Il valore fornito per la proprietà photoURL dell'utente è invalido. Deve essere una stringa URL.",
    "auth/invalid-provider-data": "providerData deve essere un array valido di oggetti UserInfo.",
    "auth/invalid-provider-id": "providerId deve essere una stringa valida di un identificatore di fornitore supportato.",
    "auth/invalid-oauth-responsetype": "Solo un tipo di risposta OAuth deve essere impostato su true.",
    "auth/invalid-session-cookie-duration": "La durata del cookie di sessione deve essere un numero valido in millisecondi tra 5 minuti e 2 settimane.",
    "auth/invalid-uid": "L'UID fornito deve essere una stringa non vuota di al massimo 128 caratteri.",
    "auth/invalid-user-import": "Il record dell'utente da importare è invalido.",
    "auth/maximum-user-count-exceeded": "È stato superato il numero massimo consentito di utenti da importare.",
    "auth/missing-android-pkg-name": "Deve essere fornito un nome del pacchetto Android se l'app Android deve essere installata.",
    "auth/missing-continue-uri": "Deve essere fornita una URL di continuazione valida nella richiesta.",
    "auth/missing-hash-algorithm": "L'importazione degli utenti con hash delle password richiede di fornire l'algoritmo di hash e i suoi parametri.",
    "auth/missing-ios-bundle-id": "Nella richiesta manca un ID bundle.",
    "auth/missing-uid": "È richiesto un UID per l'operazione corrente.",
    "auth/missing-oauth-client-secret": "È richiesto il segreto del client di configurazione OAuth per abilitare il flusso di codice OIDC.",
    "auth/operation-not-allowed": "Il fornitore di login fornito è disabilitato per il tuo progetto Firebase. Attivalo dalla sezione Metodo di Login nella console Firebase.",
    "auth/phone-number-already-exists": "Il numero di telefono fornito è già in uso da un utente esistente. Ogni utente deve avere un numero di telefono unico.",
    "auth/project-not-found": "Nessun progetto Firebase è stato trovato per il certificato utilizzato per inizializzare gli SDK Admin. Consulta la documentazione su Configurare un Progetto Firebase per informazioni su come generare un certificato per il tuo progetto e usarlo per autenticare gli SDK Admin.",
    "auth/reserved-claims": "Una o più claims personalizzate dell'utente fornite a setCustomUserClaims() sono riservate. Ad esempio, claims specifiche per OIDC come (sub, iat, iss, exp, aud, auth_time, ecc.) non dovrebbero essere utilizzate come chiavi per claims personalizzate.",
    "auth/session-cookie-expired": "Il cookie di sessione Firebase fornito è scaduto.",
    "auth/session-cookie-revoked": "Il cookie di sessione Firebase è stato revocato.",
    "auth/too-many-requests": "Il numero di richieste supera il massimo consentito.",
    "auth/uid-already-exists": "L'UID fornito è già in uso da un utente esistente. Ogni utente deve avere un UID unico.",
    "auth/unauthorized-continue-uri": "Il dominio dell'URL di continuazione non è nella whitelist. Aggiungi il dominio alla console Firebase.",
    "auth/user-not-found": "Non esiste alcun record utente corrispondente all'identificativo fornito.",
    "auth/wrong-password": "Password errata",

    // other errors
    ok: "Operazione riuscita",
    cancelled: "Operazione annullata",
    unknown: "Errore sconosciuto",
    "invalid-argument": "Argomento non valido",
    "deadline-exceeded": "Scadenza superata",
    "not-found": "Non trovato",
    "already-exists": "Già esistente",
    "permission-denied": "Permesso negato",
    unauthenticated: "Utente non autenticato",
    "resource-exhausted": "Risorsa esaurita",
    "failed-precondition": "Precondizione fallita",
    aborted: "Operazione annullata",
    "out-of-range": "Fuori portata",
    unimplemented: "Non implementato",
    internal: "Errore interno",
    unavailable: "Non disponibile",
    "data-loss": "Perdita di dati",
  },
};
