export default class GenericGlass {
  constructor(id, glasstype, id_app, id_veras, thickness, white_glass, sound, weight, resistance_class, uv_protection, application, _orders, max_width, max_height, layers) {
    this.dbIndex = -1;
    this.id = id;
    this.glasstype = glasstype;
    this.id_app = id_app;
    this.id_veras = id_veras;
    this.thickness = parseFloat(thickness.replace(",", "."));
    this.white_glass = white_glass === 'Yes';
    this.sound = parseFloat(sound);
    this.weight = parseFloat(weight.replace(",", "."));
    this.resistanceClass = resistance_class;
    this.uv_protection = uv_protection;
    this.application = application;
    this.max_width = parseFloat(max_width);
    this.max_height = parseFloat(max_height);
    this.layers = layers ? JSON.parse(layers) : (white_glass ? [this.thickness + ':1'] : [this.thickness + ':0']);

    let sum = this.layers.reduce((acc, cur) => acc + parseFloat(cur.split(":")[0]), 0.0);
    if (sum !== this.thickness) {
      console.warn(`${this.id}: Glass ${this.glasstype}: Layers (${sum}) don't add up to glass thickness (${this.thickness})`);
    }
  }
}
