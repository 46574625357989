import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API } from 'api';
import { AuthContext } from 'context/auth';
import { Spinner } from 'components/Spinner';
import { PROMISE_STATES } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import { fieldValidation } from 'utils';
import { Formik } from 'formik';
import FormField from '../FormField';

const AccountCompanyForm = () => {
	const { t } = useTranslation(['baseFormPlaceholders', 'firebaseErrorMessages']);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const [companyData, setCompanyData] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
	const notificationsHandler = useRef(new ToastifyHandler());
	const [initialFormValues, setInitialFormValues] = useState(null);
	const formikRef = useRef();

	const validate = {
		companyName: value => fieldValidation(t('userCompanyPlaceholder'), value),
		companyAddress: value => fieldValidation(t('userStreetPlaceholder'), value),
		companyCity: value => fieldValidation(t('userCityPlaceholder'), value),
		companyTel: value => fieldValidation(t('userPhonePlaceholder'), value),
		companyZIP: value => fieldValidation(t('userZIPPlaceholder'), value),
	};

	const createInitialFormValues = ({
		name,
		address,
		zip,
		city,
		tel,
	}) => {
		const initialValues = {
			companyName: name,
			companyAddress: address,
			companyZIP: zip,
			companyCity: city,
			companyTel: tel,
		};

		setInitialFormValues(initialValues);
	};

	const getCompanyData = async () => {
		setLoadingStatus(PROMISE_STATES.pending);

		try {
			const res = await COMPANIES_API.getCompany(currentUserAdditionalData.companyId);

			if (res) {
				setCompanyData(res);
				createInitialFormValues(res);

				setLoadingStatus(PROMISE_STATES.fulfilled);
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
			setLoadingStatus(PROMISE_STATES.rejected);
		}
	};

	const createNewCompanyData = ({
		companyName,
		companyAddress,
		companyCity,
		companyTel,
		companyZIP,
	}) => {
		const data = {
			...companyData,
			name: companyName,
			address: companyAddress,
			city: companyCity,
			tel: companyTel,
			zip: companyZIP,
		};

		return data;
	};

	useEffect(() => {
		if (currentUserAdditionalData) {
			getCompanyData();
		}
	}, [currentUserAdditionalData]);

	return (
		<div className="account_form">
			<BlockHeading
				title={t('accountPage:companyFormTitle')}
			/>
			<div className="account_form__in">
				{companyData && currentUserAdditionalData && initialFormValues ? (
					<Formik
						initialValues={initialFormValues}
						innerRef={formikRef}
						mapPropsToValues={() => {
							return {
								...initialFormValues,
							};
						}}
						validate={values => Object.keys(values).reduce((errors, field) => {
							const error = validate[field](values[field]);
							return {
								...errors,
								...(error && { [field]: error }),
							};
						}, {})}
						onSubmit={async (values) => {
							notificationsHandler.current.pending(t('notifications:pleaseWait'));

							const updatedCompanyData = createNewCompanyData(values);

							try {
								setLoadingStatus(PROMISE_STATES.pending);

								await COMPANIES_API.addNewCompany(updatedCompanyData);

								setCompanyData(updatedCompanyData);

								notificationsHandler.current.success(t('notifications:companyDataWasUpdated'));
								setLoadingStatus(PROMISE_STATES.fulfilled);
							} catch (error) {
								notificationsHandler.current.rejected(error.message);
								setLoadingStatus(PROMISE_STATES.rejected);
							}
						}}
						validateOnChange={false}
					>
						{({
							handleSubmit,
							handleBlur,
							handleChange,
							values,
							errors,
							touched,
						}) => (
							<form onSubmit={handleSubmit}>
								<ul className="form_fields offset_2_mod">
									<li className="form_fields__item">
										<FormField
											id="companyName"
											placeholder={t('userCompanyPlaceholder')}
											name="companyName"
											type="text"
											required
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.companyName && errors.companyName}</div>
									</li>
									<li className="form_fields__item">
										<FormField
											id="companyAddress"
											placeholder={t('userStreetPlaceholder')}
											name="companyAddress"
											type="text"
											required
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.companyAddress && errors.companyAddress}</div>
									</li>
									<li className="form_fields__item">
										<FormField
											id="companyZIP"
											placeholder={t('userZIPPlaceholder')}
											name="companyZIP"
											type="text"
											required
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.companyZIP && errors.companyZIP}</div>
									</li>
									<li className="form_fields__item">
										<FormField
											id="companyCity"
											placeholder={t('userCityPlaceholder')}
											name="companyCity"
											type="text"
											required
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.companyCity && errors.companyCity}</div>
									</li>
									<li className="form_fields__item">
										<FormField
											id="companyTel"
											placeholder={t('userPhonePlaceholder')}
											name="companyTel"
											type="text"
											required
											values={values}
											handleBlur={handleBlur}
											handleChange={handleChange}
										/>
										<div className="form_fields__error">{touched.companyTel && errors.companyTel}</div>
									</li>
								</ul>
								<button
									className="account_form__button"
									type="submit"
									disabled={loadingStatus === PROMISE_STATES.pending}
								>
									<div className="btn_base">{t('accountPage:saveButton')}</div>
								</button>
							</form>
						)}
					</Formik>
				) : (
					loadingStatus === PROMISE_STATES.pending ? (
						<Spinner darkTheme />
					) : (
						<div className="company_users__error">{loadingErrorMessage}</div>
					)
				)}
			</div>
		</div>
	);
};

export default AccountCompanyForm;
