import React, { useState, useEffect, useContext } from 'react';
import { View, Text } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import { formatNumberToFixed } from 'utils';
import { DEFAULT_GLASS_SIZES } from 'domain/glass';
import PdfGlassConfigTableRowCell from './PdfGlassConfigTableRowCell';

import styles from '../styles';

const PdfGlassConfigTableRow = ({
	tableRow,
	positionIndex,
	totalValues,
}) => {
	const [total, setTotal] = useState({ m2Pc: '-', sqmPrice: '-', ...DEFAULT_GLASS_SIZES });
	const { t } = useTranslation('configurationTableHeading');
	const notes = tableRow.row.find((cellItem) => cellItem.name === "notes") || { value: '-' };

	useEffect(() => {
		if (totalValues) {
			setTotal(totalValues);
		} else {
			setTotal({ m2Pc: '-', sqmPrice: '-', ...DEFAULT_GLASS_SIZES });
		}
	}, [totalValues]);

	return (
		<>
			<View style={styles.glassConfigTableRow}>
				{positionIndex ? (
					<View style={{ ...styles.glassConfigTableCell, ...styles.glassConfigTableCellFirstChild }}>
						<Text>{positionIndex}</Text>
					</View>
				) : null}
				{tableRow.row && tableRow.row.length ? (
					<>
						{tableRow.row.map((cellItem, index) => {
							if (cellItem.name === "notes") return null;

							return (
								<PdfGlassConfigTableRowCell
									key={index}
									cellData={cellItem}
								/>
							);
						})}
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.m2Pc) }} />
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.m2) }} />
						<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.kg) }} isLastCell={total.price === null} />
						{
							total.price !== null ? (<PdfGlassConfigTableRowCell cellData={{ value: formatNumberToFixed(total.price) }} isLastCell />) : null
						}
					</>
				) : null}
			</View>
			<View style={{ ...styles.glassConfigTableRow, ...styles.glassConfigTableRow2 }}>
				<Text style={{
					...styles.glassConfigTableCellTitle,
					...styles.glassConfigTableCell,
					...styles.glassConfigTableCellFirstChild,
				}}
				>
					{t('notes')}

				</Text>
				<PdfGlassConfigTableRowCell cellData={notes} isLastCell isNotes />
			</View>
		</>
	);
};

export default PdfGlassConfigTableRow;
