/* eslint-disable indent */
export const TRANSLATION_EN = {
  visualizerComponent: {
    measurementArrowsLabelFormat: "ET {}",
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "Email",
    userPasswordPlaceholder: "Password",
    repeatPasswordPlaceholder: "Repeat Password",
    newPasswordPlaceholder: "New password",
    repeatNewPasswordPlaceholder: "Repeat new password",
    userGenderPlaceholder: "GENDER",
    userCompanyPlaceholder: "Company",
    userFirstNamePlaceholder: "First name",
    userLastNamePlaceholder: "Last Name",
    userFunctionPlaceholder: "FUNCTION",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "street + nr",
    userZIPPlaceholder: "zip",
    userCityPlaceholder: "city",
    userPhonePlaceholder: "Phone",
    searchField: "Search",
    hideMessage: 'Hide "structure change" message in configurator',
    userGenderMale: "Male",
    userGenderFemale: "Female",
    userGenderDiv: "Div",
    userFunctionOwner: "Owner",
    userFunctionCoOwner: "Co-owner",
    userFunctionManagement: "Management",
    userFunctionExecutiveManagement: "Executive Management",
    userFunctionPurchasingProcurement: "Purchasing / Procurement",
    userFunctionSales: "Sales",
    userFunctionProjectManagement: "Project Management",
    userFunctionCalculationCosting: "Calculation / Costing",
    userFunctionAdministrativeProcessing: "Administrative Processing",
    userFunctionOther: "Other",
  },
  commonAppValues: {
    reference: "reference 1",
    building: "reference 2",
    types: "# types",
    created: "Created",
    lastEdited: "last edited",
    configuration: "Configuration",
    company: "Company",
    orders: "Orders",
    openOrders: "Open orders",
    openRequests: "Open requests",
    drafts: "Drafts",
    requests: "Requests",
    addPosition: "Add position",
    companies: "companies",
    configurationCodeLabel: "code",
  },
  notifications: {
    loadConfiguration: "Configuration loading...",
    configurationUpdatedSuccessfully: "Configuration saved.",
    configurationIsSavedInTheCompany:
      "The configuration has been saved to your company!",
    configurationUpdatedError: "An error occurred, configuration not updated",
    configurationNotFound: "Configuration not found!",
    confirmationOfPositionDeletion:
      "Are you sure you want to delete this position?",
    confirmationOfGlassDeletion:
      "Are you sure you want to delete this glass configuration?",
    confirmationOfGlassEdit: "Do you want to edit this glass configuration?",
    errorAddingFileWrongFormat: "You can only added images and PDFs",
    confirmationOfDocumentDeletion:
      "Are you sure you want to delete this document?",
    confirmationOfConfigurationDeletion:
      "Are you sure you want to delete this configuration?",
    errorLargeFileSize: "Max file size {}",
    accountCreationInProgress: "Account creation in progress",
    accountCreatedSuccessfully: "You have signed up successfully",
    emailSendedSuccessfully: "Your order has been submitted. Thank you.",
    emailSendingInProgress: "Please wait, message is being sent.",
    confirmationOfResetingConfiguration:
      "Are you sure you want to delete this configuration with all uploaded files?",
    configurationDeletionInProgress: "Configuration deletion in progress",
    configurationWasDeletedSuccessfully:
      "Configuration was deleted successfully",
    passwordResetInProgress: "Password reset in progress",
    passwordHasBeenSuccessfullyChanged:
      "Password has been successfully changed",
    pleaseWait: "Please wait...",
    companyDataWasUpdated: "Company data was updated!",
    emailWithInstuctionsToResetPasswordHasBeenSent:
      "An email with instructions to reset your password has been sent to you.",
    confirmationOfChangeTypeConfiguration:
      "Are you sure you want to change type of this configuration?",
    configurationTypeChangeInProgress: "Configuration type change in progress",
    configTypeChangedSuccessfully: "Configuration type changed successfully!",
    configurationStatusChangeInProgress:
      "Configuration status change in progress",
    configurationStatusChangedSuccessfully:
      "Configuration status changed successfully!",
    duplicatingConfigWithAllFilesInProgress:
      "Please wait. Duplicating a config with all its files in progress",
    configurationDuplicationCompletedSuccessfully:
      "Configuration duplication completed successfully",
    confirmationOfClientCompanyDeletion:
      "Are you sure you want to delete this client with its users and configurations?",
    clientCompanyDeletionInProgress: "Client company deletion in progress",
    clientCompanyWasDeletedSuccessfully:
      "Client company was deleted successfully",
    companyNotFound: "Company not found!",
    loadingCompanyData: "Loading company data...",
    dataLoadedSuccessfully: "Data loaded successfully!",
    dataHasBeenSuccessfullyUpdated: "Data has been successfully updated!",
    userHasBeenSuccessfullyRemovedFromTheCompany:
      "The user has been successfully removed from the company!",
    confirmationOfUserDeletion:
      "Are you sure you want to remove this user from the company?",
    invitationLinkHasBeenCopied: "Link has been copied to clipboard!",
    anErrorHasOccurred: "An error has occurred",
    loadingDataOfAllCompaniesInProgress:
      "Loading data of all companies in progress...",
    uploadDocAreaDragAndDrop: "Drag & drop files here",
    uploadDocAreaOr: "or",
    uploadDocAreaBrowseFiles: "Browse files on your computer",
    uploadDocAreaMaxFileSizeFmt: "Max file size {}",
  },
  formNotifications: {
    emailRequired: "Email is required",
    enterValidEmail: "Please enter a valid email",
    fieldIsRequired: "is required",
    nameFieldInvalidCharacters: "Invalid characters",
    nameFieldCharactersLength: "needs to be at least three characters",
    passwordFieldCharactersLong: "Password must be 8 characters long.",
    passwordFieldMustContainNumber:
      "Invalid password. Must contain one number.",
    passwordNotMatched: "Password not matched",
  },
  configurationCategory: {
    glassType: "Glass Type",
    fireResistant: "Fire Resistant Glass",
    spacer1: "Spacer 1",
    gas1: "Gas",
    middleGlass: "Middle Glass (Silverstar EN2plus)",
    spacer2: "Spacer 2",
    gas2: "Gas",
    outsideGlass: "Outside Glass (Silverstar EN2plus)",
    structure: "Structure",
    manufacturer: "Manufacturer",
    fireResistanceClass: "Fire resistance (EN 13501-2)",
    insulation: "U-Value [W/m²K] (EN 673)",
    application: "Application",
    whiteGlass: "Extrawhite",
    resistanceClass: "Resistance (EN 1627)",
    buttJoints: "Butt Joints",
    sound: "Sound Reduction Rw (EN 140-3)",
  },
  filterName: {
    StructureFilter: "Structure",
    ManufacturerFilter: "Manufacturer",
    FireClassFilter: "Fire resistance",
    InsulationFilter: "U-Value",
    ApplicationFilter: "Application",
    WhiteGlassFilter: "Extra-white",
    ResistanceClassFilter: "Resistance",
    ButtJointsFilter: "Butt Joints",
    SoundFilter: "Sound Reduction",
  },
  commonConfigurationAction: {
    addToCart: "Add to cart",
    updateInCart: "Update in cart",
    resetGlass: "reset glass",
    resetConfiguration: "reset cart",
    saveAsPdf: "save as PDF",
    glassDotPdfFmt: "CDR_Glass_{date}.pdf",
    configurationDotPdfFmt: "CDR_Configuration_{date}.pdf",
    continue: "continue",
    delete: "Delete",
    complete: "Complete",
    duplicate: "Duplicate",
    edit: "Edit",
    order: "Order",
    duplicateAsDraft: "Duplicate as draft",
    firstSelectProperties: 'Select Properties',
    secondCustomize: 'Customize',
    addYourGlassToCart: 'Add your glass to the cart',
    sizeValidationError: 'Please make sure to fill in all required fields: width, height, and quantity.',
    selectProductToContinue: 'Select product to continue.',
  },
  configurationTableHeading: {
    length: "width",
    width: "height",
    quantity: "quantity",
    price: "price",
    form: "model",
    formNote:
      "For special shaped glasses please leave a note or upload a document with the specifications at the bottom.",
    notes: "notes",
    cantonalInsurance: "VKF",
    cantonalInsuranceLink: "https://www.bsronline.ch/de/registersuche/",
    total: 'total',
    totalCart: 'Total cart',
    pos: 'pos',
    pc: 'pcs',
  },
  registrationForm: {
    title: "Registration",
    registerButton: "Register",
    alreadyHaveAccount: "Already have an account? ",
    logIn: "Log in",
  },
  loginForm: {
    title: "Login",
    dontHaveAccount: "Don't have an account?",
    loginButton: "Login",
    lostPassword: "lost password",
    signUp: " SIGN Up",
    successfullyLoggedIn: "You are successfully logged in!",
    loggingInProgress: "Logging in progress",
    successfullyLoggedOut: "You have successfully logged out",
  },
  lostPasswordForm: {
    dontHaveAccount: "Don't have an account?",
    title: "lost password",
    submitButton: "reset password",
    signUp: " sign up",
    loggingInProgress: "Reset in progress",
    successfullyLoggedIn: "Reset success",
  },
  resetConfigurationForm: {
    title: "Reset Configuration",
    text: "Do you want to start a new configuration and keep the current One? Configuration code is valid for 30 days.",
    configurationCode: "Configuration code",
    resetButton: "Reset",
    or: "or",
    textNew:
      "do you want to Start a new configuration and delete all content and files.",
    resetDeleteButton: "Reset and delete",
    configurationDoesNotExist: "Configuration does not exist",
  },
  resetGlassForm: {
    title: "Reset Glass",
    text: "Do you want to reset the current glass and filters?",
    resetButton: "Reset",
  },
  confirmFilterChangesForm: {
    titleStructureChanged: "Structure Change",
    textStructureChanged:
      "This option changes the glass structure from {oldStruct} to {newStruct} and may affect other filters. Would you like to continue?",
    titleFiltersReset: "Reset Filters",
    textFiltersReset:
      "This option resets the filters {filters}. Would you like to continue?",
    yesChange:
      "Yes, change the glass structure and reset the necessary filters",
    noAbort: "No, let's abort this and try something else",
    hideMessage: 'don\'t show message again',
  },
  guestForm: {
    title: "Guest",
    additionalInfo: "Additional Infos",
    continue: "Continue",
  },
  accountCompanyForm: {
    title: "Company",
  },
  accountPage: {
    companyFormTitle: "Company",
    userFormTitle: "User",
    usersInCompanyTitle: "users in company",
    registerNewUser: "Register new user for this company",
    saveButton: "Save",
  },
  configurationCodeForm: {
    title: "Configuration code",
    enterYourCodePlaceholder: "enter your code",
    overwriteConfigurationCheckbox:
      "this will overwrite your current configuration",
    submitButton: "load",
  },
  resetPasswordForm: {
    title: "Lost password",
    submitButton: "Reset password",
  },
  homePage: {
    myGlassButton: "my glass",
  },
  cartPage: {
    configurationCodeTitle: "Configuration code",
    configurationCodeLabel: "code",
    configurationCodeDescription:
      "This is the code for your configuration, if you want to retrieve it later without login. It is valid for 30 days.",
    yourReference: "your reference",
    yourReferenceInputLabel: "reference 1",
    yourReferenceOptionalTitle: "optional",
    buildingReferenceInputLabel: "reference 2",
    buildingReferenceInputLabelPlaceholder: "Building XYZ",
    uploadedDocuments: "Uploaded documents",
    addNewTypeTitle: "Add new glass type",
    createFirstConfiguration: "Create your first configuration",
    addNewTypePlaceholderTitle: "Glass Type",
    addNewTypePlaceholderValue: "Fire resistant Glass",
    addNewTypeBtn: "add",
    cartIsEmpty: "Cart is empty",
    termsText:
      "I accept the <a href='#' target='_blank'>general terms</a> and the <a href='#' target='_blank'>liability disclaimer</a>",
  },
  pageNavigation: {
    login: "Login",
    myCode: "My Code",
    dashboard: "Dashboard",
    account: "Account",
    logout: "Logout",
    adminDashboard: "Admin Dashboard",
    configurator: 'Configurator',
  },
  additionalPageNavigation: {
    configure: "Configure",
    cart: "Cart",
    inquireOrder: "Summary",
  },
  requestOrOrderPage: {
    request: "request",
    order: "Order",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Order confirmation",
    formSubmittedText: "Your order has been submitted. Thank you.",
  },
  adminDashboardPage: {
    clientsTitle: "Clients",
  },
  contactCompanyInformation: {
    company: "company",
    address: "address",
    zip: "ZIP",
    city: "city",
    tel: "Tel",
    email: "Email",
  },
  deletePositionPopup: {
    title: "Delete position",
    description: "Are you sure you want to delete this position?",
    deleteButton: "Delete",
  },
  deleteAttachmentPopup: {
    title: "Delete attachment",
    description: "Are you sure you want to delete this attachment?",
    deleteButton: "Delete",
  },
  supportedDevicesPopup: {
    title: "Mobile",
    description:
      "This configurator is currently not supported on mobile devices. Please use a desktop computer or resize the browser.",
  },
  glassCharacteristics: {
    glassStructure: "Structure",
    fireResistance: "Fire Resistance",
    uValue: "U-Value",
    thickness: "Thickness",
    clearGlass: "Clear glass",
    resistanceClass: "Resistance Class",
    buttJoints: "Suitable for butt-joints",
    application: "Suitable applications",
    sound: "Noise dampening",
    valid: "Valid configuration",
    glassMaxSizeWarningFormat:
      "Glass is restricted to {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Unavailable due to filter",
  },
  filters: {
    changesUValue: "Changes U-Value",
    gas_argon: "Argon",
    gas_krypton: "Krypton",
    gas_mixedGas: "Mixed gas",
    gas_expensive:
      "The price for this gas is currently very volatile and expensive",
    spacer_format: "{} mm",
    structure_1Glazed: "1-glazed (Mono)",
    structure_2Glazed: "2-glazed-ISO",
    structure_3Glazed: "3-glazed-ISO",
    manufacturer_indep: "Vendor Independent",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "U-Value {}",
    application_in: "Inside",
    application_inOut: "Inside/Outside",
    application_outOut: "Outside/Outside",
    whiteGlass_yes: "Yes",
    whiteGlass_no: "No",
    resistanceClass_none: "None",
    resistanceClass_rc2: "RC2 (P4A)",
    resistanceClass_rc3: "RC3 (P5A)",
    resistanceClass_rc4: "RC4 (P6B)",
    buttJoints_yes: "Yes",
    buttJoints_no: "No",
    sound_format: "{} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Page {pageNum} of {numPages}",
  },
  firebaseErrorMessages: {
    // errors for Cloud Storage
    "storage/unknown": "An unknown error has occurred.",
    "storage/object-not-found": "No object exists at the desired reference.",
    "storage/bucket-not-found": "No bucket is configured for Cloud Storage.",
    "storage/project-not-found": "No project is configured for Cloud Storage.",
    "storage/quota-exceeded": "The quota on your Cloud Storage bucket has been exceeded. If you are on the free tier, upgrade to a paid plan. If you are on a paid plan, contact Firebase support.",
    "storage/unauthenticated": "User is not authenticated, please authenticate and try again.",
    "storage/unauthorized": "User is not authorized to perform the desired action, check your security rules to ensure they are correct.",
    "storage/retry-limit-exceeded": "The maximum time limit on an operation (upload, download, delete, etc.) has been exceeded. Try uploading again.",
    "storage/invalid-checksum": "The file on the client does not match the checksum of the file received by the server. Try uploading again.",
    "storage/canceled": "The user has canceled the operation.",
    "storage/invalid-event-name": "Invalid event name provided. Must be one of [`running`, `progress`, `pause`]",
    "storage/invalid-url": "Invalid URL provided to refFromURL(). Must be in the form: gs://bucket/object or https://firebasestorage.googleapis.com/v0/b/bucket/o/object?token=<TOKEN>",
    "storage/invalid-argument": "The argument passed to put() must be a `File`, `Blob`, or `UInt8` array. The argument passed to putString() must be a raw, `Base64`, or `Base64URL` string.",
    "storage/no-default-bucket": "No bucket has been set in your configuration's storageBucket property.",
    "storage/cannot-slice-blob": "Occurs often when the local file has changed (deleted, saved again, etc.). Try uploading again after verifying the file has not changed.",
    "storage/server-file-wrong-size": "The file on the client does not match the size of the file received by the server. Try uploading again.",

    // authentication API Errors
    "auth/claims-too-large": "The claims payload provided to setCustomUserClaims() exceeds the maximum allowed size of 1000 bytes.",
    "auth/email-already-exists": "The provided email is already in use by an existing user. Each user must have a unique email address.",
    "auth/id-token-expired": "The provided Firebase ID token has expired.",
    "auth/id-token-revoked": "The Firebase ID token has been revoked.",
    "auth/insufficient-permission": "The certificate used to initialize the Admin SDK does not have the necessary permissions to access the requested authentication resource. See Configuring a Firebase Project for documentation on generating a certificate with the appropriate permissions and using it to authenticate the Admin SDKs.",
    "auth/internal-error": "The authentication server encountered an unexpected error while trying to process the request. The error message should contain the authentication server's response containing additional information. If the error persists, please report the issue to our Bug Report support channel.",
    "auth/invalid-argument": "An invalid argument was provided to an authentication method. The error message should contain additional information.",
    "auth/invalid-claims": "The custom claims attributes provided to setCustomUserClaims() are invalid.",
    "auth/invalid-continue-uri": "The continuation URL must be a valid URL string.",
    "auth/invalid-creation-time": "The creation time must be a valid UTC date string.",
    "auth/invalid-credential": "The certificate used to authenticate the Admin SDKs cannot be used to perform the desired action. Some authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate as a refresh token or a default application token. See the documentation on initializing the SDK for how to authenticate the Admin SDKs with a certificate.",
    "auth/invalid-disabled-field": "The provided value for the user disabled property is invalid. It must be a boolean.",
    "auth/invalid-display-name": "The provided value for the user displayName property is invalid. It must be a non-empty string.",
    "auth/invalid-dynamic-link-domain": "The provided dynamic link domain is not configured or authorized for the current project.",
    "auth/invalid-email": "The provided value for the user email property is invalid. It must be an email address.",
    "auth/invalid-email-verified": "The provided value for the user emailVerified property is invalid. It must be a boolean.",
    "auth/invalid-hash-algorithm": "The hashing algorithm must match one of the strings from the list of supported algorithms.",
    "auth/invalid-hash-block-size": "The hash block size must be a valid number.",
    "auth/invalid-hash-derived-key-length": "The hash derived key length must be a valid number.",
    "auth/invalid-hash-key": "The hash key must be a valid byte buffer.",
    "auth/invalid-hash-memory-cost": "The hash memory cost must be a valid number.",
    "auth/invalid-hash-parallelization": "The hash parallelization must be a valid number.",
    "auth/invalid-hash-rounds": "The number of hash rounds must be a valid number.",
    "auth/invalid-hash-salt-separator": "The hash algorithm's salt separator field must be a valid byte buffer.",
    "auth/invalid-id-token": "The provided ID token is not a valid Firebase ID token.",
    "auth/invalid-last-sign-in-time": "The last sign-in time must be a valid UTC date string.",
    "auth/invalid-page-token": "The next page token provided in listUsers() is invalid. It must be a valid non-empty string.",
    "auth/invalid-password": "The provided value for the user password property is invalid. It must be a string of at least six characters.",
    "auth/invalid-password-hash": "The password hash must be a valid byte buffer.",
    "auth/invalid-password-salt": "The password salt must be a valid byte buffer.",
    "auth/invalid-phone-number": "The provided value for the phone number is invalid. It must be a string conforming to the E.164 standard.",
    "auth/invalid-photo-url": "The provided value for the user photoURL property is invalid. It must be a URL string.",
    "auth/invalid-provider-data": "providerData must be a valid array of UserInfo objects.",
    "auth/invalid-provider-id": "providerId must be a valid string of a supported provider identifier.",
    "auth/invalid-oauth-responsetype": "Only one OAuth response type should be set to true.",
    "auth/invalid-session-cookie-duration": "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
    "auth/invalid-uid": "The provided UID must be a non-empty string of at most 128 characters.",
    "auth/invalid-user-import": "The user record to be imported is invalid.",
    "auth/maximum-user-count-exceeded": "The maximum allowed number of users to import has been exceeded.",
    "auth/missing-android-pkg-name": "An Android package name must be provided if the Android app is to be installed.",
    "auth/missing-continue-uri": "A valid continuation URL must be provided in the request.",
    "auth/missing-hash-algorithm": "Importing users with password hashes requires providing the hashing algorithm and its parameters.",
    "auth/missing-ios-bundle-id": "The request is missing a bundle ID.",
    "auth/missing-uid": "A UID is required for the current operation.",
    "auth/missing-oauth-client-secret": "The OAuth configuration client secret is required to enable the OIDC code flow.",
    "auth/operation-not-allowed": "The provided login provider is disabled for your Firebase project. Enable it from the Login Method section in the Firebase console.",
    "auth/phone-number-already-exists": "The provided phone number is already in use by an existing user. Each user must have a unique phone number.",
    "auth/project-not-found": "No Firebase project was found for the certificate used to initialize the Admin SDKs. See Configuring a Firebase Project for documentation on generating a certificate for your project and using it to authenticate the Admin SDKs.",
    "auth/reserved-claims": "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC-specific claims such as (sub, iat, iss, exp, aud, auth_time, etc.) should not be used as keys for custom claims.",
    "auth/session-cookie-expired": "The provided Firebase session cookie has expired.",
    "auth/session-cookie-revoked": "The Firebase session cookie has been revoked.",
    "auth/too-many-requests": "The number of requests exceeds the maximum allowed.",
    "auth/uid-already-exists": "The provided UID is already in use by an existing user. Each user must have a unique UID.",
    "auth/unauthorized-continue-uri": "The domain of the continuation URL is not whitelisted. Add the domain to the Firebase console.",
    "auth/user-not-found": "There is no user record corresponding to the provided identifier.",
    "auth/wrong-password": "Wrong password",

    // other errors
    ok: "Operation successful",
    cancelled: "Operation cancelled",
    unknown: "Unknown error",
    "invalid-argument": "Invalid argument",
    "deadline-exceeded": "Deadline exceeded",
    "not-found": "Not found",
    "already-exists": "Already exists",
    "permission-denied": "Permission denied",
    unauthenticated: "Unauthenticated user",
    "resource-exhausted": "Resource exhausted",
    "failed-precondition": "Failed precondition",
    aborted: "Operation cancelled",
    "out-of-range": "Out of range",
    unimplemented: "Unimplemented",
    internal: "Internal error",
    unavailable: "Unavailable",
    "data-loss": "Data loss",
  },
};
