import * as dat from 'dat.gui';

class DatGUI
{
  constructor()
  {
    this.dat_gui = undefined;

    this.settings = {
      scale: 0.8,
      refractionIndex : 1.00125,
      dispersion : 0.005,
      opacity : 0.06,
      roughness : 0.98,
      fesnel : 0.08,
      color : 0xe0e0e0,
      floor_shadow_opacity : 0.04,
      refractionRatio: 0.98,
      roughness : 0.0,
      metalness : 1.0,
      reflectivity : 0.95,
      clearcoat :1.0,
      clearcoatRoughness : 0.4,
      turbidity: 0.5,
      rayleigh: 0.26,
      mieCoefficient: 0.002,
      mieDirectionalG: 0.72,
      elevation: 80,
      azimuth: 8.4,
      exposure: 1.0,
    };
  }

  init()
  {
    document.addEventListener('keydown', event =>
    {
      if (event.shiftKey && event.key === 'K')
      {
        document.querySelector('.dg.ac').classList.toggle('hidden');
      }
    });
  }

  start()
  {
    this.dat_gui = new dat.GUI();
    this.dat_gui.close();
    document.querySelector('.dg.ac').style['z-index'] = 999;
    document.querySelector('.dg.ac').classList.add('hidden');

    this.dat_gui.add(this.settings, 'scale', 0.1, 2);
    this.dat_gui.add(this.settings, 'refractionIndex').min(-1).max(2).step(0.0025);
    this.dat_gui.add(this.settings, 'dispersion').min(-1).max(2).step(0.0025);
    this.dat_gui.add(this.settings, 'roughness').min(0.3).max(1).step(0.0025);
    this.dat_gui.add(this.settings, 'opacity').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'fesnel').min(0).max(0.5).step(0.01);
    this.dat_gui.add(this.settings, 'floor_shadow_opacity').min(0).max(1).step(0.01);
    this.dat_gui.addColor(this.settings, 'color');

    this.dat_gui.add(this.settings, 'refractionRatio').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'roughness').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'metalness').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'reflectivity').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'clearcoat').min(0).max(1).step(0.01);
    this.dat_gui.add(this.settings, 'clearcoatRoughness').min(0).max(1).step(0.01);

    this.dat_gui.add( this.settings, 'turbidity', 0.0, 20.0, 0.1 );
    this.dat_gui.add( this.settings, 'rayleigh', 0.0, 4, 0.001 );
    this.dat_gui.add( this.settings, 'mieCoefficient', 0.0, 0.1, 0.001 );
    this.dat_gui.add( this.settings, 'mieDirectionalG', 0.0, 1, 0.001 );
    this.dat_gui.add( this.settings, 'elevation', 0, 90, 0.1 );
    this.dat_gui.add( this.settings, 'azimuth', - 180, 180, 0.1 );
    this.dat_gui.add( this.settings, 'exposure', 0, 2, 0.05 );

    this.dat_gui.width = 400;
  }
}

export default new DatGUI();
