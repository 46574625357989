import {
	StyleSheet,
} from '@react-pdf/renderer';

const styles = StyleSheet.create({
	header: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
	},
	headerLogo: {
		width: 56,
		height: 35,
		marginRight: 26,
	},
	headerLogoImg: {
		objectFit: 'contain',
		width: '100%',
		height: '100%',
	},
	headerInfo: {
		display: 'flex',
		flexWrap: 'wrap',
		maxWidth: 210,
		flexDirection: 'row',
	},
	headerInfoItem: {
		width: '50%',
		paddingRight: 23,
		fontSize: 8,
		color: '#707070',
		textTransform: 'uppercase',
	},
});

export default styles;
