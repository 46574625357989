export function resetFilters() {
    window.filters_handler.ui.resetGlass();
}

export function confirmFilterChanges() {
    window.filters_handler.ui.confirmFilterChanges();
}

export function abortFilterChanges() {
    window.filters_handler.ui.abortFilterChanges();
}
