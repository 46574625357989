import AbstractFilter from './AbstractFilter.js'

export default class InsulationFilter extends AbstractFilter {

	constructor() {
		super("InsulationFilter", false);

		// Modified by setUValueOptions
		this.i18n_prefix = "insulation_";
		this.filterOptions = {
			"5.4": "UValueFormat",
			"1.7": "UValueFormat",
			"1.4": "UValueFormat",
			"1.3": "UValueFormat",
			"1.2": "UValueFormat",
			"1.1": "UValueFormat",
			"1.0": "UValueFormat",
			"0.9": "UValueFormat",
			"0.8": "UValueFormat",
			"0.7": "UValueFormat",
			"0.6": "UValueFormat",
			"0.5": "UValueFormat",
		};
		this.uValue = 0.0;
	}

	setUValueOptions(uValues) {
		Object.keys(this.filterOptions).forEach((key) => delete this.filterOptions[key]);
		uValues.forEach(v => (this.filterOptions[`${v.toFixed(1)}`] = "UValueFormat"));
	}

	setFilter(filterOption) {
		this.uValue = filterOption ? parseFloat(filterOption) : 0.0;
		super.setFilter(filterOption);
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null) {
			return;
		}

		for (var i = 0; i < dbView['uValue']['1-glazed'].length; ++i) {
			if (dbView['uValue']['1-glazed'][i].u_value != this.uValue) {
				dbView['uValueFiltered']['1-glazed'].push(dbView['uValue']['1-glazed'].splice(i, 1)[0]);
				i -= 1;
			}
		}
		for (var i = 0; i < dbView['uValue']['2-glazed'].length; ++i) {
			if (dbView['uValue']['2-glazed'][i].uValue != this.uValue) {
				dbView['uValueFiltered']['2-glazed'].push(dbView['uValue']['2-glazed'].splice(i, 1)[0]);
				i -= 1;
			}
		}
		for (var i = 0; i < dbView['uValue']['3-glazed'].length; ++i) {
			if (dbView['uValue']['3-glazed'][i].uValue != this.uValue) {
				dbView['uValueFiltered']['3-glazed'].push(dbView['uValue']['3-glazed'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}
