import { BlockHeading } from 'components/BlockHeading';
import { FormSearchField } from 'components/Form';
import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { COMPANIES_API } from 'api';
import { ClientBlock } from 'components/ClientBlock';
import { AuthContext } from 'context/auth';
import { chunkArray, cloneObjectWithoutReference, generateUniqueId } from 'utils';
import { PaginationBlock } from 'components/PaginationBlock';

const ClientsList = ({
	setTotalNumberClientsCompany,
}) => {
	const { t } = useTranslation('baseFormPlaceholders');
	const [clientCompanies, setClientCompanies] = useState(null);
	const [filteredClientCompanies, setFilteredClientCompanies] = useState(null);
	const [chunkedConfigurationData, setChunkedConfigurationData] = useState(null);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const [isSearchApplied, setSearchApplied] = useState(null);
	const [isPaginationShown, setPaginationShown] = useState(false);
	const [activePaginationPage, setActivePaginationPage] = useState(1);
	const [totalClientsCompanyItemsCount, setClientsCompanyItemsCount] = useState(1);

	const clientCompanyCountPerPage = useRef(10);

	const removeAdminCompanyFromClientList = (companiesList) => {
		if (!companiesList) return null;

		const filteredCompanies = companiesList.filter(company => {
			return company.id !== currentUserAdditionalData.companyId;
		});

		return filteredCompanies;
	};

	const updateCurrentActivePaginationPage = () => {
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[activePaginationPage - 1]);

		setFilteredClientCompanies(activePageData);
	};

	const handleOnChangeSearchInput = (e) => {
		const inputValueLowerCase = e.target.value.toLowerCase();

		if (inputValueLowerCase.trim() !== '') {
			const filteredArray = clientCompanies.filter(({
				address,
				city,
				email,
				name,
				tel,
				zip,
			}) => {
				const addressLowerCase = address ? address.toLowerCase() : '';
				const cityReferenceLowerCase = city ? city.toLowerCase() : '';
				const emailLowerCase = email ? email.toLowerCase() : '';
				const nameEditedLowerCase = name ? name.toLowerCase() : '';
				const telEditedLowerCase = tel ? tel.toLowerCase() : '';
				const zipEditedLowerCase = zip ? zip.toLowerCase() : '';

				return (
					addressLowerCase.includes(inputValueLowerCase)
					|| cityReferenceLowerCase.includes(inputValueLowerCase)
					|| emailLowerCase.includes(inputValueLowerCase)
					|| nameEditedLowerCase.includes(inputValueLowerCase)
					|| telEditedLowerCase.includes(inputValueLowerCase)
					|| zipEditedLowerCase.includes(inputValueLowerCase)
				);
			});

			setFilteredClientCompanies(filteredArray);
			setSearchApplied(true);
		} else {
			if (isPaginationShown) {
				updateCurrentActivePaginationPage();
			} else {
				const clonedFilteredCompanies = cloneObjectWithoutReference(clientCompanies);

				setFilteredClientCompanies(clonedFilteredCompanies);
			}
			setSearchApplied(false);
		}
	};

	const splitConfigurationsByPage = (clientsCompanyListData) => {
		if (!clientsCompanyListData) return;

		const chunkedData = chunkArray(clientsCompanyListData, clientCompanyCountPerPage.current);
		const activePageData = cloneObjectWithoutReference(chunkedData[activePaginationPage - 1]);

		setClientsCompanyItemsCount(clientsCompanyListData.length);
		setFilteredClientCompanies(activePageData);
		setChunkedConfigurationData(chunkedData);
		setPaginationShown(true);
	};

	const fetchAllClientsCompanies = async () => {
		const res = await COMPANIES_API.getClientCompanies();

		if (res.length) {
			const filteredCompanies = removeAdminCompanyFromClientList(res);

			setClientCompanies(filteredCompanies);

			if (setTotalNumberClientsCompany && typeof setTotalNumberClientsCompany === 'function') {
				setTotalNumberClientsCompany(filteredCompanies.length);
			}

			if (filteredCompanies.length > clientCompanyCountPerPage.current) {
				splitConfigurationsByPage(filteredCompanies);
			} else {
				const clonedFilteredCompanies = cloneObjectWithoutReference(filteredCompanies);

				setFilteredClientCompanies(clonedFilteredCompanies);
			}
		}
	};

	const setNewActivePaginationPage = (pageNumber) => {
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[pageNumber - 1]);

		setActivePaginationPage(pageNumber);
		setFilteredClientCompanies(activePageData);
	};

	const handlePaginationPageChange = (pageNumber) => {
		setNewActivePaginationPage(pageNumber);
	};

	useEffect(() => {
		fetchAllClientsCompanies();
	}, []);

	return (
		<div className="dashboard_block">
			<div className="dashboard_block__heading">
				<BlockHeading
					title={t('adminDashboardPage:clientsTitle')}
					iconName="user"
				/>
				<div className="dashboard_block__field">
					<FormSearchField
						id="clientSearchField"
						placeholder={t('searchField')}
						name="clientSearchField"
						onChange={handleOnChangeSearchInput}
					/>
				</div>
			</div>
			{filteredClientCompanies && filteredClientCompanies.length > 0 ? (
				<ul className="clients_list">
					{filteredClientCompanies.map(({
						address,
						city,
						email,
						id,
						name,
						tel,
						zip,
					}) => {
						return (
							<li className="clients_list__item" key={id}>
								<ClientBlock
									address={address}
									city={city}
									email={email}
									id={id}
									name={name}
									tel={tel}
									zip={zip}
									fetchAllClientsCompanies={fetchAllClientsCompanies}
								/>
							</li>
						);
					})}
				</ul>
			) : null}
			{filteredClientCompanies && isPaginationShown && !isSearchApplied ? (
				<PaginationBlock
					activePaginationPage={activePaginationPage}
					itemsCountPerPage={clientCompanyCountPerPage.current}
					totalItemsCount={totalClientsCompanyItemsCount}
					handlePaginationPageChange={handlePaginationPageChange}
				/>
			) : null}
		</div>
	);
};

export default ClientsList;
