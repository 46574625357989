import { CameraManager } from 'ohzi-core';
import { SceneManager } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import { Graphics } from 'ohzi-core';

import { Object3D } from 'three';
import { WebGLRenderTarget } from 'three';
import { HalfFloatType } from 'three';

import GenericGlass from './GenericGlass';
import Tape from './Tape';
import Spacer from './Spacer';
import MeasurementArrows from './MeasurementArrows';

export default class Structure extends Object3D {

  constructor() {
    super();

    this.rt = new WebGLRenderTarget(
      Screen.width,
      Screen.height,
      {
        type: HalfFloatType
      }
    );
    this.glasses = [];

    this.measurementArrows = new MeasurementArrows();
    this.measurementArrows.position.x = 2; // in glass direction
    this.measurementArrows.position.y = -0.2; // height

    this.tapeInitial = new Tape();
    this.tapeFinal = new Tape();

    this.tapeInitial.position.z = - this.tapeInitial.width;

    this.add(this.tapeInitial);
    this.add(this.tapeFinal);

    this.frg = new GenericGlass(this, this.rt, 0, true);
    this.middle_glass = new GenericGlass(this, this.rt, 1);
    this.outside_glass = new GenericGlass(this, this.rt, 2);

    this.spacer_middle_glass = new Spacer();
    this.spacer_outside_glass = new Spacer();

    this.add(this.frg);
    this.add(this.middle_glass);
    this.add(this.outside_glass);
    this.add(this.spacer_middle_glass);
    this.add(this.spacer_outside_glass);

    SceneManager.textScene.add(this.measurementArrows);

    this.glasses.push(this.frg);
    this.glasses.push(this.middle_glass);
    this.glasses.push(this.outside_glass);

    this._onTransitionEndCb = null;
    this._transitionInProgress = false;
  }

  _onTransitionEnd() {
    if (this._onTransitionEndCb) {
      this._onTransitionEndCb();
      this._onTransitionEndCb = null;
    }
  }

  /**
   * Set a callback for when the animation transition is finished
   * @param {*} cb callback
   * @param {*} instantIfNotTransitioning call cb() instantly if no transition is currently in progress
   */
  setTransitionEndCallback(cb, instantIfNotTransitioning = true) {
    if (instantIfNotTransitioning && !this._transitionInProgress) {
      cb();
    } else {
      this._onTransitionEnd = cb;
    }
  }

  set(frg, mg, og, smg = { thickness: 0 }, sog = { thickness: 0 }, rotate_frg) {
    frg = frg ?? { thickness: 0, layers: [this.frg.thickness + ':0'] };
    mg = mg ?? { thickness: 0, layers: [this.middle_glass.thickness + ':0'] };
    og = og ?? { thickness: 0, layers: [this.outside_glass.thickness + ':0'] };

    let initial_structure = {
      frg: { thickness: this.frg.thickness, layers: [this.frg.thickness + ':0'] },
      middle_glass: { thickness: this.middle_glass.thickness, layers: [this.middle_glass.thickness + ':0'] },
      outside_glass: { thickness: this.outside_glass.thickness, layers: [this.outside_glass.thickness + ':0'] },
      spacer_middle_glass: this.spacer_middle_glass,
      spacer_outside_glass: this.spacer_outside_glass,
    };

    let target_structure = {
      frg: { thickness: frg.thickness, layers: frg.layers },
      middle_glass: { thickness: mg.thickness, layers: mg.layers },
      outside_glass: { thickness: og.thickness, layers: og.layers },
      spacer_middle_glass: { thickness: smg.thickness },
      spacer_outside_glass: { thickness: sog.thickness }
    };

    if (rotate_frg) {
      target_structure.frg.layers = target_structure.frg.layers.slice().reverse();
    }

    this.__lerp_structure(initial_structure, target_structure, 1000);
  }

  __lerp_structure(str1, str2, time) {
    // this function can be called several times
    // if that's the case, the animation should reset
    // and the events should be cleared
    this._transitionInProgress = true;

    if (this.___interrupt) this.___interrupt();

    this.___interrupt = () => {
      this.____set(str1.frg, str1.middle_glass, str1.outside_glass, str1.spacer_middle_glass, str1.spacer_outside_glass);
      window.clearInterval(this.___interval);
    };

    let interval_ms = 8;
    let lerper = 0;

    // we need to make 1 step in time mili seconds
    // but we do steps every $interval_ms ms interval, so each step size
    let step_size = (1.0 / time) * interval_ms;

    this.___timeout = (() => {
      this.____set(str2.frg, str2.middle_glass, str2.outside_glass, str2.spacer_middle_glass, str2.spacer_outside_glass);
      this.___interrupt = undefined;
      window.clearInterval(this.___interval);
      this._transitionInProgress = false;
      setTimeout(this._onTransitionEnd, 0);

    }).bind(this);

    this.___interval = setInterval((() => {
      if (lerper > 1.0) {
        this.___timeout();
        return;
      }

      let _lerper = Math.pow(lerper, 2);

      let lerp_structure = {
        'frg': { 'thickness': str1.frg.thickness * (1 - _lerper) + str2.frg.thickness * _lerper, 'layers': str2.frg.layers },
        'middle_glass': { 'thickness': str1.middle_glass.thickness * (1 - _lerper) + str2.middle_glass.thickness * _lerper, 'layers': str2.middle_glass.layers },
        'outside_glass': { 'thickness': str1.outside_glass.thickness * (1 - _lerper) + str2.outside_glass.thickness * _lerper, 'layers': str2.outside_glass.layers },
        'spacer_middle_glass': { 'thickness': str1.spacer_middle_glass.thickness * (1 - _lerper) + str2.spacer_middle_glass.thickness * _lerper },
        'spacer_outside_glass': { 'thickness': str1.spacer_outside_glass.thickness * (1 - _lerper) + str2.spacer_outside_glass.thickness * _lerper }
      };
      this.____set(lerp_structure.frg, lerp_structure.middle_glass, lerp_structure.outside_glass, lerp_structure.spacer_middle_glass, lerp_structure.spacer_outside_glass);

      lerper += step_size;

    }).bind(this), interval_ms);
  }

  ____set(frg, mg, og, smg, sog) {
    const bias = 0.0005;
    let space = bias;
    // INITIAL TAPE ALWAYS AT 0
    // FRG

    this.frg.set(frg.thickness, frg.layers);
    this.frg.shiftZ = space;
    space += this.frg.width + bias;
    // FINAL TAPE

    this.tapeFinal.set_short(sog.thickness > 0.0005);
    this.tapeFinal.shiftZ = space + 0.005;
    space += this.tapeFinal.width + bias;

    // SPACER 1
    this.spacer_middle_glass.set(smg.thickness);
    this.spacer_middle_glass.shiftZ = space;
    space += this.spacer_middle_glass.width + bias;
    // MIDDLE GLASS

    this.middle_glass.set(mg.thickness, mg.layers);
    this.middle_glass.shiftZ = space;
    space += this.middle_glass.width + bias;
    // SPACER 2

    this.spacer_outside_glass.set(sog.thickness);
    this.spacer_outside_glass.shiftZ = space;
    space += this.spacer_outside_glass.width + bias;
    // OUTSIDE GLASS

    this.outside_glass.set(og.thickness, og.layers);
    this.outside_glass.shiftZ = space;
    space += this.outside_glass.width + bias;

    this.structure_width = space;

    this.measurementArrows.set(space, Number(sog.thickness) + Number(og.thickness) + Number(mg.thickness) + Number(smg.thickness) + Number(frg.thickness));
    this.position.z = -(this.structure_width / 2.0);
    this.measurementArrows.position.z = this.position.z; // proximity to glass
  }

  init() {
  }

  start() {
  }

  update() {
    Graphics.render(SceneManager.backScene, CameraManager.current, this.rt);
    Graphics._renderer.clear(true, true);

    for (let i = 0; i < this.glasses.length; i++) {
      this.glasses[i].update();
    }

    this.tapeInitial.update();
    this.tapeFinal.update();
  }
}
