import Insulation from "./InsulationValues";
import SoundDampening from "./SoundDampeningValues";


export default class GlassStructure {

  constructor(webGlComponent, initialValues = null) {
    this.webGlComponent = webGlComponent;
    this.initialize_values(initialValues);
    this.updatePending = false;
  }

  initialize_values(initialValues) {
    this.fireResistantGlass = initialValues ? initialValues.fireResistantGlass : undefined;
    this.middleGlass = initialValues ? initialValues.middleGlass : undefined;
    this.outerGlass = initialValues ? initialValues.outerGlass : undefined;
    this.spacerMiddle = initialValues ? { ...initialValues.spacerMiddle } : { thickness: 0 };
    this.spacerOutside = initialValues ? { ...initialValues.spacerOutside } : { thickness: 0 };
    this.uValue = initialValues ? initialValues.uValue : undefined; // unused but passed to WebGL
    this.gasMiddle = initialValues ? initialValues.gasMiddle : null;
    this.gasOutside = initialValues ? initialValues.gasOutside : null;
    this.insideOutsideApplication = initialValues ? initialValues.insideOutsideApplication : undefined;
    this.invert_frg = initialValues ? initialValues.invert_frg : false;
  }

  getSnapshot() {
    let snapshot = new GlassStructure(this.webGlComponent, this);
    snapshot.updateView = () => {};
    delete snapshot.webGlComponent;
    delete snapshot.applySnapshot;
    delete snapshot.updatePending;
    return snapshot;
  }

  applySnapshot(snapshot) {
    this.fireResistantGlass = snapshot.fireResistantGlass;
    this.middleGlass = snapshot.middleGlass;
    this.outerGlass = snapshot.outerGlass;
    this.spacerMiddle = { ...snapshot.spacerMiddle };
    this.spacerOutside = { ...snapshot.spacerOutside };
    this.gasMiddle = snapshot.gasMiddle;
    this.gasOutside = snapshot.gasOutside;
    this.insideOutsideApplication = snapshot.insideOutsideApplication;
    this.invert_frg = snapshot.invert_frg;
    this.uValue = snapshot.uValue ?? this.getInsulationValue();
    this.updateView();
  }

  getFlatSnapshot(db) {
    return {
      fireResistantGlassId: this.fireResistantGlass.id,
      middleGlassId: this.middleGlass ? this.middleGlass.id : null,
      outerGlassId: this.outerGlass ? this.outerGlass.id : null,
      spacerMiddle: this.spacerMiddle ? this.spacerMiddle.thickness : 0,
      spacerOutside: this.spacerOutside ? this.spacerOutside.thickness : 0,
      gasMiddle: this.gasMiddle,
      gasOutside: this.gasOutside,
      // params:
      fireResistance: this.getFireResistance(),
      manufacturer: this.fireResistantGlass.manufacturer,
      insideOutsideApplication: this.insideOutsideApplication,
      insulationValue: this.getFormattedInsulationValue(),
      isButtJointCompatible: this.isButtJointCompatible(),
      isClearGlass: this.isClearGlass(),
      resistanceClass: this.getResistanceClass(),
      soundDampening: SoundDampening.getSoundValue(this),
      structure: this.getGlassStructure(),
      thickness: this.calculateThickness(),
      sqmPrice: db.getGlassPrice(this),
    }
  }

  applyFlatSnapshot(flatSnapshot, db) {
    this.applySnapshot({
      fireResistantGlass: db.getGlassById(flatSnapshot.fireResistantGlassId, 'frg'),
      middleGlass: flatSnapshot.middleGlassId && db.getGlassById(flatSnapshot.middleGlassId, 'mid'),
      outerGlass: flatSnapshot.outerGlassId && db.getGlassById(flatSnapshot.outerGlassId, 'out'),
      spacerMiddle: { thickness: flatSnapshot.spacerMiddle },
      spacerOutside: { thickness: flatSnapshot.spacerOutside },
      gasMiddle: flatSnapshot.gasMiddle,
      gasOutside: flatSnapshot.gasOutside,
      insideOutsideApplication: flatSnapshot.insideOutsideApplication,
      invert_frg: (flatSnapshot.insideOutsideApplication === 'Outside/Outside'),
    });
  }

  /**
   * Returns the structure of the glass:
   * '1-glazed', '2-glazed', '3-glazed'
   */
  getGlassStructure() {
    if (this.middleGlass) { return '3-glazed'; }
    if (this.outerGlass) { return '2-glazed'; }
    return '1-glazed';
  }

  getGlassByLabel(label) {
    if (label === 'frg') { return this.fireResistantGlass; }
    if (label === 'mid') { return this.middleGlass; }
    if (label === 'out') { return this.outerGlass; }
    console.error(`No such label ${label}`);
  }

  getInsulationValue() {
    let struct = this.getGlassStructure();
    switch (struct) {
      case '1-glazed': return this.fireResistantGlass.u_value;

      case '2-glazed':
        if (this.spacerOutside && this.gasOutside) {
          let filtered = Insulation.uValueTable['2-glazed'].filter((o) => (
            o.spacer1 === this.spacerOutside.thickness && o.gas === this.gasOutside
          ));
          if (filtered.length > 0) {
            return filtered[0].uValue;
          }
        }
        return -1;

      case '3-glazed':
        if (this.spacerOutside && this.gasOutside && this.spacerMiddle && this.gasMiddle) {
          let filtered = Insulation.uValueTable['3-glazed'].filter((o) => (
            o.spacer1 === this.spacerOutside.thickness && o.gas === this.gasOutside &&
            o.spacer2 === this.spacerMiddle.thickness /* && o.gas2 === this.gasMiddle */
          ));
          if (filtered.length > 0) {
            return filtered[0].uValue;
          }
        }
        return -1;
    }
  }

  getFormattedInsulationValue() {
    return this.getInsulationValue().toFixed(1);
  }

  getFireResistance() {
    return this.fireResistantGlass.fireResistanceClass;
  }

  getResistanceClass() {
    let struct = this.getGlassStructure();
    if (struct === '1-glazed') {
      return this.fireResistantGlass.resistanceClass;
    }
    return this.outerGlass ? this.outerGlass.resistanceClass : undefined;
  }

  /// True if all glasses are white glasses (also see isMixedClearGlass())
  isClearGlass() {
    let struct = this.getGlassStructure();
    let clear = true;
    switch (struct) {
      // Fall-through at every level
      case '3-glazed': if (this.middleGlass) clear = this.middleGlass.white_glass;
      case '2-glazed': if (this.outerGlass) clear = clear && this.outerGlass.white_glass;
      case '1-glazed': clear = clear && this.fireResistantGlass.white_glass;
      default: return clear;
    }
  }

  /// True if some glasses are white glasses and some are not (also see isClearGlass())
  isMixedClearGlass() {
    let struct = this.getGlassStructure();
    let clear = null;
    let nonclear = null;
    switch (struct) {
      // Fall-through at every level
      case '3-glazed': if (this.middleGlass) {
        clear = this.middleGlass.white_glass;
        nonclear = !this.middleGlass.white_glass;
      }
      case '2-glazed': if (this.outerGlass) {
        if (clear == null) {
          clear = this.outerGlass.white_glass;
          nonclear = !this.outerGlass.white_glass;
        } else {
          clear = clear || this.outerGlass.white_glass;
          nonclear = nonclear || !this.outerGlass.white_glass;
        }
      }
      case '1-glazed':
        if (clear == null) {
          clear = this.fireResistantGlass.white_glass;
          nonclear = !this.fireResistantGlass.white_glass;
        } else {
          clear = clear || this.fireResistantGlass.white_glass;
          nonclear = nonclear || !this.fireResistantGlass.white_glass;
        }
      default: return clear && nonclear;
    }
  }

  isButtJointCompatible() {
    let struct = this.getGlassStructure();
    return struct === '1-glazed' && this.fireResistantGlass.butt_joints;
  }

  reduceStructure() {
    if (!this.outerGlass) {
      this.spacerOutside.thickness = 0;
    }
    if (!this.middleGlass) {
      this.spacerMiddle.thickness = 0;
      this.outerGlass = undefined;
    }
  }

  calculateThickness() {
    return this.fireResistantGlass.thickness +
      this.spacerMiddle.thickness + this.spacerOutside.thickness +
      (this.middleGlass ? this.middleGlass.thickness : 0) +
      (this.outerGlass ? this.outerGlass.thickness : 0);
  }

  // spacerOutside, spacerMiddle, gasOutside, gasMiddle
  setSpacersAndGas({ spacer1 = undefined, spacer2 = undefined, gas1 = undefined, gas2 = undefined }) {
    console.log(`Spacers and Gas: ${spacer1} ${spacer2} ${gas1} ${gas2}`);
    // 1-glazed means there's no outer glass set
    // 2-glazed means there's an outer glass set
    // 3-glazed means there's a middle glass set
    // commented out: don't update the spacer values if it shouldn't be there based on the glass type
    if (spacer1 !== undefined) {
      this.spacerOutside.thickness = spacer1; // this.outerGlass ? spacer1 : 0;
    }
    if (spacer2 !== undefined) {
      this.spacerMiddle.thickness = spacer2; // this.middleGlass ? spacer2 : 0;
    }
    if (gas1 !== undefined) {
      this.gasOutside = gas1 === 0 ? null : gas1;
    }
    if (gas2 !== undefined) {
      this.gasMiddle = gas2 === 0 ? null : gas2;
    }

    this.updateView();
  }

  setOuterGlass(outerGlass) {
    this.insideOutsideApplication = outerGlass ? outerGlass.application : this.fireResistantGlass.application;
    this.outerGlass = outerGlass;
    this._updateInsideOutsideApplication();
    this.updateView();
  }

  setMiddleGlass(middleGlass) {
    this.middleGlass = middleGlass;
    this.updateView();
  }

  setFireResistantGlass(fireResistantGlass) {
    this.fireResistantGlass = fireResistantGlass;
    this._updateInsideOutsideApplication();
    this.updateView();
  }

  _updateInsideOutsideApplication() {
    if (this.outerGlass) {
      if (this.outerGlass.application === 'Inside/Outside' && this.fireResistantGlass.application === 'Inside/Outside') {
        this.insideOutsideApplication = 'Outside/Outside';
      } else {
        // Needs an additional else-if to check if both glasses are inside if it's a valid configuration to use the FRG as outside glass.
        // Since this should not be a valid configuration at this point, we'll ignore it.
        this.insideOutsideApplication = this.outerGlass.application;
      }
    } else {
      this.insideOutsideApplication = this.fireResistantGlass.application;
    }

    this.invert_frg = (this.insideOutsideApplication === 'Outside/Outside');
  }

  updateView() {
    if (!this.updatePending) {
      console.info("scheduling webgl update");
      this.updatePending = true;
      setTimeout(this._dispatchUpdateView.bind(this), 100);
    }
  }

  _dispatchUpdateView() {
    if (!this.webGlComponent.areAllModelsLoaded()) {
      console.log("webGL not ready");
      setTimeout(this._dispatchUpdateView.bind(this), 300);
      return;

    }
    this.updatePending = false;

    // DEBUG: verbose printout
    // const now = new Date();
    // const timestamp = now.toLocaleTimeString() + '.' + now.getMilliseconds();
    // console.info(`${timestamp} updating webgl`);

    this.webGlComponent.updateLabels(
      this.fireResistantGlass,
      this.outerGlass,
      this.middleGlass,
      this.spacerOutside,
      this.spacerMiddle,
      this.uValue,
      this.gasOutside,
      this.gasMiddle,
      this.invert_frg,
    );

    this.webGlComponent.setInsideOutside(this.insideOutsideApplication);
  }
}
