import { PerspectiveCamera } from 'ohzi-core';
import { CameraManager } from 'ohzi-core';
import { SceneManager } from 'ohzi-core';
import { Screen } from 'ohzi-core';
import { Graphics } from 'ohzi-core';
/// import { Debug, Grid } from 'ohzi-core';

import { Color } from 'three';
import { Fog } from 'three';
import { AmbientLight } from 'three';
import { DirectionalLight } from 'three';
import { SpotLight } from 'three';
import { Object3D } from 'three';

import CameraController from '../camera_controller/CameraController';
import CameraSimpleState from '../camera_controller/states/CameraSimpleState';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Sun from './Sun';

class SceneController {
  init() {
    this.initial_cam_pos = { zoom: 12, orientation: 120, tilt: 10, position: { x: 1.0, y: 1.0, z: 0.0 } };
    this.camera_controller = new CameraController(this.initial_cam_pos);
    this.sun = null;
    this.outdoor_model = [null, null];
    this.indoor_model = [null, null];
  }

  set_initial_cam_pos(initial_cam_pos) {
    this.initial_cam_pos = initial_cam_pos;
  }

  start() {
    this.__init_camera();
    this.__init_camera_controller();

    this.add_lights();

    // Begin helper scale for positioning objects
    // import { Debug, Grid } from 'ohzi-core';
    /// Debug.draw_sphere();
    /// Debug.draw_axis();
    /// SceneManager.current.add(new Grid());
    // End helper scale

    SceneManager.current.fog = new Fog(0x050505, 50, 100);

    // Here we add all objects into the scene
    this.sun = new Sun();
    this.sun.position.set(1.0, 1.0, 2.8);
    this.sun.visible = false;
    SceneManager.current.add(this.sun);

    // Iterate through each object in the scene
    let updateHSLSceneTraversal = (hueFactor, saturationFactor, luminosityFactor) => {
      return (object) => {
        if (object.isMesh) {
          // Check if the object has a material
          if (object.material) {
            // Reduce the color saturation of the material
            object.material.color.convertSRGBToLinear(); // Convert color space if needed

            // Convert color to HSL
            const hsl = {};
            object.material.color.getHSL(hsl);

            // Adjust saturation and luminosity
            hsl.s *= saturationFactor;
            hsl.l *= luminosityFactor;

            object.material.color.setHSL(hsl.h, hsl.s, hsl.l);

            // Update the material to reflect the changes
            object.material.needsUpdate = true;
          }
        }
      };
    };

    const loader = new GLTFLoader();
    loader.load(
      'data/lowpoly_office_chair/scene.gltf',
      (gltf) => {
        const scale = 0.8;
        gltf.scene.scale.set(scale, scale, scale);
        gltf.scene.castShadow = true;
        gltf.scene.traverse(updateHSLSceneTraversal(1.0, 0.2, 2.0));
        this.indoor_model[0] = gltf.scene;
        this.indoor_model[1] = gltf.scene.clone();
        // positions: (glass direction, height, proximity to glass surface)
        this.indoor_model[0].position.set(0.7, 0.0, -1.7);
        this.indoor_model[0].name = 'indoor-model0';
        this.indoor_model[1].position.set(0.7, 0.0, 1.6);
        this.indoor_model[1].name = 'indoor-model1'
        this.indoor_model[1].rotation.y = Math.PI;
        SceneManager.current.add(this.indoor_model[0]);
        SceneManager.current.add(this.indoor_model[1]);
      }, (xhr) => {
        // console.debug(`GLTF is ${(xhr.loaded / xhr.total * 100)}% loaded`);
      },
      (error) => {
        console.error('An error occured while loading the indoor model gltf', error);
      }
    );

    loader.load(
      'data/low_poly_tree_concept/scene.gltf',
      (gltf) => {
        const scale = .0035;
        gltf.scene.scale.set(scale, scale, scale);
        gltf.scene.castShadow = true;
        gltf.scene.visible = false;
        gltf.scene.traverse(updateHSLSceneTraversal(1.0, 0.01, 1.5));
        this.outdoor_model[0] = gltf.scene; // the tree by the sun ("left")
        this.outdoor_model[1] = gltf.scene.clone(); // the tree without sun ("right")
        // positions: (glass direction, height, proximity to glass surface)
        this.outdoor_model[0].position.set(-0.6, -0.05, 2.5);
        this.outdoor_model[0].name = 'outdoor-model0';
        this.outdoor_model[1].position.set(-0.6, -0.05, -1.5);
        this.outdoor_model[1].name = 'outdoor-model1';
        SceneManager.current.add(this.outdoor_model[0]);
        SceneManager.current.add(this.outdoor_model[1]);
      }, (xhr) => {
        // console.debug(`GLTF is ${(xhr.loaded / xhr.total * 100)}% loaded`);
      },
      (error) => {
        console.error('An error occured while loading the outdoor model gltf', error);
      }
    );

    // Compile shaders on the first frame
    Graphics.update();
  }

  add_lights() {
    let light = new AmbientLight('#FFFFFF', 0.4);
    SceneManager.current.add(light);

    // let directional_light = new DirectionalLight('#FFFFFF', 1);
    // directional_light.position.set(0, 10, 20);
    // SceneManager.current.add(directional_light);

    const spotLight = new SpotLight(0xffffff, 0.5);
    spotLight.position.set(1, 5, 3);
    spotLight.angle = Math.PI / 8;
    spotLight.penumbra = 0.8;
    spotLight.decay = 2;
    spotLight.distance = 10;
    spotLight.target.position.x = -15;
    spotLight.target.updateMatrix = true;

    const targetObject = new Object3D();
    targetObject.position.x = 1;
    SceneManager.current.add(targetObject);
    spotLight.target = targetObject;

    spotLight.castShadow = true;
    spotLight.shadow.mapSize.width = 1024 * 2;
    spotLight.shadow.mapSize.height = 1024 * 2;
    spotLight.shadow.camera.near = 3.7;
    spotLight.shadow.camera.far = 5.9;
    spotLight.shadow.focus = 1.0;
    spotLight.shadow.bias = -0.002;
    spotLight.shadow.radius = 20;

    SceneManager.current.add(spotLight);
    let shadowClone = spotLight.clone();
    shadowClone.shadow.mapSize.width = 1024 * 2;
    shadowClone.shadow.mapSize.height = 1024 * 2;
    shadowClone.shadow.camera.near = 3.7;
    shadowClone.shadow.camera.far = 5.9;
    shadowClone.shadow.focus = 1.0;
    shadowClone.shadow.bias = - 0.002;
    shadowClone.shadow.radius = 20;

    SceneManager.frontScene.add(shadowClone);

    const targetObjectShadow = new Object3D();
    targetObjectShadow.position.x = 1;
    SceneManager.frontScene.add(targetObjectShadow);
    shadowClone.target = targetObjectShadow;

    const spotLight2 = new DirectionalLight(0xffffff, 0.2);
    spotLight2.position.set(4, 6, 8);

    SceneManager.current.add(spotLight);

    //const shadowCameraHelper = new CameraHelper( spotLight.shadow.camera );
    //SceneManager.current.add( shadowCameraHelper );

  }

  update() {
    this.camera_controller.update();
  }

  __init_camera() {
    let camera = new PerspectiveCamera(60, Screen.aspect_ratio, 0.1, 200);
    camera.updateProjectionMatrix();
    camera.position.z = 10;

    camera.clear_color.copy(new Color('#181818'));
    camera.clear_alpha = 1;
    camera.fov = 30
    CameraManager.current = camera;
  }

  __init_camera_controller() {
    this.camera_controller.set_camera(CameraManager.current);
    // this.camera_controller.set_idle();
    //this.camera_controller.set_standard_mode();
    this.camera_controller.set_state(new CameraSimpleState());

    this.camera_controller.min_zoom = 1;
    this.camera_controller.max_zoom = 40;
    this.camera_controller.reference_zoom = this.initial_cam_pos.zoom;
    this.camera_controller.reference_position.set(this.initial_cam_pos.position.x, this.initial_cam_pos.position.y, this.initial_cam_pos.position.z);
    this.camera_controller.set_rotation(this.initial_cam_pos.tilt, this.initial_cam_pos.orientation);
  }
}

export default new SceneController();
