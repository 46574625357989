import { useContext, useEffect } from 'react';
import { getGlassSizes } from 'domain/glass';
import { ConfigurationDataContext } from 'context/configuration';

const useGlassSize = (configurationJsonData) => {
	const {
		setTotalGlassSizes,
		setTotalGlassSizesSum,
		setIsSizesFilled,
		setErrorFields,
	} = useContext(ConfigurationDataContext);

	useEffect(() => {
		if (!configurationJsonData?.glassTypes?.length) return;
		const {
			values, valuesSum, isSizesValid, emptyFields,
		} = getGlassSizes(configurationJsonData.glassTypes);

		setTotalGlassSizes(values);
		setIsSizesFilled(isSizesValid);
		setTotalGlassSizesSum(valuesSum);
		setErrorFields(emptyFields);
	}, [configurationJsonData?.glassTypes]);
};

export default useGlassSize;
