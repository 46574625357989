import React, { useContext, useRef } from 'react';

import { ReactComponent as UserIcon } from 'i/icons/user_2.svg';
import { ReactComponent as UserAdminIcon } from 'i/icons/user_icon_admin.svg';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { getFunctions, httpsCallable } from 'firebase/functions';
import ToastifyHandler from 'utils/ToastifyHandler';
import { useTranslation } from 'react-i18next';
import { STATUS_RESPONSE, USER_ROLES } from 'utils/constant';
import { AuthContext } from 'context/auth';

const CompanyUsersBlock = ({
	role,
	email,
	currentUserEmail,
	getAllCompanyUsers,
}) => {
	const notificationsHandler = useRef(new ToastifyHandler());
	const { t } = useTranslation(['notifications', 'firebaseErrorMessages']);
	const { currentUserRole } = useContext(AuthContext);

	const handleClick = async (e) => {
		e.preventDefault();

		if (confirm(t('confirmationOfUserDeletion'))) {
			const functions = getFunctions();
			const deleteUserFromCompany = httpsCallable(functions, 'deleteUserFromCompany');

			notificationsHandler.current.pending(t('pleaseWait'));

			try {
				const deleteUserRes = await deleteUserFromCompany({
					userEmail: email,
				});

				if (deleteUserRes.data === STATUS_RESPONSE.ok) {
					notificationsHandler.current.success(t('userHasBeenSuccessfullyRemovedFromTheCompany'));
				} else {
					notificationsHandler.current.rejected(t('anErrorHasOccurred'));
				}

				getAllCompanyUsers();
			} catch (error) {
				const { code } = error;
				notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
			}
		}
	};

	return (
		<div className="company_users__block">
			<div className="company_users__block__icon">
				{role === USER_ROLES.companyAdmin ? (
					<UserAdminIcon className="icon icon-user_2 size_mod current_mod" />
				) : (
					<UserIcon className="icon icon-user_2 size_mod current_mod" />
				)}
			</div>
			<div className="company_users__block__field">{email}</div>
			{(currentUserRole === USER_ROLES.companyAdmin || currentUserRole === USER_ROLES.superAdmin) && email !== currentUserEmail ? (
				<button className="company_users__block__button" type="button" onClick={handleClick}>
					<CloseIcon className="icon icon-close size_mod" />
				</button>
			) : null}
		</div>
	);
};

export default CompanyUsersBlock;
