import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LinkIcon } from 'i/icons/link.svg';
import { USERS_API } from 'api';
import { AuthContext } from 'context/auth';
import { PROMISE_STATES } from 'utils/constant';
import { Spinner } from 'components/Spinner';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CompanyUsersBlock from './CompanyUsersBlock';

const CompanyUsers = () => {
	const { t } = useTranslation('accountPage');
	const { currentUserAdditionalData } = useContext(AuthContext);
	const [companyUsers, setCompanyUsers] = useState(null);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const [loadingErrorMessage, setLoadingErrorMessage] = useState(null);
	const companyId = useRef(null);
	const navigate = useNavigate();

	const getAllCompanyUsers = async () => {
		setLoadingStatus(PROMISE_STATES.pending);

		try {
			const companyUsersRes = await USERS_API.getUsersFromCompany(currentUserAdditionalData.companyId);

			if (companyUsersRes) {
				setCompanyUsers(companyUsersRes);

				companyId.current = companyUsersRes[0].companyId;

				setLoadingStatus(PROMISE_STATES.fulfilled);
			}
		} catch (error) {
			setLoadingStatus(PROMISE_STATES.rejected);
			setLoadingErrorMessage(error.message);
		}
	};

	const createInviteLinkToCompany = () => {
		if (companyId.current) {
			const currentHost = window.location.host;
			const inviteLink = `${currentHost}/register/${companyId.current}`;

			navigator.clipboard.writeText(inviteLink);

			toast.success(t('notifications:invitationLinkHasBeenCopied'), {
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
			});
		}
	};

	const moveToRegisterNewUserPage = () => {
		navigate(`/register/${companyId.current}`);
	};

	useEffect(() => {
		if (currentUserAdditionalData) {
			getAllCompanyUsers();
		}
	}, [currentUserAdditionalData]);

	return (
		<div className="company_users">
			<BlockHeading
				title={t('usersInCompanyTitle')}
			/>
			<div className="company_users__in">
				{companyUsers && currentUserAdditionalData ? (
					<>
						<ul className="company_users__list">
							{companyUsers.map(({
								role,
								email,
							}, index) => {
								return (
									<li className="company_users__list__item" key={index}>
										<CompanyUsersBlock
											email={email}
											role={role}
											currentUserEmail={currentUserAdditionalData.email}
											getAllCompanyUsers={getAllCompanyUsers}
										/>
									</li>
								);
							})}
						</ul>
						<div className="company_users__block_link_w">
							<button
								className="company_users__block_link"
								type="button"
								onClick={moveToRegisterNewUserPage}
							>
								{t('registerNewUser')}
							</button>
							<button
								className="company_users__block_link_icon"
								type="button"
								onClick={createInviteLinkToCompany}
							>
								<LinkIcon className="icon icon-link size_mod" />
							</button>
						</div>
					</>
				) : (
					loadingStatus === PROMISE_STATES.pending ? (
						<Spinner darkTheme />
					) : (
						<div className="company_users__error">{loadingErrorMessage}</div>
					)
				)}
			</div>
		</div>
	);
};

export default CompanyUsers;
