import RegisterNewCompanyUserForm from 'components/Form/RegisterNewCompanyUserForm';
import { PageNavHeading } from 'components/PageNavHeading';
import React from 'react';

const RegisterByInvintationPage = () => {
	return (
		<section className="section section--register_invintation">
			<div className="section__in">
				<PageNavHeading />
				<RegisterNewCompanyUserForm
					limitContainerMod
				/>
			</div>
		</section>
	);
};

export default RegisterByInvintationPage;
