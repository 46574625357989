import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { UiContext } from 'context/ui';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';
import { CUSTOM_EVENTS } from 'utils/constant';
import { emit, on } from 'utils';

const DeletePositionPopup = ({
	isOpen,
}) => {
	const { showPopupByKey } = useContext(UiContext);
	const { t } = useTranslation('deletePositionPopup');
	const [positionId, setPositionId] = useState(null);
	const $deleteButton = useRef(null);

	const closePopup = () => {
		showPopupByKey();
	};

	const onPopupEntering = () => {
		document.body.classList.add('filter_mod');
	};

	const onPopupExit = () => {
		document.body.classList.remove('filter_mod');
	};

	const listenerCustomEvents = () => {
		on(CUSTOM_EVENTS.DELETE_POSITION.name, (e, detail) => {
			setPositionId(detail.id);
		});
	};

	const handleDeletePosition = () => {
		closePopup();
		emit(CUSTOM_EVENTS.DELETE_POSITION.name, { id: positionId, action: 'delete' });
	};

	useEffect(() => {
		listenerCustomEvents();
	}, []);

	useEffect(() => {
		if (isOpen) {
			$deleteButton.current.focus();
		}
	}, [isOpen]);

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			onEntering={onPopupEntering}
			onExit={onPopupExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in popup_in--delete_position_state">
					<div className="delete_position">
						<button className="popup_close" type="button" onClick={closePopup}>
							<CloseIcon className="icon icon-close size_mod" />
						</button>
						<BlockHeading
							title={t('title')}
							iconName="question"
							offsetMod
						/>
						<div className="delete_position__text">{t('description')}</div>
						<div className="delete_position__button">
							<button
								className="btn_base"
								type="button"
								onClick={handleDeletePosition}
								ref={$deleteButton}
							>
								{t('deleteButton')}
							</button>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default DeletePositionPopup;
