export const DEFAULT_GLASS_SIZES = {
	quantity: '-',
	m2: '-',
	kg: '-',
	price: '-',
};

export const replaceZerosWithDash = (values) => {
	return Object.fromEntries(
		Object.entries(values).map(([key, value]) => [key, value === 0 ? '-' : value]),
	);
};

export const calculatePcSurface = (width, height) => {
	const multiplier = 0.001;
	const parsedWidth = parseFloat(width);
	const parsedHeight = parseFloat(height);

	return (parsedWidth * multiplier) * (parsedHeight * multiplier);
};

export const calculatePosSurface = (m2, quantity) => {
	const parsedQuantity = parseFloat(quantity);

	return m2 * parsedQuantity;
};

export const calculatePcWeight = (m2, thickness) => {
	const multiplier = 2.5;
	const parsedThickness = parseFloat(thickness);

	return m2 * parsedThickness * multiplier;
};

const findItemByName = (row, name) => row.find(item => item.name === name) || { value: null };

export const checkIsFieldError = (fields, id, name) => {
	return fields.some(item => item.id === id && item.fields.some(field => field.name === name));
};

export const calculateSubTotalSizes = (positions, flatGlassStructure) => {
	const validRows = positions.filter(({ row }) => {
		return Array.isArray(row) && row.length > 0;
	});

	const emptyFields = validRows.map(({ row, inactive, id }) => {
		const fields = row.filter(({ name, value }) => {
			return !inactive && (name === "length" || name === "width" || name === "quantity") && value === "";
		});

		return {
			id,
			fields,
		};
	});

	const values = validRows
		.map(({ row, inactive }, index) => {
			const { value: width } = findItemByName(row, "width");
			const { value: height } = findItemByName(row, "length");
			const { value: quantity } = findItemByName(row, "quantity");

			if (!inactive && width && height && quantity && quantity >= 1) {
				const m2Pc = calculatePcSurface(width, height);
				const kg = calculatePcWeight(m2Pc, flatGlassStructure.thickness);
				const m2 = calculatePosSurface(m2Pc, quantity);

				return {
					index,
					quantity: parseFloat(quantity),
					m2Pc,
					m2,
					kg,
					kgPos: kg * quantity,
					price: flatGlassStructure.sqmPrice !== null ? flatGlassStructure.sqmPrice * m2 : null,
				};
			}

			return null;
		});

	const validActiveRowLength = validRows.filter(({ inactive }) => {
		return !inactive;
	}).length;

	const sizes = values.filter(item => item !== null);

	const isFilled = validActiveRowLength === sizes.length;

	return { sizes, isFilled, emptyFields };
};

export const calculateTotalSizes = (values) => {
	return values.reduce((acc, innerValues) => {
		innerValues.forEach(({
			quantity, m2, kgPos, price,
		}) => {
			acc.quantity += quantity;
			acc.m2 += m2;
			acc.kg += kgPos;
			acc.price = acc.price !== null && price !== null ? acc.price + price : null;
		});
		return acc;
	}, {
		quantity: 0, m2: 0, kg: 0, price: 0,
	});
};

export const getGlassSizes = (glassTypes) => {
	const values = [];
	let emptyFields = [];
	let isSizesValid = true;

	glassTypes.map(({ positions, flatGlassStructure }) => {
		const { sizes, isFilled, emptyFields: fields } = calculateSubTotalSizes(positions, flatGlassStructure);

		emptyFields = emptyFields.concat(fields);
		values.push(sizes);

		isSizesValid = isSizesValid && isFilled;
		return null;
	});

	const valuesSum = replaceZerosWithDash(calculateTotalSizes(values));

	return {
		values, valuesSum, isSizesValid, emptyFields,
	};
};
