import AbstractFilter from './AbstractFilter.js'

export default class ResistanceClassFilter extends AbstractFilter {
  constructor() {
    super("ResistanceClassFilter");

    this.glassStructure = '1-glazed';
    this.application = 'Inside/Outside';

    this.i18n_prefix = "resistanceClass_";
    this.filterOptions = {
      "-": "none",
      "RC 2 (WK 2) / P4A": "rc2",
      "RC 3 (WK 3) / P5A": "rc3",
      "RC 4 (WK 4) / P6B": "rc4",
      // Removed from dataset due to low popularity
      // "RC 5 (WK 5) / P7B": "rc5",
      // "RC 6 (WK 6) / P8B": "rc6",
    };
  }

  applyFilter(dbView) {
    super.applyFilter(dbView);
    if (this.selectedFilter === null) {
      return;
    }

    if (this.glassStructure === '1-glazed' || this.application === 'Outside/Outside') {
      // if structure is mono
      for (var i = 0; i < dbView['frg'].length; ++i) {
        if (dbView['frg'][i].resistanceClass !== this.selectedFilter) {
          dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
          i -= 1;
        }
      }
    }

    // iso structure
    for (var i = 0; i < dbView['out'].length; ++i) {
      if (dbView['out'][i].resistanceClass !== this.selectedFilter) {
        dbView['outFiltered'].push(dbView['out'].splice(i, 1)[0]);
        i -= 1;
      }
    }
  }
}
