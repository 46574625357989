export const TABLET_MEDIA_POINT = 1024;

export const STRING_NAME = 'string';
export const OBJECT_NAME = 'object';
export const ARRAY_NAME = 'array';
export const REFERENCE_NAME = 'Reference';
export const BOOLEAN_NAME = 'Boolean';
export const NUMBER_NAME = 'Number';
export const MEDIA_NAME = 'media';
export const TEMPLATE_NAME = 'template';

export const PROMISE_PENDING_STATE = 'pending';
export const PROMISE_FULFILLED_STATE = 'fulfilled';
export const PROMISE_REJECTED_STATE = 'rejected';
export const PROMISE_DEFAULT_STATE = 'idle';

export const PROMISE_STATES = {
	pending: 'pending',
	fulfilled: 'fulfilled',
	rejected: 'rejected',
	default: 'idle',
};

export const FIREBASE_CONFIGURATIONS_STORAGE_PATH = 'configurations';

export const CONFIGURATION_TYPE = {
	drafts: 'drafts',
	order: 'order',
	request: 'request',
};

export const CONFIGURATION_STATUS = {
	open: 'open',
	closed: 'closed',
	accepted: 'accepted',
};

export const USER_ROLES = {
	superAdmin: 'superAdmin',
	admin: 'admin',
	companyAdmin: 'companyAdmin',
	user: 'user',
};

export const SUPPORTED_FILE_FORMATS = [
	'image/jpeg',
	'image/gif',
	'image/png',
	'application/pdf',
];

export const APP_URL = 'localhost:3000';

export const CONFIGURATIONS_COLLECTION_NAME = 'configurations';

export const STATUS_RESPONSE = {
	ok: 200,
	methodNotAllowed: 402,
};

export const CUSTOM_EVENTS = {
	DELETE_POSITION: {
		name: 'delete-position',
		id: '',
		action: '',
	},
	DELETE_ATTACHMENT: {
		name: 'delete-attachment',
		id: '',
		action: '',
	},
};

export const VIDEO_LOADING_STATUS = {
	pending: 'pending',
	loaded: 'loaded',
};

export const MENU_CLOSED_STATE = 'closed';
export const MENU_OPEN_STATE = 'opened';

export const CRISP_CHAT_WEBSITE_ID = '7d725d4a-5139-4ea8-bcd1-076bb222cbcc';
