import {
	useEffect, useState, useContext, cloneElement,
} from 'react';
import { AuthContext } from 'context/auth';
import { UiContext } from 'context/ui';

const HighlightComponent = ({ children, delay }) => {
	const { isModelLoaded } = useContext(UiContext);
	const [highlighted, setHighlighted] = useState(false);
	const { currentUser } = useContext(AuthContext);

	useEffect(() => {
		if (currentUser || !isModelLoaded) return undefined;

		// Add the 'highlight' class on component mount
		const timeoutInId = setTimeout(() => {
			setHighlighted(true);
		}, delay);

		// Remove the 'highlight' class after a delay (e.g., 1 second)
		const timeoutOutId = setTimeout(() => {
			setHighlighted(false);
		}, delay + 1000); // 1000 milliseconds (1 second)

		// Clean up the timeout on unmount (optional)
		return () => {
			clearTimeout(timeoutInId);
			clearTimeout(timeoutOutId);
		};
	}, [currentUser, isModelLoaded]);

	const modifiedComponent = cloneElement(children, {
		className: `${children.props.className || ''} ${highlighted ? 'highlight' : ''}`,
	});
	return modifiedComponent;
};

export default HighlightComponent;
