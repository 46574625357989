import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { ReactComponent as CloseIcon } from 'i/icons/close_light.svg';
import { ReactComponent as UploadIcon } from 'i/icons/upload.svg';
import { ConfigurationDataContext } from 'context/configuration';
import { getStorage, ref, deleteObject } from 'firebase/storage';
import { emit, on } from 'utils';
import { CUSTOM_EVENTS, FIREBASE_CONFIGURATIONS_STORAGE_PATH, PROMISE_STATES } from 'utils/constant';
import { UiContext } from 'context/ui';

const UploadedDocumentsList = ({
	documentsList,
}) => {
	const { configuration } = useContext(ConfigurationDataContext);
	const { showPopupByKey } = useContext(UiContext);
	const [fileDeletingStatus, setFileDeletingStatus] = useState();
	const documentsListRef = useRef(documentsList);
	const unsubscribeCustomEvent = useRef(null);

	const updateConfigurationAttachments = (attachmentId) => {
		if (!attachmentId) return;

		const transacted = documentsListRef.current.transact();

		// eslint-disable-next-line no-plusplus
		for (let index = 0; index < transacted.length; index++) {
			const attachment = transacted[index];

			if (attachment.id === attachmentId) {
				transacted.splice(index, 1);
			}
		}

		documentsList.run();
	};

	const showDeleteAttachmentPopup = (attachmentId) => {
		if (!attachmentId) return;

		showPopupByKey('deleteAttachment');

		emit(CUSTOM_EVENTS.DELETE_ATTACHMENT.name, { attachmentId });
	};

	const getAttachment = (attachmentId) => {
		let selectedAttachmentIndex = null;

		const selectedAttachment = documentsListRef.current.filter(({ id }, index) => {
			const isIdMatch = attachmentId === id;

			if (isIdMatch && selectedAttachmentIndex === null) {
				selectedAttachmentIndex = index;
			}

			return isIdMatch;
		});

		return selectedAttachment.length ? { ...selectedAttachment[0], index: selectedAttachmentIndex } : selectedAttachment;
	};

	const deleteAttachment = (attachmentId) => {
		const { name, index } = getAttachment(attachmentId);

		if (!name) return;
		if (index === undefined) return;

		const storage = getStorage();

		const { code } = configuration;

		const filePath = `${FIREBASE_CONFIGURATIONS_STORAGE_PATH}/${code}/${name}`;

		const fileRef = ref(storage, filePath);

		setFileDeletingStatus(PROMISE_STATES.pending);

		deleteObject(fileRef).then(() => {
			setFileDeletingStatus(PROMISE_STATES.fulfilled);
			updateConfigurationAttachments(attachmentId);
		}).catch((error) => {
			setFileDeletingStatus(PROMISE_STATES.rejected);
			alert(error);
		});
	};

	const listenerCustomEvents = () => {
		unsubscribeCustomEvent.current = on(CUSTOM_EVENTS.DELETE_ATTACHMENT.name, (e, {
			action,
			attachmentId,
		}) => {
			if (action !== 'delete') return;

			deleteAttachment(attachmentId);
		});
	};

	useEffect(() => {
		listenerCustomEvents();

		return () => {
			if (unsubscribeCustomEvent.current) {
				unsubscribeCustomEvent.current();
			}
		};
	}, []);

	useEffect(() => {
		documentsListRef.current = documentsList;
	}, [documentsList]);

	return (
		<ul className="uploaded_doc__list">
			{documentsList.map(({
				name,
				url,
				size,
				id,
			}) => {
				return (
					<li className="uploaded_doc__list__item" key={id}>
						<div className="uploaded_doc__block">
							<button
								className="uploaded_doc__block__close_icon"
								type="button"
								onClick={() => showDeleteAttachmentPopup(id)}
								disabled={fileDeletingStatus === PROMISE_STATES.pending}
							>
								<CloseIcon className="icon icon-close_light size_mod" />
							</button>
							<a href={url} target="_blank" download={name} className="uploaded_doc__block__download_icon" rel="noreferrer">
								<UploadIcon className="icon icon-upload size_mod" />
							</a>
							<div className="uploaded_doc__block__size">{size}</div>
							<div className="uploaded_doc__block__file_name">{name}</div>
						</div>
					</li>
				);
			})}
		</ul>
	);
};

export default UploadedDocumentsList;
