import React from 'react';

import Logo from 'i/logo.png';
import { View, Image, Text } from '@react-pdf/renderer';
import jsonAppData from 'utils/jsonAppData';
import styles from './styles';

const PdfHeader = () => {
	const { contactInformation } = jsonAppData;

	return (
		<View style={styles.header}>
			<View style={styles.headerLogo}>
				<Image src={Logo} />
			</View>
			{contactInformation && contactInformation.length ? (
				<ul style={styles.headerInfo}>
					{contactInformation.map(({
						title,
					}, index) => {
						return (
							<Text style={styles.headerInfoItem} key={index}>{title}</Text>
						);
					})}
				</ul>
			) : null}
		</View>
	);
};

export default PdfHeader;
