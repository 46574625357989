import { firebaseDB } from 'utils/firebase';
import {
	setDoc,
	getDoc,
	doc,
	query,
	collection,
	getDocs,
	where,
	updateDoc,
} from 'firebase/firestore';

const COLLECTION_NAME = 'users';

const addNewUser = async (user, userUid) => {
	if (!user) return null;

	const res = await setDoc(doc(firebaseDB, COLLECTION_NAME, userUid), user);

	return res;
};

const updateUserData = async (userUid, userData) => {
	if (!userUid) return null;
	if (!userData) return null;

	const userRef = doc(firebaseDB, COLLECTION_NAME, userUid);
	const res = await updateDoc(userRef, userData);

	return res;
};

const getUser = async id => {
	if (!id) return null;

	const snap = await getDoc(doc(firebaseDB, COLLECTION_NAME, id));

	if (snap.exists()) {
		return snap.data();
	}

	return null;
};

const getUsersFromCompany = async (companyId) => {
	if (!companyId) return null;

	const companyUsersQuery = query(collection(firebaseDB, COLLECTION_NAME), where('companyId', '==', companyId));

	const querySnapshot = await getDocs(companyUsersQuery);

	const res = [];

	querySnapshot.forEach((docSnap) => {
		res.push(docSnap.data());
	});

	return res;
};

const USERS_API = {
	addNewUser,
	updateUserData,
	getUser,
	getUsersFromCompany,
};

export default USERS_API;
