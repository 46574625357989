import React from "react";
import { useTranslation } from "react-i18next";
import { formatNumberToFixed } from 'utils';
import { View, Text } from '@react-pdf/renderer';
import PdfGlassConfigTotalCell from './PdfGlassConfigTotalCell';

import styles from '../styles';

const rowStyles = {
	...styles.glassConfigTableRow,
	...styles.glassConfigTableRowTotalMod,
};

const labelStyles = {
	...styles.glassConfigTableCellTitle,
	...styles.glassConfigTableCellTitleColor1,
};

const PdfGlassConfigTotal = ({
	quantity, m2, kg, price,
}) => {
	const { t } = useTranslation('configurationTableHeading');

	return (
		<View style={rowStyles} wrap={false}>
			<Text style={labelStyles}>{t('total')}</Text>
			<PdfGlassConfigTotalCell label={t('quantity')} value={quantity} />
			<PdfGlassConfigTotalCell
				label={(
					<View style={styles.glassConfigTableCellSupWrap}>
						<Text>m</Text>
						<Text style={styles.glassConfigTableCellSup}>2</Text>
					</View>
				)}
				value={formatNumberToFixed(m2)}
			/>
			<PdfGlassConfigTotalCell label={t('kg')} value={formatNumberToFixed(kg)} />
			{
				/* if user is logged in */
				price !== null ? (<PdfGlassConfigTotalCell label={t('price')} value={formatNumberToFixed(price)} />) : null
			}
		</View>
	);
};

export default PdfGlassConfigTotal;
