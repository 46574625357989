import React from 'react';

import { PageNavHeading } from 'components/PageNavHeading';
import { useTranslation } from 'react-i18next';
import { BlockHeading } from 'components/BlockHeading';

const OrderConfirmationSection = () => {
	const { t } = useTranslation('orderConfirmationPage');

	return (
		<section className="section order_confirmation">
			<div className="section__in">
				<PageNavHeading />
				<BlockHeading
					title={t('orderConfirmationTitle')}
					iconName="orders"
				/>
				<div className="order_confirmation__message">
					{t('formSubmittedText')}
				</div>
			</div>
		</section>
	);
};

export default OrderConfirmationSection;
