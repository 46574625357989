import React from 'react';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import 'styles/_loader.scss';

const GlassProductCanvas = () => {
	const location = useLocation();
	const isEnabled = location.pathname === '/';

	return (
		<div className={classNames("glass_product_col img_mod", {
			"glass_product_col--enabled_mod": isEnabled,
		})}
		>
			<div className="loader">
				<div className="loader__progress-bar">
					<div className="loader__progress-bar-fill" />
				</div>
			</div>
			<canvas className="glass_product_img home__canvas webgl-canvas" />
		</div>
	);
};

export default GlassProductCanvas;
