import { CONFIGURATIONS_API } from 'api';
import { ConfigurationDataContext } from 'context/configuration';
import React, { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { cloneObjectWithoutReference, generateUniqueId, getCurrentDate } from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfConfigurationsDocument } from 'components/PdfComponents';
import { CONFIGURATION_STATUS, CONFIGURATION_TYPE, STATUS_RESPONSE } from 'utils/constant';

const ConfigurationBlockDraftsOptions = ({
	configurationCode,
	configuration,
	dropdownOptions,
}) => {
	const { t } = useTranslation(['commonConfigurationAction', 'firebaseErrorMessages']);
	const notificationsHandler = useRef(new ToastifyHandler());
	const { setConfiguration } = useContext(ConfigurationDataContext);
	const navigate = useNavigate();

	const deleteConfiguration = async () => {
		notificationsHandler.current.pending(t('notifications:configurationDeletionInProgress'));

		if (configurationCode === configuration.code) {
			setConfiguration(null);
		}

		try {
			await CONFIGURATIONS_API.deleteConfiguration(configurationCode);

			notificationsHandler.current.success(t('notifications:configurationWasDeletedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const editConfiguration = () => {
		if (!configuration) return;

		setConfiguration(configuration);
		navigate('/cart');
	};

	const changeConfigurationType = async (configurationType) => {
		if (!configurationType) return;

		notificationsHandler.current.pending(t('notifications:configurationTypeChangeInProgress'));

		try {
			const configurationCopy = cloneObjectWithoutReference(configuration);

			configurationCopy.type = configurationType;

			await CONFIGURATIONS_API.addNewConfiguration(configurationCopy);

			notificationsHandler.current.success(t('notifications:configTypeChangedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const createNewConfigurationBasedOnExisting = (newConfigurationCode) => {
		const duplicatedConfiguration = cloneObjectWithoutReference(configuration);
		const currentDate = getCurrentDate();
		const dateNow = Date.now();

		duplicatedConfiguration.code = newConfigurationCode;
		duplicatedConfiguration.created = currentDate;
		duplicatedConfiguration.lastEdited = currentDate;
		duplicatedConfiguration.publishedAt = dateNow;
		duplicatedConfiguration.lastEditedDateInMilliseconds = dateNow;
		duplicatedConfiguration.type = CONFIGURATION_TYPE.drafts;
		duplicatedConfiguration.status = CONFIGURATION_STATUS.open;

		return duplicatedConfiguration;
	};

	const duplicateConfiguration = async () => {
		notificationsHandler.current.pending(t('notifications:duplicatingConfigWithAllFilesInProgress'));

		try {
			const functions = getFunctions();
			const copyStorageFilesFromOneBucketToAnother = httpsCallable(functions, 'copyStorageFilesFromOneBucketToAnother');

			const newConfigurationCode = generateUniqueId();
			const duplicatedConfiguration = createNewConfigurationBasedOnExisting(newConfigurationCode);

			if (duplicatedConfiguration.uploadedDocuments && duplicatedConfiguration.uploadedDocuments.length) {
				const copyFilesRes = await copyStorageFilesFromOneBucketToAnother({
					companyId: configuration.companyId,
					srcConfigurationCode: configurationCode,
					destConfigurationCode: newConfigurationCode,
				});

				if (copyFilesRes.data.status && copyFilesRes.data.status === STATUS_RESPONSE.ok) {
					duplicatedConfiguration.uploadedDocuments.forEach((document, index) => {
						duplicatedConfiguration.uploadedDocuments[index].url = document.url.replace(configurationCode, newConfigurationCode);
					});
				}
			}

			await CONFIGURATIONS_API.addNewConfiguration(duplicatedConfiguration);

			notificationsHandler.current.success(t('notifications:configurationDuplicationCompletedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const changeConfigurationStatus = async (configurationStatus) => {
		if (!configurationStatus) return;

		notificationsHandler.current.pending(t('notifications:configurationStatusChangeInProgress'));

		try {
			const configurationCopy = cloneObjectWithoutReference(configuration);

			configurationCopy.status = configurationStatus;

			await CONFIGURATIONS_API.addNewConfiguration(configurationCopy);

			notificationsHandler.current.success(t('notifications:configurationStatusChangedSuccessfully'));
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
		}
	};

	const handleClick = (action) => {
		if (action === 'delete') {
			if (confirm(t('notifications:confirmationOfConfigurationDeletion'))) {
				deleteConfiguration();
			}

			return;
		}

		if (action === 'edit') {
			editConfiguration();

			return;
		}

		if (action === 'order' || action === 'request') {
			if (confirm(t('notifications:confirmationOfChangeTypeConfiguration'))) {
				changeConfigurationType(action);
			}

			return;
		}

		if (action === 'complete') {
			changeConfigurationStatus(CONFIGURATION_STATUS.closed);

			return;
		}

		if (action === 'reopen') {
			changeConfigurationStatus(CONFIGURATION_STATUS.open);

			return;
		}

		if (action === 'duplicate' || action === 'duplicateAsDraft') {
			duplicateConfiguration();
		}
	};

	const currentDate = getCurrentDate();
	return (
		dropdownOptions ? (
			<ul className="configuration_block_options">
				{dropdownOptions.map(({
					label,
				}, index) => {
					return (
						<li className="configuration_block_options__item" key={index}>
							{label === 'pdf' && configuration ? (
								<PDFDownloadLink
									document={<PdfConfigurationsDocument configuration={configuration} />}
									fileName={t("commonConfigurationAction:configurationDotPdfFmt").replace('{date}', currentDate)}
									className="configuration_block_options__title"
								>
									{() => {
										return (
											t(label)
										);
									}}
								</PDFDownloadLink>
							) : (
								<button
									className="configuration_block_options__title"
									type="button"
									onClick={() => handleClick(label)}
								>
									{t(label)}
								</button>
							)}
						</li>
					);
				})}
			</ul>
		) : null
	);
};

export default ConfigurationBlockDraftsOptions;
