import { Mesh } from 'three';
import { BoxGeometry } from 'three';

import GenericStructureElement from './GenericStructureElement';
import AluminiumTapeMaterial from './../materials/AluminiumTapeMaterial';


export default class Tape extends GenericStructureElement {

  constructor() {
    super();

    this.depth = 0.002;

    const length = 2, height = 2, thickness = 0.15, thickness_short = 0.03;

    const geometry_y = new BoxGeometry(height - thickness, thickness, this.depth);
    geometry_y.rotateZ(0.5 * Math.PI);
    geometry_y.translate(thickness * 0.5, (height + thickness) * 0.5, this.depth * 0.5);

    const geometry_x = new BoxGeometry(length, thickness, this.depth);
    geometry_x.translate(length * 0.5, thickness * 0.5, this.depth * 0.5);

    //short geo
    const geometry_y_short = new BoxGeometry(height - thickness_short, thickness_short, this.depth);
    geometry_y_short.rotateZ(0.5 * Math.PI);
    geometry_y_short.translate(thickness_short * 0.5, (height + thickness_short) * 0.5, this.depth * 0.5);

    const geometry_x_short = new BoxGeometry(length, thickness_short, this.depth);
    geometry_x_short.translate(length * 0.5, thickness_short * 0.5, this.depth * 0.5);

    const alu_material = new AluminiumTapeMaterial();
    this.alu_material = alu_material;

    this.cube_y = new Mesh(geometry_y, alu_material);
    this.cube_y.receiveShadow = true;
    this.cube_y.castShadow = true;
    this.cube_y.renderOrder = 1001;
    this.add(this.cube_y);

    this.cube_x = new Mesh(geometry_x, alu_material);
    this.cube_x.receiveShadow = true;
    this.cube_x.castShadow = true;
    this.cube_x.renderOrder = 1001;
    this.add(this.cube_x);

    // short alu tape
    this.cube_y_short = new Mesh(geometry_y_short, alu_material);
    this.cube_y_short.receiveShadow = true;
    this.cube_y_short.castShadow = true;
    this.cube_y_short.renderOrder = 1001;
    this.add(this.cube_y_short);

    this.cube_x_short = new Mesh(geometry_x_short, alu_material);
    this.cube_x_short.receiveShadow = true;
    this.cube_x_short.castShadow = true;
    this.cube_x_short.renderOrder = 1001;
    this.add(this.cube_x_short);

    //this.set_short(false);

  }

  set_short(aBoolean) {
    if (aBoolean) {
      this.cube_y_short.visible = true;
      this.cube_x_short.visible = true;
      this.cube_y.visible = false;
      this.cube_x.visible = false;
    }
    else {
      this.cube_y_short.visible = false;
      this.cube_x_short.visible = false;
      this.cube_y.visible = true;
      this.cube_x.visible = true;
    }

  }

  update() {
    this.alu_material.update();
  }

  get width() {
    return this.depth;
  }
}


