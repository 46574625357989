/* eslint-disable consistent-return */
/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
import i18n from "i18next";
import { SUPPORTED_FILE_FORMATS } from "./constant";

export const getCurrentDate = () => {
  const date = new Date();

  return date.toLocaleDateString("de-CH");
};

export const cloneObjectWithoutReference = (object) => {
  return JSON.parse(JSON.stringify(object));
};

export const generateUniqueId = (length = 20) => {
  const randomInt = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const getRandomChar = (string) => {
    return string[randomInt(0, string.length - 1)];
  };

  const generateId = () => {
    const chars = "abcdefghyjklmnopqrstuvwxyz0123456789";
    let res = "";

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < length; i++) {
      res +=
        randomInt(1, 2) === 1
          ? getRandomChar(chars).toUpperCase()
          : getRandomChar(chars);
    }

    return res;
  };

  const uniqueId = generateId();

  return uniqueId;
};

export const fieldValidationEmptyValue = (fieldValue) => {
  if (fieldValue.trim() === "") {
    return false;
  }

  return true;
};

export const guessType = (value) => {
  const type = typeof value;

  if (type !== "object") return type;

  if (value instanceof Array) return "array";

  if (value instanceof Date) return "date";

  return "object";
};

export const formatNumberToFixed = (number, toFixed = 2) => {
	const parsedNumber = parseFloat(number);
	return !isNaN(parsedNumber) ? parsedNumber.toFixed(toFixed) : number;
};

export const typeDefaultValues = {
  string: "",
  object: {},
  array: [],
};

export const checkFileType = (fileType) => {
  if (!fileType) return false;

  if (SUPPORTED_FILE_FORMATS.includes(fileType)) {
    return true;
  }

  return false;
};

export const formatFileSize = (bytes) => {
  if (!bytes) return false;

  const sufixes = ["B", "kB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(1024));

  return `${(bytes / 1024 ** i).toFixed(2)} ${sufixes[i]}`;
};

export const emailValidation = (email) => {
  if (
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      email
    )
  ) {
    return null;
  }
  if (email.trim() === "") {
    return i18n.t("formNotifications:emailRequired");
  }
  return i18n.t("formNotifications:enterValidEmail");
};

export const nameValidation = (fieldName, fieldValue) => {
  if (fieldValue.trim() === "") {
    return `${fieldName} ${i18n.t("formNotifications:fieldIsRequired")}`;
  }
  if (/[^a-zA-Z -]/.test(fieldValue)) {
    return i18n.t("formNotifications:nameFieldInvalidCharacters");
  }
  if (fieldValue.trim().length < 3) {
    return `${fieldName} ${i18n.t(
      "formNotifications:nameFieldCharactersLength"
    )}`;
  }
  return null;
};

export const validatePassword = (fieldName, values) => {
  let error = "";
  const passwordRegex = /(?=.*[0-9])/;
  if (!values) {
    error = `${fieldName} ${i18n.t("formNotifications:fieldIsRequired")}`;
  } else if (values.length < 8) {
    error = i18n.t("formNotifications:passwordFieldCharactersLong");
  } else if (!passwordRegex.test(values)) {
    error = i18n.t("formNotifications:passwordFieldMustContainNumber");
  }
  return error;
};

export const validateConfirmPassword = (pass, value) => {
  if (pass && value !== undefined) {
    if (pass !== value) {
      return i18n.t("formNotifications:passwordNotMatched");
    }
  }
  return null;
};

export const fieldValidation = (fieldName, fieldValue) => {
  if (fieldValue !== undefined && fieldValue.trim() === "") {
    return `${fieldName} ${i18n.t("formNotifications:fieldIsRequired")}`;
  }

  return null;
};

export const convertFileToBase64 = async (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    let binaryImage;

    reader.onload = (frEvent) => {
      binaryImage = frEvent.target.result;
      resolve(binaryImage);
    };

    reader.readAsDataURL(file);
  });
};

export const getParameterByName = (name) => {
  const copiedName = name;
  copiedName.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
  let regexS = `[\\?&]${copiedName}=([^&#]*)`;
  let regex = new RegExp(regexS);
  let results = regex.exec(window.location.href);
  if (results == null) {
    return "";
  } else {
    return decodeURIComponent(results[1].replace(/\+/g, " "));
  }
};

export const clearAllBrowserUrlParameter = () => {
  window.history.pushState(null, null, window.location.pathname);
};

export const sortReferenceByAlphaNumeric = (arrayData) => {
  if (!(arrayData && Array.isArray(arrayData))) return null;

  const clonedArrayData = cloneObjectWithoutReference(arrayData);

  const reA = /[^a-zA-Z]/g;
  const reN = /[^0-9]/g;

  const compareFunction = (a, b) => {
    const aReference = a.reference;
    const bReference = b.reference;

    let aA = aReference.replace(reA, "");
    let bA = bReference.replace(reA, "");

    if (aReference.trim() === "") {
      return 1;
    } else if (bReference.trim() === "") {
      return -1;
    } else if (aA === bA) {
      let aN = parseInt(aReference.replace(reN, ""), 10);
      let bN = parseInt(bReference.replace(reN, ""), 10);
      return aN === bN ? 0 : aN > bN ? 1 : -1;
    } else {
      return aA > bA ? 1 : -1;
    }

    // if (aA === bA) {
    // 	let aN = parseInt(aReference.replace(reN, ''), 10);
    // 	let bN = parseInt(bReference.replace(reN, ''), 10);
    // 	return aN === bN ? 0 : aN > bN ? 1 : -1;
    // } else if (aReference.trim() === '') {
    // 	return 1;
    // } else if (bReference.trim() === '') {
    // 	return -1;
    // } else {
    // 	return aA > bA ? 1 : -1;
    // }
  };

  clonedArrayData.sort(compareFunction);

  return clonedArrayData;
};

export const chunkArray = (myArray, chunkSize) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += chunkSize) {
    let myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const on = (event, cb, element) => {
  const target = element || window;

  if (typeof window.CustomEvent === 'undefined') {
    console.error('CustomEvent is not supported in this environment.');
    return;
  }

  const handler = (e) => {
    cb(e, e.detail);
  };

  target.addEventListener(event, handler);

  return () => target.removeEventListener(event, handler);
};

export const emit = (event, payload, element) => {
  const target = element || window;
  const createdEvent = new CustomEvent(event, { detail: payload });
  return target.dispatchEvent(createdEvent);
};

export const setItemToLocalStorage = (keyName, keyValue) => {
	window.localStorage.setItem(keyName, keyValue);
};

export const getItemFromLocalStorage = keyName => {
	return window.localStorage.getItem(keyName);
};

export const filterByCategoryId = (categoryArray, id) => {
	if (!categoryArray && !categoryArray.length) return false;

	const res = categoryArray.filter(({
		categories,
	}) => {
		const isIdMatch = categories.includes(id);

		return isIdMatch;
	});

	return res;
};

export const searchFieldValidation = fieldValue => {
	if (fieldValue.trim() === '') {
		return false;
	}

	return true;
};

export const getPageById = (allPages, pageId) => {
	const resultPage = allPages.filter(({ id }) => {
		if (id === pageId) return true;

		return false;
	});

	return resultPage;
};

export const detectSafariBrowser = () => {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const hideCrispChat = () => {
	if (window.$crisp) {
		window.$crisp.push(['do', 'chat:hide']);
	}
};

export const showCrispChat = () => {
	if (window.$crisp) {
		window.$crisp.push(['do', 'chat:show']);
	}
};
