/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React, { useContext, useRef, useState, useEffect } from "react";

import { PageNavHeading } from "components/PageNavHeading";
import { SaveAsSection } from "components/SaveAsSection";
import { ConfigurationBlock } from "components/ConfigurationBlock";
import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import { TermsBlock } from "components/TermsBlock";
import { AuthContext } from "context/auth";
import { ConfigurationDataContext } from "context/configuration";
import emailjs from "emailjs-com";
import { CONFIGURATION_TYPE, PROMISE_STATES } from "utils/constant";
import { PdfConfigurationsDocument } from "components/PdfComponents";
import { COMPANIES_API, CONFIGURATIONS_API } from "api";
import { pdf } from "@react-pdf/renderer";
import { cloneObjectWithoutReference, convertFileToBase64 } from "utils/index";
import ToastifyHandler from "utils/ToastifyHandler";
import { OrderConfirmationSection } from "components/OrderConfirmationSection";
import { useNavigate } from "react-router-dom";

const SummaryPage = () => {
  const { t } = useTranslation(['requestOrOrderPage', 'firebaseErrorMessages']);
  const { guestUser, currentUser, currentUserAdditionalData, setGuestUser } =
    useContext(AuthContext);
  const { configuration, setConfiguration } = useContext(
    ConfigurationDataContext
  );
  const [sendingEmailStatus, setSendingEmailStatus] = useState(
    PROMISE_STATES.default
  );
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const [companyInfo, setCompanyInfo] = useState(null);
  const navigate = useNavigate();
  const notificationsHandler = useRef(new ToastifyHandler());

  const createClientEmailData = (configurationPdfFileInBase64, orderType) => {
    if (currentUser) {
      const { name, email } = currentUserAdditionalData;
      return {
        to_name: name,
        user_email: email,
        content: configurationPdfFileInBase64,
        order_type: orderType,
      };
    }

    if (guestUser) {
      const { userFirstName, email } = guestUser;

      return {
        to_name: userFirstName,
        user_email: email,
        content: configurationPdfFileInBase64,
        order_type: orderType,
      };
    }

    return null;
  };

  const createAdminEmailData = (configurationPdfFileInBase64, orderType) => {
    if (currentUser) {
      const { name: companyName, email, tel, zip, city, address } = companyInfo;

      return {
        client_company_name: companyName,
        client_email: email,
        client_company_street: address,
        client_company_zip: zip,
        client_company_city: city,
        client_company_phone: tel,
        order_type: orderType,
        content: configurationPdfFileInBase64,
      };
    }

    if (guestUser) {
      const {
        companyName,
        userFirstName,
        userLastName,
        email,
        companyStreet,
        companyZIP,
        companyCity,
        companyPhone,
      } = guestUser;

      return {
        client_company_name: companyName,
        client_email: email,
        client_first_name: userFirstName,
        client_last_name: userLastName,
        client_company_street: companyStreet,
        client_company_zip: companyZIP,
        client_company_city: companyCity,
        client_company_phone: companyPhone,
        order_type: orderType,
        content: configurationPdfFileInBase64,
      };
    }

    return null;
  };

  const sendEmail = async (
    updatedConfiguration,
    configurationPdfFileInBase64,
    orderType
  ) => {
    if (!configurationPdfFileInBase64) return;
    if (!updatedConfiguration) return;

    notificationsHandler.current.pending(
      t("notifications:emailSendingInProgress")
    );
    setSendingEmailStatus(PROMISE_STATES.pending);

    const clientEmail = createClientEmailData(
      configurationPdfFileInBase64,
      orderType
    );
    const adminEmail = createAdminEmailData(
      configurationPdfFileInBase64,
      orderType
    );

    try {
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_ADMIN_TEMPLATE_ID,
        adminEmail,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_USER_TEMPLATE_ID,
        clientEmail,
        process.env.REACT_APP_EMAILJS_USER_ID
      );

      await CONFIGURATIONS_API.addNewConfiguration(updatedConfiguration);

      notificationsHandler.current.success(
        t("notifications:emailSendedSuccessfully")
      );
      setSendingEmailStatus(PROMISE_STATES.success);

      setTimeout(() => {
        setConfiguration(null);

        if (guestUser) {
          setGuestUser(null);
        }

        navigate("/");
      }, 2000);
    } catch (error) {
      const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
      setSendingEmailStatus(PROMISE_STATES.rejected);
    }
  };

  const handleSubmit = async (orderType) => {
    const clonedConfiguration = cloneObjectWithoutReference(configuration);

    const preparedOrderType = orderType || CONFIGURATION_TYPE.request;

    clonedConfiguration.type = preparedOrderType;

    const getConfigurationPdfFile = async () => {
      const blob = await pdf(
        <PdfConfigurationsDocument configuration={configuration} />
      ).toBlob();
      const file = new File([blob], "ConfigurationPDF.pdf");

      return file;
    };

    try {
      const configurationPdfFile = await getConfigurationPdfFile();
      const configurationPdfFileInBase64 = await convertFileToBase64(
        configurationPdfFile
      );

      sendEmail(
        clonedConfiguration,
        configurationPdfFileInBase64,
        preparedOrderType
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getCompanyInfo = async () => {
    try {
      const res = await COMPANIES_API.getCompany(
        currentUserAdditionalData.companyId
      );

      if (res) {
        setCompanyInfo(res);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (currentUser && currentUserAdditionalData) {
      getCompanyInfo();
    }
  }, [currentUser, currentUserAdditionalData]);

  return configuration && (guestUser || currentUser) ? (
    <>
      {sendingEmailStatus === PROMISE_STATES.success ? (
        <OrderConfirmationSection />
      ) : (
        <section className="section request_or_order_page">
          <div className="section__in">
            <PageNavHeading />
            <BlockHeading
              title={t("commonAppValues:configuration")}
              iconName="config_code"
            />
            <ConfigurationBlock
              reference={configuration.reference}
              buildingReference={configuration.buildingReference}
              amountGlassTypes={configuration.amountGlassTypes}
              created={configuration.created}
              lastEdited={configuration.lastEdited}
              code={configuration.code}
              isOrderPage
            />
            <TermsBlock setIsTermsChecked={setIsTermsChecked} />
            <div className="request_or_order_page__buttons">
              <button
                className="btn_v3"
                type="button"
                onClick={() => handleSubmit("request")}
                disabled={
                  sendingEmailStatus === PROMISE_STATES.pending ||
                  !isTermsChecked
                }
              >
                {t("request")}
              </button>
              <button
                className="btn_v3"
                type="button"
                disabled={
                  sendingEmailStatus === PROMISE_STATES.pending ||
                  !isTermsChecked
                }
                onClick={() => handleSubmit("order")}
              >
                {t("order")}
              </button>
            </div>
          </div>
        </section>
      )}
      <SaveAsSection />
    </>
  ) : (
    <section className="section">
      <h1 className="section__title section__title--center_hr_state">
        {t("cartPage:cartIsEmpty")}
      </h1>
    </section>
  );
};

export default SummaryPage;
