import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import DINNextLTProRegular from 'fonts/DINNextLTPro-Regular.ttf';
import DINNextLTProBold from 'fonts/DINNextLTPro-Bold.ttf';

import {
	View,
	Document,
	Page,
	StyleSheet,
	Font,
	Text,
} from '@react-pdf/renderer';
import { PdfGlassConfig } from 'components/PdfComponents/PdfGlassConfig';
import { getCurrentDate } from 'utils';
import { PdfHeader } from '../PdfHeader';

const styles = StyleSheet.create({
	PDFContainer: {
		backgroundColor: '#F7F7F7',
	},
	section: {
		position: 'relative',
		width: '100%',
		height: '100%',
		paddingHorizontal: 35,
		paddingVertical: 23,
		fontSize: 8,
		fontFamily: 'DINNextLTPro',
	},
	sectionBlock: {
		marginBottom: 30,
	},
	sectionBlockFooter: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		textAlign: 'center',
		minHeight: 50,
		width: '100%',
		paddingVertical: 18,
		paddingHorizontal: 23,
	},
	sectionBlockFooterText: {
		fontSize: 8,
		textTransform: 'uppercase',
		textAlign: 'center',
		color: '#456d86',
	},
});

Font.register({
	family: 'DINNextLTPro',
	fonts: [
		{ src: DINNextLTProRegular },
		{ src: DINNextLTProBold, fontWeight: 700 },
	],
});

const PdfGlassDocument = ({
	glassData,
	totalValue,
}) => {
	const { t } = useTranslation('pdfPage');
	const pdfCreationDate = useRef(getCurrentDate());

	return (
		<Document style={styles.PDFContainer}>
			<Page style={styles.PDFContainer} size="A3">
				<View style={styles.section}>
					<View style={styles.sectionBlock}>
						<PdfHeader />
					</View>
					{glassData ? (
						<View style={styles.sectionBlock}>
							<PdfGlassConfig
								totalValues={totalValue}
								glassData={glassData}
								isGlassDocument
							/>
						</View>
					) : null}
					<View style={styles.sectionBlockFooter}>
						<Text style={styles.sectionBlockFooterText}>{`${pdfCreationDate.current} - ${t('pageFooterFmt').replace('{pageNum}', '1').replace('{numPages}', '1')}`}</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default PdfGlassDocument;
