import React from 'react';

import { View } from '@react-pdf/renderer';
import PdfGlassConfigDetailItem from './PdfGlassConfigDetailItem';

import styles from '../styles';

const PdfGlassConfigDetailSecond = ({
	configDetails,
}) => {
	return (
		<View style={{ ...styles.glassConfigDetails, ...styles.glassConfigDetailsIndentState }}>
			<PdfGlassConfigDetailItem
				categoryLabel="fireResistanceClass"
				selected={configDetails.fireResistanceClass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="manufacturer"
				selected={configDetails.manufacturer}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="whiteGlass"
				selected={configDetails.whiteGlass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="application"
				selected={configDetails.application}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="structure"
				selected={configDetails.structure}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="spacer2"
				selected={configDetails.spacer2}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="insulation"
				selected={configDetails.insulation}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="sound"
				selected={configDetails.sound}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="resistanceClass"
				selected={configDetails.resistanceClass}
				secondLayoutOption
			/>
			<PdfGlassConfigDetailItem
				categoryLabel="buttJoints"
				selected={configDetails.buttJoints}
				secondLayoutOption
			/>
		</View>
	);
};

export default PdfGlassConfigDetailSecond;
