import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";

import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "i/icons/close.svg";
import { BlockHeading } from "components/BlockHeading";
import {
	confirmFilterChanges,
	abortFilterChanges,
} from "components/ThreeJs/assets/main";

import { UiContext } from 'context/ui';
import { clearAllBrowserUrlParameter } from 'utils';
import { AuthContext } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import HideStructureChangeCheckbox from 'components/Form/HideStructureChangeCheckbox';

const ConfirmFilterChangesPopup = ({ isOpen }) => {
	const { t } = useTranslation("confirmFilterChangesForm");
	const { showPopupByKey, popupData } = useContext(UiContext);
	const navigate = useNavigate();

	const closePopup = () => {
		showPopupByKey();
	};

	const onPopupEntering = () => {
		document.body.classList.add("filter_mod");
	};

	const onPopupExit = () => {
		document.body.classList.remove("filter_mod");

		clearAllBrowserUrlParameter();
	};

	const confirmChanges = () => {
		confirmFilterChanges();

		navigate("/");
		closePopup();
	};

	const abortChanges = () => {
		abortFilterChanges();

		navigate("/");
		closePopup();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			onEntering={onPopupEntering}
			onExit={onPopupExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in confirm_filter_change_popup">
					<div className="popup_content">
						<button className="popup_close" type="button" onClick={closePopup}>
							<CloseIcon className="icon icon-close size_mod" />
						</button>
						{popupData.popupType === "StructureChanged" && (
							<>
								<BlockHeading
									title={t("titleStructureChanged")}
									iconName="pensil"
								/>
								<div className="popup_note">
									{t("textStructureChanged")
										.replace("{oldStruct}", popupData.oldStruct)
										.replace("{newStruct}", popupData.newStruct)}
								</div>
							</>
						)}
						{popupData.popupType === "FiltersReset" && (
							<>
								<BlockHeading
									title={t("titleFiltersReset")}
									iconName="pensil"
								/>
								<div className="popup_note">
									{t("textFiltersReset").replace(
										"{filters}",
										popupData.filters.join(", "),
									)}
								</div>
							</>
						)}
						<div className="confirm_changes">
							<div className="confirm_btn">
								<button
									type="button"
									className="btn_base"
									onClick={confirmChanges}
								>
									{t("yesChange")}
								</button>
							</div>
							<div className="abort_btn">
								<button
									type="button"
									className="btn_base"
									onClick={abortChanges}
								>
									{t("noAbort")}
								</button>
							</div>
							<HideStructureChangeCheckbox title={t('hideMessage')} />
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default ConfirmFilterChangesPopup;
