import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';
import { MENU_CLOSED_STATE, TABLET_MEDIA_POINT } from 'utils/constant';

import UiContext from './UiContext';

const UiState = ({ children }) => {
	const [windowHeight, setWindowHeight] = useState(null);
	const [windowWidth, setWindowWidth] = useState(null);
	const [isMainMenuOpen, setMainMenuOpen] = useState(false);
	const [mainMenuState, setMainMenuState] = useState(MENU_CLOSED_STATE);
	const [isMainMenuAnimationActive, setMainMenuAnimationActive] = useState(false);
	const isDesktopScreen = useRef(false);
	const isMobileScreen = useRef(false);
	const [appPopups, setAppPopups] = useState({
		registration: {
			isOpen: false,
		},
		login: {
			isOpen: false,
		},
		configurationCode: {
			isOpen: false,
		},
		lostPassword: {
			isOpen: false,
		},
		resetPassword: {
			isOpen: false,
		},
		deletePosition: {
			isOpen: false,
		},
		resetConfiguration: {
			isOpen: false,
		},
		resetGlass: {
			isOpen: false,
		},
		confirmFilterChanges: {
			isOpen: false,
		},
		deleteAttachment: {
			isOpen: false,
		},
		faq: {
			isOpen: false,
		},
	});
	const [isModelLoaded, setIsModelLoaded] = useState(false);

	const [actionBannerAnimTl] = useState(gsap.timeline({
		paused: true,
	}));
	const [signUpBannerAnimTl] = useState(gsap.timeline({
		paused: true,
	}));
	const [faqBlockAnimTl] = useState(gsap.timeline({
		paused: true,
	}));

	const showPopupByKey = popupKey => {
		const updatedAppPopups = { ...appPopups };

		Object.keys(updatedAppPopups).forEach(key => {
			const keyObj = updatedAppPopups[key];

			if (popupKey && key.toLowerCase() === popupKey.toLowerCase()) {
				keyObj.isOpen = !keyObj.isOpen;
			} else {
				keyObj.isOpen = false;
			}
		});

		setAppPopups(updatedAppPopups);
	};

	const [popupData, setPopupData] = useState({});

	const onResize = () => {
		const { innerHeight, innerWidth } = window;
		// eslint-disable-next-line no-mixed-operators
		const calcRemValue = innerWidth * 0.5625 > innerHeight ? innerHeight / 1080 * 10 : innerWidth / 1920 * 10;

		setWindowHeight(innerHeight);
		setWindowWidth(innerWidth);

		document.documentElement.style.setProperty('--rem', `${calcRemValue}px`);

		if (innerWidth < TABLET_MEDIA_POINT) {
			if (isDesktopScreen.current && !isMobileScreen.current) {
				window.location.reload();
				isDesktopScreen.current = false;
				isMobileScreen.current = true;
			}
		} else {
			if (!isDesktopScreen.current && isMobileScreen.current) {
				window.location.reload();
				isDesktopScreen.current = true;
				isMobileScreen.current = false;
			}
		}
	};

	useEffect(() => {
		onResize();
		window.addEventListener('resize', onResize);
	}, []);

	return (
		<UiContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				appPopups,
				setAppPopups,
				showPopupByKey,
				popupData,
				setPopupData,
				isModelLoaded,
				setIsModelLoaded,
				faqBlockAnimTl,
				actionBannerAnimTl,
				signUpBannerAnimTl,
				isMainMenuOpen,
				setMainMenuOpen,
				mainMenuState,
				setMainMenuState,
				isMainMenuAnimationActive,
				setMainMenuAnimationActive,
				windowWidth,
				windowHeight,
			}}
		>
			{children}
		</UiContext.Provider>
	);
};

export default UiState;
