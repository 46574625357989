import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import { FormCheckbox } from 'components/Form';
import classNames from 'classnames';
import { ConfigurationDataContext } from 'context/configuration';
import { checkIsFieldError } from 'domain/glass';
import GlassConfigTableInput from '../GlassConfigTableInput';
import { POSITION_INCREASE } from './constant';

const GlassConfigTableRowCell = ({
	cellData,
	cellIndex,
	lastPositionIndex,
	rowIndex,
	positionAction,
	formElementIdRef,
	isInactive,
	handleRemoveInactive,
	handleInputBlur,
	isFocus,
	handleRemoveFocus,
	rowId,
}) => {
	const { errorFields, showErrors } = useContext(ConfigurationDataContext);

	const { name, value } = cellData;
	const [isError, setIsError] = useState(false);
	const [isInputOpened, setInputOpened] = useState(false);
	const [inputValueState, setInputValueState] = useState(value);
	const inputRef = useRef(null);

	const glassConfigTableCellClasses = classNames('glass_config_table__cell');

	const hideInput = () => {
		setInputOpened(false);
		inputRef.current.blur();
	};

	const showInput = () => {
		setInputOpened(true);
	};

	const setInputValue = e => {
		const { value: inputValue } = e.target;

		if (inputValue !== value && cellData) {
			let trans = cellData.transact();

			trans.value = inputValue;

			cellData.run();
		}

		hideInput();
	};

	const handleInputKeyDown = e => {
		if (e.which === 13) {
			setInputValue(e);
		}
	};

	const onInputChangeHandler = e => {
		const { value: inputValue } = e.target;

		setInputValueState(inputValue.replace(/\D/g, ''));
	};

	const onBlurHandler = e => {
		if (handleInputBlur && e?.target?.value !== "") {
			handleInputBlur();
		}

		if (isError && e?.target?.value !== "") {
			setIsError(false);
		}

		setInputValue(e);
	};

	const onChangeCheckboxHandler = e => {
		const { checked } = e.target;

		const preparedValue = checked ? 'checked' : 'unchecked';

		if (preparedValue !== value) {
			cellData.set('value', preparedValue);
		}
	};

	const onFocusHandler = () => {
		showInput();

		if (isInactive && handleRemoveInactive) {
			handleRemoveInactive();
		}
	};

	useEffect(() => {
		if (isFocus && handleRemoveFocus && cellIndex === 0) {
			showInput();
			handleRemoveFocus();
		}
	}, [isFocus]);

	useEffect(() => {
		if (isInputOpened && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isInputOpened]);

	useEffect(() => {
		if (lastPositionIndex !== null
			&& lastPositionIndex >= 0
			&& lastPositionIndex === rowIndex
			&& cellIndex === 0
			&& positionAction === POSITION_INCREASE
			&& !isInactive) {
			showInput();
		}
	}, [lastPositionIndex, rowIndex, positionAction]);

	useEffect(() => {
		if (errorFields && rowId && name) {
			setIsError(checkIsFieldError(errorFields, rowId, name) && showErrors);
		}
	}, [errorFields, rowId, name, showErrors]);

	return (
		<div className={glassConfigTableCellClasses}>
			{name === 'form' ? (
				<FormCheckbox
					id={formElementIdRef.current}
					name={formElementIdRef.current}
					onChange={onChangeCheckboxHandler}
					defaultChecked={value === 'checked'}
					noLabel
				/>
			) : (
				<div className="glass_config_table__cell_value">
					{!isInputOpened ? (
						<button
							className={classNames("glass_config_table__cell_value_in", {
								"glass_config_table__cell_value_in--invalid_state": isError,
							})}
							onClick={showInput}
							onFocus={onFocusHandler}
							type="button"
						>
							{value === '' ? '-' : value}
						</button>
					) : (
						<GlassConfigTableInput
							ref={inputRef}
							id={formElementIdRef.current}
							name={formElementIdRef.current}
							value={inputValueState}
							onBlur={onBlurHandler}
							onChange={onInputChangeHandler}
							onKeyDown={handleInputKeyDown}
							type="number"
							min="1"
						/>
					)}
				</div>
			)}
		</div>
	);
};

export default GlassConfigTableRowCell;
