import AbstractFilter from './AbstractFilter.js'

export default class WhiteGlassFilter extends AbstractFilter {
  constructor() {
    super('WhiteGlassFilter');
    this.i18n_prefix = "whiteGlass_";
    this.filterOptions = {
      "checked": "yes",
      "unchecked": "no",
    };
  }

  _isWhiteGlass(aGlass) {
    // Some entries are empty: treat those as unchecked
    return aGlass.white_glass ? "checked" : "unchecked";
  }

  applyFilter(dbView) {
    super.applyFilter(dbView);
    if (this.selectedFilter === null) {
      return;
    }

    for (var i = 0; i < dbView['frg'].length; ++i) {
      if (this._isWhiteGlass(dbView['frg'][i]) !== this.selectedFilter) {
        dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
        i -= 1;
      }
    }

    for (var i = 0; i < dbView['mid'].length; ++i) {
      if (this._isWhiteGlass(dbView['mid'][i]) !== this.selectedFilter) {
        dbView['midFiltered'].push(dbView['mid'].splice(i, 1)[0]);
        i -= 1;
      }
    }

    for (var i = 0; i < dbView['out'].length; ++i) {
      if (this._isWhiteGlass(dbView['out'][i]) !== this.selectedFilter) {
        dbView['outFiltered'].push(dbView['out'].splice(i, 1)[0]);
        i -= 1;
      }
    }
  }
}