import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { PageNavigation } from 'components/PageNavigation';

import { SaveAsBlock } from 'components/SaveAsSection';
import { ConfigurationDataContext } from 'context/configuration';
import { AuthContext } from 'context/auth';
import HighlightComponent from 'components/FX/HighlightComponent';
import GlassProductSideOption from './GlassProductSideOption';

const sqmPriceFormat = (price) => {
	if (!price) {
		return price;
	}
	const roundedPrice = Math.round(price);
	if (roundedPrice === price) {
		return `${price}.-`;
	}
	return `${Math.round(price * 100) / 100.0}`; // Display 2 digits
};

const GlassProductSidebar = ({
	key,
	rightKeys,
	glassConfig,
	uiHandlerRef,
}) => {
	const { t } = useTranslation('commonConfigurationAction');
	const {
		addNewConfiguration, editingGlassId, saveEditedConfiguration, isLoading,
	} = useContext(ConfigurationDataContext);
	const { currentUser } = useContext(AuthContext);

	const onAddToCartHandler = () => {
		if (Object.keys(glassConfig).length > 0 && !isLoading) {
			if (editingGlassId === null) {
				addNewConfiguration(glassConfig);
			} else {
				saveEditedConfiguration(glassConfig);
			}
		}
	};

	let glass_sqm_price = sqmPriceFormat(glassConfig?.flatGlassStructure?.sqmPrice);

	return (
		<div id={key} className="glass_product_col info_mod">
			<PageNavigation
				showOnlyOnDesktop
				hasBottomOffset
			/>
			<HighlightComponent delay={4000}>
				<div className="instruction_side_wrap">
					<div className="instruction instruction_side">
						<strong>2.</strong>
						{t('secondCustomize')}
					</div>
					{rightKeys.map(f => <GlassProductSideOption name={f} uiHandlerRef={uiHandlerRef} />)}
				</div>
			</HighlightComponent>
			{
				/* only show when logged in */
				currentUser && glass_sqm_price ? (
					<div className="glass_product_sqm_price">
						<span>
							{glass_sqm_price}
							&nbsp;/&nbsp;m
							<sup>2</sup>
						</span>
					</div>
				) : null
			}
			<div className="btn_wrap offset_mod flex_mod">
				<HighlightComponent delay={6000}>
					<div className="instruction_side_wrap">
						<div className="instruction instruction_side instruction_side instruction_hidden">
							<strong>3.</strong>
							{t('addYourGlassToCart')}
						</div>
						<button className="btn_v3" type="button" onClick={onAddToCartHandler}>
							<span className="btn_in">{editingGlassId === null ? t('addToCart') : t('updateInCart')}</span>
						</button>
					</div>
				</HighlightComponent>
			</div>
			<SaveAsBlock pdfData={glassConfig} isHomePage />
		</div>
	);
};

export default GlassProductSidebar;
