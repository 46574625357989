import React, { useContext } from "react";
import { BlockHeading } from "components/BlockHeading";
import { useTranslation } from "react-i18next";
import { AuthContext } from "context/auth";

const ConfigurationCode = ({ code }) => {
	const { t } = useTranslation("cartPage");
	const { currentUser } = useContext(AuthContext);

	return (
		<div className="configuration_code">
			<BlockHeading
				title={t("configurationCodeTitle")}
				iconName="config_code"
			/>
			<div className="configuration_code__in">
				<div className="configuration_code__info">
					<div className="configuration_code__head">
						<div className="configuration_code__title">
							{t("configurationCodeLabel")}
						</div>
						{code && <div className="configuration_code__value">{code}</div>}
					</div>
					{!currentUser ? (
						<div className="configuration_code__descr">
							{t("configurationCodeDescription")}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

export default ConfigurationCode;
