import { AdditionalPageNavigation } from 'components/AdditionalPageNavigation';
import { PageNavigation } from 'components/PageNavigation';
import React from 'react';

const PageNavHeading = () => {
	return (
		<div className="page_nav_heading">
			<AdditionalPageNavigation />
			<PageNavigation />
		</div>
	);
};

export default PageNavHeading;
