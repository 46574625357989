import { BlockHeading } from 'components/BlockHeading';
import { ConfigurationBlockList } from 'components/ConfigurationBlock';
import { FormSearchField } from 'components/Form';
import { PaginationBlock } from 'components/PaginationBlock';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { chunkArray, cloneObjectWithoutReference, sortReferenceByAlphaNumeric } from 'utils';

const SORT_TYPES = {
	lastEdited: 'lastEdited',
	created: 'created',
	reference: 'reference',
};

// const DIRECTION_UP = 'larger';
// const DIRECTION_BOTTOM = 'smaller';

const DashboardBlock = ({
	titleLabel,
	configurationData,
}) => {
	const { t } = useTranslation('baseFormPlaceholders');
	const [sortedConfigurationData, setSortedConfigurationData] = useState(null);
	const [chunkedConfigurationData, setChunkedConfigurationData] = useState(null);
	const [activeSortType, setActiveSortType] = useState(null);
	const [activePaginationPage, setActivePaginationPage] = useState(1);
	const [totalConfigurationItemsCount, setTotalConfigurationItemsCount] = useState(1);
	const [isSearchApplied, setSearchApplied] = useState(null);
	const [isPaginationShown, setPaginationShown] = useState(false);

	const configurationCountPerPage = useRef(3);

	const sortConfigurationsByDate = (dateSortType, dataToSort) => {
		if (!dateSortType) return;

		const preparedDataToSort = dataToSort || configurationData;

		const duplicatedConfigurationData = cloneObjectWithoutReference(preparedDataToSort);

		const sortData = (a, b) => {
			const dateA = dateSortType === SORT_TYPES.created ? a.publishedAt : a.lastEditedDateInMilliseconds;
			const dateB = dateSortType === SORT_TYPES.created ? b.publishedAt : b.lastEditedDateInMilliseconds;

			return dateB - dateA;
			// if (activeSortTypeDirection === DIRECTION_UP) return dateB - dateA;
			// if (activeSortTypeDirection === DIRECTION_BOTTOM) return dateA - dateB;
		};

		duplicatedConfigurationData.sort(sortData);

		setSortedConfigurationData(duplicatedConfigurationData);
		setActiveSortType(dateSortType);
	};

	const sortConfigurationByReference = () => {
		const sortedConfigurations = sortReferenceByAlphaNumeric(sortedConfigurationData || configurationData);

		if (sortedConfigurations) {
			setSortedConfigurationData(sortedConfigurations);
			setActiveSortType(SORT_TYPES.reference);
		}
	};

	const handleClickOnSortByDateButton = (sortType) => {
		if (!sortType) return;

		if (sortType !== activeSortType) {
			sortConfigurationsByDate(sortType, sortedConfigurationData);
		}
	};

	const handleClickOnSortByReferenceButton = () => {
		sortConfigurationByReference();
	};

	const setNewActivePaginationPage = (pageNumber) => {
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[pageNumber - 1]);

		setActivePaginationPage(pageNumber);
		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
	};

	const updateCurrentActivePaginationPage = () => {
		const activePageData = cloneObjectWithoutReference(chunkedConfigurationData[activePaginationPage - 1]);

		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
	};

	const handlePaginationPageChange = (pageNumber) => {
		setNewActivePaginationPage(pageNumber);
	};

	const handleOnChangeSearchInput = (e) => {
		const inputValueLowerCase = e.target.value.toLowerCase();

		if (inputValueLowerCase.trim() !== '') {
			const filteredArray = configurationData.filter(({
				reference,
				buildingReference,
				created,
				lastEdited,
			}) => {
				const referenceLowerCase = reference.toLowerCase();
				const buildingReferenceLowerCase = buildingReference.toLowerCase();
				const createdLowerCase = created.toLowerCase();
				const lastEditedLowerCase = lastEdited.toLowerCase();

				return (
					referenceLowerCase.includes(inputValueLowerCase)
					|| buildingReferenceLowerCase.includes(inputValueLowerCase)
					|| createdLowerCase.includes(inputValueLowerCase)
					|| lastEditedLowerCase.includes(inputValueLowerCase)
				);
			});

			setSortedConfigurationData(filteredArray);
			setSearchApplied(true);
		} else {
			if (isPaginationShown) {
				updateCurrentActivePaginationPage();
			} else {
				sortConfigurationsByDate(activeSortType, configurationData);
			}

			setSearchApplied(false);
		}
	};

	const splitConfigurationsByPage = () => {
		const chunkedData = chunkArray(configurationData, configurationCountPerPage.current);
		const activePageData = cloneObjectWithoutReference(chunkedData[activePaginationPage - 1]);

		setTotalConfigurationItemsCount(configurationData.length);
		sortConfigurationsByDate(SORT_TYPES.lastEdited, activePageData);
		setChunkedConfigurationData(chunkedData);
		setPaginationShown(true);
	};

	useEffect(() => {
		if (configurationData) {
			if (configurationData.length > configurationCountPerPage.current) {
				splitConfigurationsByPage();
			} else {
				switch (activeSortType) {
					case SORT_TYPES.created:
						sortConfigurationsByDate(SORT_TYPES.created);
						break;
					case SORT_TYPES.reference:
						sortConfigurationByReference();
						break;
					default:
						sortConfigurationsByDate(SORT_TYPES.lastEdited);
						break;
				}
			}
		}
	}, [configurationData]);

	return (
		<div className="dashboard_block">
			<div className="dashboard_block__heading">
				<BlockHeading
					title={t(titleLabel)}
				/>
				<div className="dashboard_block__field">
					<FormSearchField
						id={titleLabel}
						placeholder={t('searchField')}
						name={titleLabel}
						onChange={handleOnChangeSearchInput}
					/>
				</div>
			</div>
			{sortedConfigurationData && sortedConfigurationData.length > 0 ? (
				<ConfigurationBlockList
					configurationData={sortedConfigurationData}
					handleClickOnSortByReferenceButton={handleClickOnSortByReferenceButton}
					handleClickOnSortByDateButton={handleClickOnSortByDateButton}
				/>
			) : null}
			{configurationData && isPaginationShown && !isSearchApplied ? (
				<PaginationBlock
					activePaginationPage={activePaginationPage}
					itemsCountPerPage={configurationCountPerPage.current}
					totalItemsCount={totalConfigurationItemsCount}
					handlePaginationPageChange={handlePaginationPageChange}
				/>
			) : null}

		</div>
	);
};

export default DashboardBlock;
