import React from 'react';
import {
	Text,
	View,
} from '@react-pdf/renderer';
import PdfFormCheckbox from './PdfFormCheckbox';

import styles from '../styles';

const PdfGlassConfigTableRowCell = ({
	cellData,
	isLastCell,
	isNotes,
}) => {
	const { name, value } = cellData;

	const preparedValue = value === '' ? '-' : value;

	const getGlassConfigTableCellStyle = () => {
		let cellStyles = {
			...styles.glassConfigTableCell,
		};

		if (isLastCell) {
			cellStyles = {
				...cellStyles,
				...styles.glassConfigTableCellLastChild,
			};
		}

		if (isNotes) {
			cellStyles = {
				...cellStyles,
				...styles.glassConfigTableCellNotes,
			};
		}

		return cellStyles;
	};

	return (
		<View style={getGlassConfigTableCellStyle()}>
			{name === 'form' ? (
				<PdfFormCheckbox
					value={preparedValue}
				/>
			) : (
				<View>
					<Text style={styles.glassConfigTableCellValueIn}>
						{preparedValue}
					</Text>
				</View>
			)}
		</View>
	);
};

export default PdfGlassConfigTableRowCell;
