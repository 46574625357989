import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import { ConfigurationCode } from 'components/ConfigurationCode';
import { PageNavigation } from 'components/PageNavigation';
import { YourReference } from 'components/YourReference';
import { GlassConfig } from 'components/GlassConfig';
import { UploadedDocuments } from 'components/UploadedDocuments';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ToastifyHandler from 'utils/ToastifyHandler';
import { CONFIGURATIONS_COLLECTION_NAME, PROMISE_STATES, USER_ROLES } from 'utils/constant';
import {
	collection,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { firebaseDB } from 'utils/firebase';
import { AuthContext } from 'context/auth';

const PreviewPage = () => {
	const [configuration, setConfiguration] = useState(null);
	const { t } = useTranslation(['cartPage', 'firebaseErrorMessages']);
	const { configurationCode } = useParams();
	const notificationsHandler = useRef(new ToastifyHandler());
	const { currentUserAdditionalData, currentUserRole } = useContext(AuthContext);
	const [/* loadingConfigurationStatus, */setLoadingConfigurationStatus] = useState(PROMISE_STATES.pending);

	const getConfiguration = async () => {
		notificationsHandler.current.pending(t('notifications:loadConfiguration'));
		setLoadingConfigurationStatus(PROMISE_STATES.pending);
		const companyConfigurationsRef = collection(firebaseDB, CONFIGURATIONS_COLLECTION_NAME);

		try {
			let companyConfigurationQuery;

			if (currentUserRole === USER_ROLES.admin || currentUserRole === USER_ROLES.superAdmin) {
				companyConfigurationQuery = query(
					companyConfigurationsRef,
					where('code', '==', configurationCode),
				);
			} else {
				companyConfigurationQuery = query(
					companyConfigurationsRef,
					where('companyId', '==', currentUserAdditionalData.companyId),
					where('code', '==', configurationCode),
				);
			}

			const querySnapshot = await getDocs(companyConfigurationQuery);

			let resConfiguration;

			querySnapshot.forEach((doc) => {
				resConfiguration = doc.data();
			});

			if (resConfiguration) {
				notificationsHandler.current.success(t('notifications:configurationUpdatedSuccessfully'));
				setConfiguration(resConfiguration);
				setLoadingConfigurationStatus(PROMISE_STATES.fulfilled);
			} else {
				notificationsHandler.current.rejected(t('notifications:configurationNotFound'));
				setLoadingConfigurationStatus(PROMISE_STATES.rejected);
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
			setLoadingConfigurationStatus(PROMISE_STATES.rejected);
		}
	};

	useEffect(() => {
		if (configurationCode && currentUserAdditionalData) {
			getConfiguration();
		}
	}, [currentUserAdditionalData]);

	return (
		configuration ? (
			<section className="section preview_page">
				<div className="section__in">
					<div className="section__block">
						<div className="page_head">
							<PageNavigation />
						</div>
					</div>
					{configuration ? (
						<>
							<div className="section__block">
								<ConfigurationCode
									code={configuration.code}
								/>
							</div>
							<div className="section__block">
								<YourReference
									parent={configuration}
									values={{
										reference: configuration.reference,
										buildingReference: configuration.buildingReference,
									}}
								/>
							</div>
							{configuration.glassTypes && configuration.glassTypes.length ? (
								configuration.glassTypes.map((glassData, index) => {
									return (
										<div className="section__block" key={glassData.id || index}>
											<GlassConfig
												parent={configuration.glassTypes}
												glassData={glassData}
												attrKey={index}
												glassIndex={index + 1}
												showHeadingDropdownMenu
											/>
										</div>
									);
								})
							) : null}
							{configuration.uploadedDocuments.length > 0 ? (
								<div className="section__block">
									<UploadedDocuments previewPage configuration={configuration} />
								</div>
							) : null}
						</>
					) : null}
				</div>
			</section>
		) : null
	);
};

export default PreviewPage;
