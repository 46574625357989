import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/auth';
import { Formik } from 'formik';
import {
	emailValidation,
	validateConfirmPassword,
	validatePassword,
} from 'utils';
import { PROMISE_STATES } from 'utils/constant';
import ToastifyHandler from 'utils/ToastifyHandler';
import { updateEmail, updatePassword } from 'firebase/auth';
import FormField from '../FormField';

const UpdateUserPasswordForm = () => {
	const { t } = useTranslation(['baseFormPlaceholders', 'firebaseErrorMessages']);
	const { currentUser } = useContext(AuthContext);
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const [initialFormValues, setInitialFormValues] = useState(null);
	const formikRef = useRef();
	const notificationsHandler = useRef(new ToastifyHandler());

	const formikValidate = {
		userEmail: email => emailValidation(email),
		userPassword: password => validatePassword(t('userPasswordPlaceholder'), password),
		userRepeatPassword: repeatPasswordValue => validateConfirmPassword(formikRef.current.values.userPassword, repeatPasswordValue),
	};

	const createInitialFormValues = () => {
		const { email } = currentUser;

		const initialValues = {
			userEmail: email,
			userPassword: '',
			userRepeatPassword: '',
		};

		setInitialFormValues(initialValues);
	};

	useEffect(() => {
		if (currentUser) {
			createInitialFormValues();
		}
	}, [currentUser]);

	return (
		initialFormValues ? (
			<Formik
				initialValues={initialFormValues}
				innerRef={formikRef}
				mapPropsToValues={() => {
					return {
						...initialFormValues,
					};
				}}
				validate={values => Object.keys(values).reduce((errors, field) => {
					const error = formikValidate[field](values[field]);
					return {
						...errors,
						...(error && { [field]: error }),
					};
				}, {})}
				onSubmit={async (values) => {
					const {
						userEmail: email,
						userPassword: newUserPassword,
					} = values;

					setLoadingStatus(PROMISE_STATES.pending);
					notificationsHandler.current.pending(t('notifications:pleaseWait'));

					try {
						if (currentUser.email !== email) {
							await updateEmail(currentUser, email);
						}

						await updatePassword(currentUser, newUserPassword);

						setLoadingStatus(PROMISE_STATES.fulfilled);
						notificationsHandler.current.success(t('notifications:dataHasBeenSuccessfullyUpdated'));
					} catch (error) {
						const { code } = error;
						notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
						setLoadingStatus(PROMISE_STATES.rejected);
					}
				}}
				validateOnChange={false}
			>
				{({
					handleSubmit,
					handleBlur,
					handleChange,
					values,
					errors,
					touched,
				}) => (
					<form className="account_form_el" onSubmit={handleSubmit}>
						<ul className="form_fields offset_2_mod">
							<li className="form_fields__item">
								<FormField
									id="userEmail"
									placeholder={t('userEmailPlaceholder')}
									name="userEmail"
									type="email"
									required
									iconName="mail"
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
								/>
								<div className="form_fields__error">{touched.userEmail && errors.userEmail}</div>
							</li>
							<li className="form_fields__item">
								<FormField
									id="userPassword"
									placeholder={t('userPasswordPlaceholder')}
									name="userPassword"
									type="password"
									required
									iconName="lock"
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
								/>
								<div className="form_fields__error">{touched.userPassword && errors.userPassword}</div>
							</li>
							<li className="form_fields__item">
								<FormField
									id="userRepeatPassword"
									placeholder={t('repeatPasswordPlaceholder')}
									name="userRepeatPassword"
									type="password"
									required
									iconName="lock"
									values={values}
									handleBlur={handleBlur}
									handleChange={handleChange}
								/>
								<div className="form_fields__error">{touched.userRepeatPassword && errors.userRepeatPassword}</div>
							</li>
						</ul>
						<button
							className="account_form__button"
							type="submit"
							disabled={loadingStatus === PROMISE_STATES.pending}
						>
							<div className="btn_base">{t('accountPage:saveButton')}</div>
						</button>
					</form>
				)}
			</Formik>
		) : null
	);
};

export default UpdateUserPasswordForm;
