export const selectStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'white',
		border: '0',
		minHeight: 'unset',
		boxShadow: 'none !important',
		padding: '',
		position: 'static',
		':hover': {
			cursor: 'pointer',
		},
	}),
	option: (styles, { isSelected }) => {
		return {
			...styles,
			padding: '1rem  1.4rem ',
			minWidth: '22rem',
			fontSize: '.8rem',
			color: isSelected ? '#456D86' : '#4C4B4E',
			borderBottom: '1px dashed #70707040',
			backgroundColor: 'white',
			':hover': {
				color: '#456D86',
				cursor: 'pointer',
			},
		};
	},
	menu: (styles) => {
		return {
			...styles,
			top: 'auto',
			bottom: '100%',
			left: '50%',
			transform: 'translateX(-50%) translateY(0%)',
			height: 'auto',
			width: 'auto',
			maxHeight: 'auto',
			boxShadow: '0 1rem 1rem  #00000026',
			borderRadius: 0,
			border: 0,
		};
	},
	menuList: (styles) => {
		return {
			...styles,
			padding: 0,
			maxHeight: '44.8rem',
			width: 'auto',
			overflowY: 'auto',
			border: 0,
			boxShadow: 'unset',

			'::-webkit-scrollbar': {
				width: '.5rem',
			},
			'::-webkit-scrollbar-track': {
				backgroundColor: '#E5E5E5',
			},
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#456D86',
			},
		};
	},
	input: (styles) => ({
		...styles,
		margin: '0',
		padding: '0',
		position: 'absolute',
	}),
	placeholder: (styles) => ({
		...styles,
	}),
	singleValue: (styles) => ({
		...styles,
		fontSize: '1.2rem ',
		textTransform: 'uppercase',
		color: 'black',
		margin: 0,
		marginBottom: '-.4rem ',
		paddingRight: '0',
	}),

	valueContainer: (styles) => {
		return {
			...styles,
			padding: '0',
		};
	},
	indicatorsContainer: (styles, { isDisabled }) => {
		return {
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			right: 0,
			display: isDisabled ? 'none' : 'block',
		};
	},

	indicatorSeparator: (styles) => {
		return {
			...styles,
			display: 'none',
		};
	},
	container: (styles) => {
		return {
			...styles,
			position: 'static',
		};
	},
	dropdownIndicator: (styles) => {
		return {
			...styles,
			color: '#707070',
			':hover': {
				color: '#707070c2',
			},
		};
	},
};

export const selectBoxStyles = {
	control: (styles) => ({
		...styles,
		backgroundColor: 'transparent',
		flexWrap: 'nowrap',
		border: '0',
		minHeight: 'unset',
		boxShadow: 'none !important',
		justifyContent: 'flex-start',
		':hover': {
			cursor: 'pointer',
		},
	}),
	option: (styles, { isSelected }) => {
		return {
			...styles,
			padding: '1rem  1.4rem ',
			minWidth: '22rem ',
			fontSize: '.8rem ',
			color: isSelected ? '#456D86' : '#4C4B4E',
			borderBottom: '1px dashed #70707040',
			backgroundColor: 'white',
			':hover': {
				color: '#456D86',
				cursor: 'pointer',
			},
		};
	},
	menu: (styles) => {
		return {
			...styles,
			left: '50%',
			transform: 'translateX(-50%)',
			height: 'auto',
			width: 'auto',
			maxHeight: 'auto',
			boxShadow: '0 1rem  1rem  #00000026',
			borderRadius: 0,
			border: 0,
		};
	},
	menuList: (styles) => {
		return {
			...styles,
			padding: 0,
			maxHeight: '44.8rem ',
			width: 'auto',
			overflowY: 'auto',
			border: 0,
			boxShadow: 'unset',

			'::-webkit-scrollbar': {
				width: '.5rem',
			},
			'::-webkit-scrollbar-track': {
				backgroundColor: '#E5E5E5',
			},
			'::-webkit-scrollbar-thumb': {
				backgroundColor: '#456D86',
			},
		};
	},
	input: (styles) => ({
		...styles,
		margin: '0',
		padding: '0',
	}),
	placeholder: (styles) => ({
		...styles,
	}),
	singleValue: (styles) => ({
		...styles,
		fontSize: '1.2rem ',
		textTransform: 'uppercase',
		color: '#707070',
		margin: 0,
		marginBottom: '-.4rem ',
		paddingRight: '1rem ',
	}),

	valueContainer: (styles) => {
		return {
			...styles,
			padding: '0',
			flex: 'unset',
		};
	},
	indicatorsContainer: (styles, { isDisabled }) => {
		return {
			margin: '-8px',
			display: isDisabled ? 'none' : 'block',
		};
	},
	indicatorSeparator: (styles) => {
		return {
			...styles,
			display: 'none',
		};
	},
	dropdownIndicator: (styles) => {
		return {
			...styles,
			color: '#707070',
			':hover': {
				color: '#707070c2',
			},
		};
	},
};
