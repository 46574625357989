import React, { useContext } from "react";
import { CSSTransition } from "react-transition-group";

import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "i/icons/close.svg";
import { BlockHeading } from "components/BlockHeading";
import { resetFilters } from "components/ThreeJs/assets/main";

import { UiContext } from "context/ui";
import { clearAllBrowserUrlParameter } from "utils";
import { AuthContext } from "context/auth";
import { useNavigate } from "react-router-dom";

const ResetGlassPopup = ({ isOpen }) => {
	const { t } = useTranslation("resetGlassForm");
	const { showPopupByKey } = useContext(UiContext);
	const { guestUser, setGuestUser } = useContext(AuthContext);
	const navigate = useNavigate();

	const closePopup = () => {
		showPopupByKey();
	};

	const onPopupEntering = () => {
		document.body.classList.add("filter_mod");
	};

	const onPopupExit = () => {
		document.body.classList.remove("filter_mod");

		clearAllBrowserUrlParameter();
	};

	const resetGlass = () => {
		resetFilters();

		if (guestUser) {
			setGuestUser(null);
		}

		navigate("/");
		closePopup();
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			onEntering={onPopupEntering}
			onExit={onPopupExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in reset_glass_popup">
					<div className="popup_content">
						<button className="popup_close" type="button" onClick={closePopup}>
							<CloseIcon className="icon icon-close size_mod" />
						</button>
						<div className="reset_glass">
							<BlockHeading title={t("title")} iconName="pensil" />
							<div className="popup_note">{t("text")}</div>
							<div className="reset_glass_btn	">
								<button type="button" className="btn_base" onClick={resetGlass}>
									{t("resetButton")}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default ResetGlassPopup;
