import React from 'react';
import ConfigurationBlock from './ConfigurationBlock';

const ConfigurationBlockList = ({
	configurationData,
	handleClickOnSortByDateButton,
	handleClickOnSortByReferenceButton,
}) => {
	return (
		<ul className="configuration_block_list">
			{configurationData.map(({
				code,
				reference,
				buildingReference,
				lastEdited,
				created,
				amountGlassTypes,
				type,
				status,
				uploadedDocuments,
			}, index) => {
				return (
					<li className="configuration_block_list__item" key={code}>
						<ConfigurationBlock
							code={code}
							reference={reference}
							buildingReference={buildingReference}
							amountGlassTypes={amountGlassTypes}
							created={created}
							lastEdited={lastEdited}
							type={type}
							status={status}
							uploadedDocuments={uploadedDocuments}
							fullConfiguration={configurationData[index]}
							handleClickOnSortByReferenceButton={handleClickOnSortByReferenceButton}
							handleClickOnSortByDateButton={handleClickOnSortByDateButton}
						/>
					</li>
				);
			})}
		</ul>
	);
};

export default ConfigurationBlockList;
