import { BaseApplication } from 'ohzi-core';
import { Configuration } from 'ohzi-core';
import { NormalRender } from 'ohzi-core';
import { Graphics } from 'ohzi-core';
import { ResourceContainer } from 'ohzi-core';
import { ViewManager } from 'ohzi-core';
import * as Stats from 'stats-js';

import DatGui from './components/DatGui';
import SceneController from './components/SceneController';
import HomeView from './views/HomeView';
import KonfiguratorView from './views/KonfiguratorView';
import { SceneManager } from 'ohzi-core';

import { sRGBEncoding } from 'three';
import { Scene } from 'three';
import { CubeRefractionMapping } from 'three';
import { RepeatWrapping } from 'three';
import { VSMShadowMap } from 'three';
import { MirroredRepeatWrapping } from 'three';
import { ACESFilmicToneMapping } from 'three';
import { PlaneGeometry } from 'three';
import { ShadowMaterial } from 'three';
import { Mesh } from 'three';

import { Sections } from './views/Sections';

//
import Structure from './components/Structure';
import Sky from './components/Sky';

import CustomNormalRender from './CustomNormalRender';

export default class MainApplication extends BaseApplication {
  init() {
    this.scene_controller = SceneController;
    this.normal_render_mode = new NormalRender();

    this.scene_controller.init();

    Graphics.set_state(new CustomNormalRender());
    //Graphics.set_state(this.normal_render_mode);
    Graphics._renderer.outputEncoding = sRGBEncoding;

    Graphics._renderer.toneMapping = ACESFilmicToneMapping;
    Graphics._renderer.shadowMap.type = VSMShadowMap;
    //Graphics._renderer.shadowMap.type = PCFSoftShadowMap;

    Graphics._renderer.shadowMap.enabled = true;

    Graphics._renderer.setClearColor(undefined, 0);
    Graphics._renderer.autoClear = false;
    DatGui.init();

    this.stats = new Stats();
    this.stats.showPanel(false); // 0: fps, 1: ms, 2: mb, 3+: custom
    //Graphics._renderer.domElement.appendChild( this.stats.dom );
    document.body.appendChild(this.stats.dom);
  }

  on_enter() {
    ResourceContainer.get_resource('cube').mapping = CubeRefractionMapping;
    ResourceContainer.get_resource('cube').encoding = sRGBEncoding;

    ResourceContainer.get_resource('cube-blur').mapping = CubeRefractionMapping;
    ResourceContainer.get_resource('cube-blur').encoding = sRGBEncoding;

    ResourceContainer.get_resource('alu-diffuse').repeat.set(6, 0.5);
    ResourceContainer.get_resource('alu-diffuse').wrapT = RepeatWrapping;
    ResourceContainer.get_resource('alu-diffuse').wrapS = RepeatWrapping;
    ResourceContainer.get_resource('alu-diffuse').encoding = sRGBEncoding;

    ResourceContainer.get_resource('noise').wrapT = MirroredRepeatWrapping;

    ResourceContainer.get_resource('noise').wrapS = MirroredRepeatWrapping;

    ResourceContainer.get_resource('alu-normal').repeat.set(6, 0.5);
    ResourceContainer.get_resource('alu-normal').wrapT = RepeatWrapping;
    ResourceContainer.get_resource('alu-normal').wrapS = RepeatWrapping;

    ResourceContainer.get_resource('alu-specular').repeat.set(6, 0.5);
    ResourceContainer.get_resource('alu-specular').wrapT = RepeatWrapping;
    ResourceContainer.get_resource('alu-specular').wrapS = RepeatWrapping;

    ResourceContainer.get_resource('styrofoam-diffuse3').wrapS = MirroredRepeatWrapping;
    ResourceContainer.get_resource('styrofoam-diffuse3').wrapS = MirroredRepeatWrapping;
    ResourceContainer.get_resource('styrofoam-diffuse3').encoding = sRGBEncoding;

    ResourceContainer.get_resource('sun').encoding = sRGBEncoding;

    SceneManager.backScene = new Scene();
    SceneManager.frontScene = new Scene();
    SceneManager.textScene = new Scene();
    //SceneManager.backScene.background = ResourceContainer.get_resource("cube-blur");

    this.sky = new Sky();
    this.sky.scale.setScalar(100);
    SceneManager.current.add(this.sky);

    this.structure = new Structure();
    //this.structure.set({thickness: 1, layers:['10:0']},{thickness:0, layers:['0:0']},{thickness:0, layers:['0:0']},{thickness:0},{thickness:0});

    let floor_geometry = new PlaneGeometry(1000, 1000);
    floor_geometry.rotateX(Math.PI * -0.5);

    let floor_shadow_glass_material = new ShadowMaterial({ opacity: 0.025, dithering: true });
    let floor_shadow_spacer_material = new ShadowMaterial({ opacity: 0.2, dithering: true });

    let floor_shadow_glass = new Mesh(floor_geometry, floor_shadow_glass_material);
    let floor_spacer_glass = new Mesh(floor_geometry, floor_shadow_spacer_material);

    floor_shadow_glass.receiveShadow = true;
    floor_spacer_glass.receiveShadow = true;
    floor_shadow_glass.position.y = -0.0125;
    floor_spacer_glass.position.y = -0.0125;

    SceneManager.frontScene.add(floor_spacer_glass);
    SceneManager.current.add(floor_shadow_glass);

    SceneManager.frontScene.add(this.structure);


    this.config = Configuration;

    this.sections = Sections;

    this.home_view = new HomeView();
    this.konfigurator_view = new KonfiguratorView();
    this.home_view.start();
    this.konfigurator_view.start();

    if (this.config['initial_cam_pos']) {
      this.scene_controller.set_initial_cam_pos(this.config['initial_cam_pos']);
    }
    this.scene_controller.start();

    DatGui.start();

    this.go_to(Sections.INITIAL);
    document.addEventListener('contextmenu', (event) => {
      event.preventDefault();
    }, false);

  }

  go_to(section) {
    ViewManager.go_to_view(section, false);
  }

  update() {
    this.stats.begin();

    this.scene_controller.update();
    this.structure.update();

    this.stats.end();
    this.sky.update();
  }

  updateLabels(frg_w, og_w, mg_w, so_w, sm_w, _u_value, _gas_outside, _gas_middle, rotate_frg) {

    if (!this.structure) {
      //setTimeout(
      //  (
      //    (() => (
      //      this.updateLabels(frg_w, og_w, mg_w, so_w, sm_w, u_value, g_o, g_m)
      //    )).bind(this)
      //  ), 100
      //);

      return;
    }

    this.structure.set(frg_w, mg_w, og_w, sm_w, so_w, rotate_frg);
  }

  //on_post_render()
  //{
  //  Graphics.render(SceneManager.frontScene, CameraManager.current);
  //}


  setInsideOutside(inside_outside) {
    let inside_only = inside_outside === 'Inside';
    let outside_only = inside_outside === 'Outside/Outside';
    if (this.scene_controller.sun) {
      this.scene_controller.sun.visible = !inside_only;
    }
    if (this.scene_controller.indoor_model[1]) {
      this.scene_controller.indoor_model[0].visible = !outside_only;
      this.scene_controller.indoor_model[1].visible = inside_only;
      this.scene_controller.outdoor_model[0].visible = !inside_only;
      this.scene_controller.outdoor_model[1].visible = outside_only;
    }
  }

  // setSun(aBoolean, attempt) {
  //   if (this.sun) {
  //     this.sun.visible = aBoolean;
  //     this.outdoor_model[0].visible = aBoolean;
  //     this.outdoor_model[1].visible = aBoolean;

  //   } else {
  //     if (attempt < 50) {
  //       setTimeout(
  //         (
  //           (() => { this.setSun(aBoolean, attempt + 1) }).bind(this)
  //         ), 100
  //       );
  //     } else {
  //       console.error("Giving up attempting to set the sun");
  //     }
  //   }
  // }

  on_post_start() {
  }
}
