import GasSpacerUIElement from './GasSpacerUIElement.js'
import Insulation from '../Glass/InsulationValues.js';

export default class SpacerUIElement extends GasSpacerUIElement {
  constructor(props) {
    super(props);

    this.i18n_option_key_format = 'spacer_format';
    this.options = [8, 10, 12, 14, 16];
  }

  handleSelectChange = (spacer) => {
    let val = spacer.value;
    if (this.uiHandlerRef.current.onSpacerChanged(this.props.id, val)) {
      this.recreate(val);
    }
  }

  setSpacerOptions(options) {
    this.options.splice(0, this.options.length, ...options);
    this.recreate();
  }

  refreshAvailableOptions() {
    const uiHandler = this.uiHandlerRef.current;
    this.resetFilteredOptions();

    let glass_structure = this.uiHandlerRef.current.structure.getGlassStructure();
    if (glass_structure === '1-glazed') {
      return;
    }

    let selectedGas = uiHandler.structure.gasOutside;
    let spacers = uiHandler.dbView['uValue'][glass_structure].filter(v => v.gas === selectedGas).reduce((acc, o) => {
      if (!acc.includes(o.spacer1)) {
        acc.push(o.spacer1);
      }
      return acc;
    }, []);

    this.options.forEach(o => {
      if (!spacers.includes(o)) {
        this.filteredOptions.push(o);
        let spacer2 = glass_structure === '3-glazed' ? o : 0;
        this.filteredOptionsUValue.push(Insulation.calculateUValueForGlass(o, spacer2, selectedGas));
      }
    });
    this.recreate();
  }
}