import { ConfigurationDataContext } from 'context/configuration';
import { useNavigate } from 'react-router-dom';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { useLocation } from 'react-router';
import { AuthContext } from 'context/auth';
import { ReactComponent as CloseIcon } from 'i/icons/close_circle.svg';
import SaveAsBlock from './SaveAsBlock';

const SaveAsSection = ({ isCartPage }) => {
	const { t } = useTranslation('commonConfigurationAction');
	const location = useLocation();
	const navigate = useNavigate();
	const {
		configuration, totalGlassSizes, totalGlassSizesSum, isSizesFilled, setShowErrors,
	} = useContext(ConfigurationDataContext);
	const { currentUser } = useContext(AuthContext);

	const onClickButtonHandler = () => {
		if (isCartPage && !isSizesFilled) {
			setShowErrors(true);
			toast.error(t('sizeValidationError'), {
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: <CloseIcon />,
			});
		} else if (totalGlassSizes?.[0].length === 0) {
			toast.error(t('selectProductToContinue'), {
				autoClose: 4000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: <CloseIcon />,
			});
		} else if (configuration && configuration.glassTypes.length) {
			setShowErrors(false);

			if (currentUser) {
				navigate('/summary');
				return;
			}

			navigate('/get-my-glass');
		}
	};

	const sectionClassnames = classNames('section save_as_section', {
		'save_as_section--line_state': location.pathname !== '/cart',
	});

	return (
		<section className={sectionClassnames}>
			<div className="section__in">
				<div className="save_as_section__in">
					{location.pathname === '/cart' ? (
						<div className="save_as_section__continue_button">
							<button
								className="btn_v3"
								onClick={onClickButtonHandler}
								type="button"
							>
								{t('continue')}
							</button>
						</div>
					) : null}
					<SaveAsBlock
						totalValuesSum={totalGlassSizesSum}
						totalValues={totalGlassSizes}
						pdfData={configuration}
					/>
				</div>
			</div>
		</section>
	);
};

export default SaveAsSection;
