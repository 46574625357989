/* eslint-disable indent */
/* eslint-disable comma-dangle */
const jsonAppData = {
  headerMenu: [
    {
      title: 'de',
    },
    {
      title: 'fr',
    },
    {
      title: 'it',
    },
    {
      title: 'en',
    },
  ],
  contactInformation: [
    {
      title: 'Oberwilstrasse 3',
    },
    {
      title: '+41 (0) 62 961 80 30',
      type: 'tel',
    },
    {
      title: 'CH-3253 Schnottwil',
    },
    {
      title: 'info@cdr.ch',
      type: 'mailto',
    },
  ],
  pageNavigation: [
    {
      label: 'login',
      url: '',
    },
    {
      label: 'myCode',
      url: '',
    },
    {
      label: 'dashboard',
      url: '',
    },
    {
      label: 'account',
      url: '/account',
    },
    {
      label: 'logout',
      url: '',
    },
    {
      label: 'adminDashboard',
      url: '',
    },
  ],
  additionalPageNavigation: [
    {
      label: 'configure',
      url: '/',
    },
    {
      label: 'cart',
      url: '/cart',
    },
    {
      label: 'inquireOrder',
      url: '/get-my-glass',
    },
  ],
  glassConfigDropdown: [
    {
			label: 'edit',
		},
    {
      label: 'duplicate',
    },
    {
      label: 'delete',
    },
    {
      label: 'pdf',
    }
  ],
  draftsConfigurationDropdown: [
    {
      label: 'duplicate',
    },
    {
      label: 'edit',
    },
    {
      label: 'pdf',
    },
    {
      label: 'request',
    },
    {
      label: 'order',
    },
    {
      label: 'delete',
    },
  ],
  orderAndRequestConfigurationDropdown: [
    {
      label: 'duplicateAsDraft',
    },
    {
      label: 'pdf',
    },
    // {
    // 	label: 'delete',
    // },
  ],
  openOrderAndRequestConfigurationDropdown: [
    {
      label: 'complete',
    },
    {
      label: 'delete',
    },
  ],
  closedOrderAndRequestConfigurationDropdown: [
    {
      label: 'reopen',
    },
    {
      label: 'delete',
    },
  ],
  clientsListDropdown: [
    {
      label: 'open',
    },
    {
      label: 'delete',
    },
  ],
  faqPage: {
		searchInputPlaceholder: {
			en: 'Type your question',
			fr: 'Type your question fr',
			it: 'Type your question it',
			de: 'Type your question de',
		},
		buttonHaveQuestionsTitle: {
			en: 'Still have<br> questions?',
		},
		buttonBackFromSearch: {
			en: 'Back',
			fr: 'Back',
			it: 'Back',
			de: 'Back',
		},
		foundResultFoundTitle: {
			en: 'Found',
			fr: 'Found',
			it: 'Found',
			de: 'Found',
		},
		foundResultAnswerTitle: {
			en: 'Answer',
			fr: 'Answer',
			it: 'Answer',
			de: 'Answer',
		},
		resultNotFoundTitle: {
			en: 'Nothing found',
			fr: 'Nothing found',
			it: 'Nothing found',
			de: 'Nothing found',
		},
		resultNotFoundDescription: {
			en: 'Please amend you search parameters and try again',
			fr: 'Nothing found',
			it: 'Nothing found',
			de: 'Nothing found',
		},
	},
  cartPage: {
    yourReferenceInputPlaceholder: 'e.g. ZQH231-21',
    buildingReferenceInputLabel: 'building XYZ',
    glassConfig: {
      fireResistant: {
        selected: 'Brandschutzglas EI30',
        options: ['Brandschutzglas EI30'],
      },
      spacer1: {
        selected: '15MM',
        options: ['15MM', '16MM', '17MM'],
      },
      gas1: {
        selected: 'krypton',
        options: ['krypton'],
      },
      middleGlass: {
        selected: 'ESG Extraweiss 5mm',
        options: ['ESG Extraweiss 5mm'],
      },
      spacer2: {
        selected: '16MM',
        options: ['16MM'],
      },
      gas2: {
        selected: 'krypton',
        options: ['krypton'],
      },
      outsideGlass: {
        selected: 'vsg 16-2 klarfolie',
        options: ['vsg 16-2 klarfolie'],
      },
      structure: {
        selected: '2-glazed',
        options: ['1-glazed (mono)', '2-glazed-ISO', '3-glazed-ISO'],
      },
      manufacturer: {
        selected: 'AGC',
        options: ['Glas Trösch', 'AGC'],
      },
      fireResistanceClass: {
        selected: 'EI30',
        options: ['EI30', 'EI60', 'EI90'],
      },
      insulation: {
        selected: 'U-Value 1.7',
        options: [
          'U-Value 5.4',
          'U-Value 1.7',
          'U-Value 1.4',
          'U-Value 1.3',
          'U-Value 1.2',
          'U-Value 1.1',
          'U-Value 1.0',
          'U-Value 0.9',
          'U-Value 0.8',
          'U-Value 0.7',
          'U-Value 0.6',
          'U-Value 0.5',
        ],
      },
      application: {
        selected: 'Inside',
        options: ['Inside', 'Inside/Outside', 'Outside/Outside'],
      },
      whiteGlass: {
        selected: 'checked',
        options: ['checked', 'unchecked'],
      },
      resistanceClass: {
        selected: 'RC 2 (WK 2) / P4A',
        options: [
          'RC 2 (WK 2) / P4A',
          'RC 3 (WK 3) / P5A',
          'RC 4 (WK 4) / P6B',
          'RC 5 (WK 5) / P7B',
          'RC 6 (WK 6) / P8B',
        ],
      },
      buttJoints: {
        selected: 'unchecked',
        options: ['checked', 'unchecked'],
      },
      sound: {
        selected: 'wert',
        options: ['wert'],
      },
    },
    glassConfigTableHeadingRow: [
      {
        label: 'length',
      },
      {
        label: 'width',
      },
      {
        label: 'quantity',
      },
      {
        label: 'form',
      },
      {
        label: 'notes',
      },
      {
        label: 'cantonalInsurance',
      },
      {
        label: 'pc',
      },
      {
        label: 'pos',
      },
      {
        label: 'kg',
      },
      {
        label: 'price',
      },
    ],
    glassConfigTable: [
      {
        row: [
          {
            name: 'length',
            value: '2000',
          },
          {
            name: 'width',
            value: '1000',
          },
          {
            name: 'quantity',
            value: '2',
          },
          {
            name: 'form',
            value: 'checked',
          },
          {
            name: 'notes',
            value: '',
          },
        ],
      },
      {
        row: [
          {
            name: 'length',
            value: '1130',
          },
          {
            name: 'width',
            value: '300',
          },
          {
            name: 'quantity',
            value: '1',
          },
          {
            name: 'form',
            value: 'unchecked',
          },
          {
            name: 'notes',
            value: '',
          },
        ],
      },
    ],
  },
  guestForm: [
    {
      keyForI18n: 'userGenderPlaceholder',
      id: 'guestGender',
      isRequired: true,
      options: [
        { value: 'male', label: 'userGenderMale' },
        { value: 'female', label: 'userGenderFemale' },
        { value: 'div', label: 'userGenderDiv' },
      ],
    },
    {
      keyForI18n: 'userCompanyPlaceholder',
      id: 'guestUserCompanyName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userFirstNamePlaceholder',
      id: 'guestUserFirstName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userLastNamePlaceholder',
      id: 'guestUserLastName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userCompanyEmailPlaceholder',
      id: 'guestUserEmail',
      type: 'email',
      isRequired: false,
    },
    {
      keyForI18n: 'userFunctionPlaceholder',
      id: 'guestFunction',
      isRequired: false,
      options: [
        { value: 'owner', label: 'userFunctionOwner' },
        { value: 'co-owner', label: 'userFunctionCoOwner' },
        { value: 'management', label: 'userFunctionManagement' },
        {
          value: 'executive management',
          label: 'userFunctionExecutiveManagement',
        },
        {
          value: 'purchasing / procurement',
          label: 'userFunctionPurchasingProcurement',
        },
        { value: 'sales', label: 'userFunctionSales' },
        { value: 'project management', label: 'userFunctionProjectManagement' },
        {
          value: 'calculation / costing',
          label: 'userFunctionCalculationCosting',
        },
        {
          value: 'administrative processing',
          label: 'userFunctionAdministrativeProcessing',
        },
        { value: 'other', label: 'userFunctionOther' },
      ],
    },
    {
      keyForI18n: 'userStreetPlaceholder',
      id: 'guestUserCompanyStreet',
      type: 'text',
    },
    {
      keyForI18n: 'userZIPPlaceholder',
      id: 'guestUserCompanyZIP',
      type: 'text',
    },
    {
      keyForI18n: 'userCityPlaceholder',
      id: 'guestUserCompanyCity',
      type: 'text',
    },
    {
      keyForI18n: 'userPhonePlaceholder',
      id: 'guestUserCompanyPhone',
      type: 'text',
    },
  ],
  accountPage: {
    accountCompanyForm: [
      {
        keyForI18n: 'userCompanyPlaceholder',
        id: 'userCompany',
        type: 'text',
      },
      {
        keyForI18n: 'userStreetPlaceholder',
        id: 'userStreet',
        type: 'text',
      },
      {
        keyForI18n: 'userZIPPlaceholder',
        id: 'userZIP',
        type: 'text',
      },
      {
        keyForI18n: 'userCityPlaceholder',
        id: 'userCity',
        type: 'text',
      },
      {
        keyForI18n: 'userPhonePlaceholder',
        id: 'userPhone',
        type: 'text',
      },
    ],
    accountUserForm: [
      {
        keyForI18n: 'userFirstNamePlaceholder',
        id: 'userFirstName',
        type: 'text',
      },
      {
        keyForI18n: 'userLastNamePlaceholder',
        id: 'userLastName',
        type: 'text',
      },
      {
        keyForI18n: 'userEmailPlaceholder',
        id: 'userEmail',
        type: 'email',
        iconName: 'mail',
      },
      {
        keyForI18n: 'userPasswordPlaceholder',
        id: 'userPassword',
        type: 'password',
        iconName: 'lock',
      },
      {
        keyForI18n: 'repeatPasswordPlaceholder',
        id: 'userRepeatPassword',
        type: 'password',
        iconName: 'lock',
      },
    ],
    usersInCompany: [
      {
        name: 'stefanbrauchli@crunch.ch',
        role: 'admin',
      },
      {
        name: 'danielmuster@crunch.ch',
      },
      {
        name: 'lukasmueller@crunch.ch',
      },
    ],
  },
  registrationNewCompanyUserForm: [
    {
      keyForI18n: 'userEmailPlaceholder',
      id: 'userEmail',
      type: 'email',
      iconName: 'mail',
    },
    {
      keyForI18n: 'userPasswordPlaceholder',
      id: 'userPassword',
      type: 'password',
      iconName: 'lock',
    },
    {
      keyForI18n: 'repeatPasswordPlaceholder',
      id: 'userRepeatPassword',
      type: 'password',
      iconName: 'lock',
    },
    {
      keyForI18n: 'userCompanyPlaceholder',
      id: 'companyName',
      type: 'text',
      disabled: true,
    },
    {
      keyForI18n: 'userFirstNamePlaceholder',
      id: 'userFirstName',
      type: 'text',
    },
    {
      keyForI18n: 'userLastNamePlaceholder',
      id: 'userLastName',
      type: 'text',
    },
    {
      keyForI18n: 'userGenderPlaceholder',
      id: 'userGender',
      isRequired: true,
      options: [
        { value: 'male', label: 'userGenderMale' },
        { value: 'female', label: 'userGenderFemale' },
        { value: 'div', label: 'userGenderDiv' },
      ],
    },
    {
      keyForI18n: 'userFunctionPlaceholder',
      id: 'userFunction',
      isRequired: false,
      options: [
        { value: 'owner', label: 'userFunctionOwner' },
        { value: 'co-owner', label: 'userFunctionCoOwner' },
        { value: 'management', label: 'userFunctionManagement' },
        {
          value: 'executive management',
          label: 'userFunctionExecutiveManagement',
        },
        {
          value: 'purchasing / procurement',
          label: 'userFunctionPurchasingProcurement',
        },
        { value: 'sales', label: 'userFunctionSales' },
        { value: 'project management', label: 'userFunctionProjectManagement' },
        {
          value: 'calculation / costing',
          label: 'userFunctionCalculationCosting',
        },
        {
          value: 'administrative processing',
          label: 'userFunctionAdministrativeProcessing',
        },
        { value: 'other', label: 'userFunctionOther' },
      ],
    },
  ],
  registrationNewUserForm: [
    {
      keyForI18n: 'userEmailPlaceholder',
      id: 'userEmail',
      type: 'email',
      iconName: 'mail',
      isRequired: true,
    },
    {
      keyForI18n: 'userPasswordPlaceholder',
      id: 'userPassword',
      type: 'password',
      iconName: 'lock',
      isRequired: true,
    },
    {
      keyForI18n: 'repeatPasswordPlaceholder',
      id: 'userRepeatPassword',
      type: 'password',
      iconName: 'lock',
      isRequired: true,
    },
    {
      keyForI18n: 'userGenderPlaceholder',
      id: 'userGender',
      isRequired: true,
      options: [
        { value: 'male', label: 'userGenderMale' },
        { value: 'female', label: 'userGenderFemale' },
        { value: 'div', label: 'userGenderDiv' },
      ],
    },
    {
      keyForI18n: 'userCompanyPlaceholder',
      id: 'userCompanyName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userFirstNamePlaceholder',
      id: 'userFirstName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userLastNamePlaceholder',
      id: 'userLastName',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userFunctionPlaceholder',
      id: 'userFunction',
      isRequired: false,
      options: [
        { value: 'owner', label: 'userFunctionOwner' },
        { value: 'co-owner', label: 'userFunctionCoOwner' },
        { value: 'management', label: 'userFunctionManagement' },
        {
          value: 'executive management',
          label: 'userFunctionExecutiveManagement',
        },
        {
          value: 'purchasing / procurement',
          label: 'userFunctionPurchasingProcurement',
        },
        { value: 'sales', label: 'userFunctionSales' },
        { value: 'project management', label: 'userFunctionProjectManagement' },
        {
          value: 'calculation / costing',
          label: 'userFunctionCalculationCosting',
        },
        {
          value: 'administrative processing',
          label: 'userFunctionAdministrativeProcessing',
        },
        { value: 'other', label: 'userFunctionOther' },
      ],
    },
    {
      keyForI18n: 'userStreetPlaceholder',
      id: 'userCompanyStreet',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userZIPPlaceholder',
      id: 'userCompanyZIP',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userCityPlaceholder',
      id: 'userCompanyCity',
      type: 'text',
      isRequired: true,
    },
    {
      keyForI18n: 'userPhonePlaceholder',
      id: 'userCompanyPhone',
      type: 'tel',
      isRequired: true,
    },
  ],
  loginForm: [
    {
      keyForI18n: 'baseFormPlaceholders:userEmailPlaceholder',
      id: 'userLoginEmail',
      type: 'email',
      iconName: 'mail',
    },
    {
      keyForI18n: 'baseFormPlaceholders:userPasswordPlaceholder',
      id: 'userLoginPassword',
      type: 'password',
      iconName: 'lock',
    },
  ],
  defaultGlassPositionsData: [
    {
      row: [
        {
          name: 'length',
          value: '',
        },
        {
          name: 'width',
          value: '',
        },
        {
          name: 'quantity',
          value: '',
        },
        {
          name: 'form',
          value: 'unchecked',
        },
        {
          name: 'cantonalInsurance',
          value: '',
        },
        {
          name: 'notes',
          value: '',
        },
      ],
    },
  ],
};

export default jsonAppData;
