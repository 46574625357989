import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { BlockHeading } from "components/BlockHeading";
import { Formik } from "formik";
import {
	emailValidation,
	nameValidation,
	validatePassword,
	validateConfirmPassword,
	fieldValidation,
	generateUniqueId,
} from "utils";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { firebaseAuth } from "utils/firebase";
import { USERS_API, COMPANIES_API } from "api";
import { PROMISE_STATES } from "utils/constant";
import { ButtonSubmit } from "components/Buttons";
import { UiContext } from "context/ui";
import ToastifyHandler from "utils/ToastifyHandler";
import jsonAppData from "utils/jsonAppData";
import FormField from "./FormField";

const RegistrationForm = ({ formInPopup, toggleLogged }) => {
	const { t } = useTranslation(["baseFormPlaceholders", 'firebaseErrorMessages']);
	const { showPopupByKey } = useContext(UiContext);
	const formikRef = useRef();

	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.default);
	const notificationsHandler = useRef(new ToastifyHandler());

	const validate = {
		userEmail: (email) => emailValidation(email),
		userPassword: (password) => validatePassword(t("userPasswordPlaceholder"), password),
		userRepeatPassword: (repeatPasswordValue) => validateConfirmPassword(
			formikRef.current.values.userPassword,
			repeatPasswordValue,
		),
		userCompanyName: (value) => fieldValidation(t("userCompanyPlaceholder"), value),
		userFirstName: (firstName) => nameValidation(t("userFirstNamePlaceholder"), firstName),
		userLastName: (lastName) => nameValidation(t("userLastNamePlaceholder"), lastName),
		userCompanyStreet: (value) => fieldValidation(t("userStreetPlaceholder"), value),
		userCompanyZIP: (value) => fieldValidation(t("userZIPPlaceholder"), value),
		userCompanyCity: (value) => fieldValidation(t("userCityPlaceholder"), value),
		userCompanyPhone: (value) => fieldValidation(t("userPhonePlaceholder"), value),
		userGender: (value) => fieldValidation(t("userGenderPlaceholder"), value),
		userFunction: () => {
			return null;
		},
	};

	const initialValues = {
		userEmail: "",
		userPassword: "",
		userRepeatPassword: "",
		userCompanyName: "",
		userFirstName: "",
		userLastName: "",
		userCompanyStreet: "",
		userCompanyZIP: "",
		userCompanyCity: "",
		userCompanyPhone: "",
		userGender: "",
		userFunction: "",
	};

	const createNewUser = (
		{
			userEmail, userFirstName, userLastName, userGender, userFunction,
		},
		companyId,
	) => {
		return {
			email: userEmail,
			companyId,
			name: userFirstName,
			lastName: userLastName,
			role: "companyAdmin",
			gender: userGender,
			function: userFunction,
		};
	};

	const createNewCompany = (
		{
			userEmail,
			userCompanyName,
			userCompanyStreet,
			userCompanyZIP,
			userCompanyCity,
			userCompanyPhone,
		},
		companyId,
	) => {
		return {
			id: companyId,
			name: userCompanyName,
			address: userCompanyStreet,
			zip: userCompanyZIP,
			city: userCompanyCity,
			tel: userCompanyPhone,
			creatorCompanyEmail: userEmail,
		};
	};

	const showLoginPopup = () => {
		if (formInPopup) {
			showPopupByKey("login");
		}
	};

	const onFormSubmit = async (values) => {
		const companyId = generateUniqueId();

		const { userPassword } = values;

		notificationsHandler.current.pending(
			t("notifications:accountCreationInProgress"),
		);

		try {
			setLoadingStatus(PROMISE_STATES.pending);

			const newUser = createNewUser(values, companyId);

			const authResult = await createUserWithEmailAndPassword(
				firebaseAuth,
				newUser.email,
				userPassword,
			);
			await updateProfile(authResult.user, {
				displayName: `${newUser.name} ${newUser.lastName}`,
			});

			const { uid } = authResult.user;

			const newCompany = createNewCompany(values, companyId);

			await USERS_API.addNewUser(newUser, uid);
			await COMPANIES_API.addNewCompany(newCompany);

			notificationsHandler.current.success(
				t("notifications:accountCreatedSuccessfully"),
			);

			setLoadingStatus(PROMISE_STATES.fulfilled);

			if (formInPopup) {
				showPopupByKey();
			}
		} catch (error) {
			const { code } = error;
			notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
			setLoadingStatus(PROMISE_STATES.rejected);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			innerRef={formikRef}
			mapPropsToValues={() => {
				return {
					...initialValues,
				};
			}}
			validate={(values) => Object.keys(values).reduce((errors, field) => {
				const error = validate[field](values[field]);
				return {
					...errors,
					...(error && { [field]: error }),
				};
			}, {})}
			onSubmit={(values) => {
				onFormSubmit(values);
			}}
			validateOnChange={false}
		>
			{({
				handleSubmit,
				handleBlur,
				handleChange,
				values,
				errors,
				touched,
				setFieldTouched,
				setFieldValue,
			}) => (
				<div className="registration_form">
					{!formInPopup && <BlockHeading title={t("registrationForm:title")} />}
					<form
						className="registration_form__in"
						onSubmit={handleSubmit}
						noValidate
					>
						{formInPopup && (
							<BlockHeading title={t("registrationForm:title")} />
						)}
						<ul className="form_fields">
							{jsonAppData.registrationNewUserForm.map(
								({
									keyForI18n, id, type, iconName, isRequired, options,
								}) => {
									const i8nLabel = t(`${keyForI18n}`);
									let i8nOptions;

									if (options) {
										i8nOptions = options.map((option) => ({
											...option,
											label: t(`${option.label}`),
										}));
									}
									const placeholder = isRequired ? `${i8nLabel} *` : i8nLabel;

									return (
										<li className="form_fields__item" key={id}>
											<FormField
												id={id}
												placeholder={placeholder}
												name={id}
												type={type}
												required={isRequired}
												iconName={iconName}
												values={values}
												handleBlur={handleBlur}
												handleChange={handleChange}
												options={i8nOptions}
												setFieldTouched={setFieldTouched}
												setFieldValue={setFieldValue}
											/>
											<div className="form_fields__error">
												{touched[id] && errors[id]}
											</div>
										</li>
									);
								},
							)}
						</ul>
						<div className="registration_form__button">
							<ButtonSubmit
								buttonText={t("registrationForm:registerButton")}
								isDisabled={loadingStatus === PROMISE_STATES.pending}
							/>
						</div>
						<div className="registration_form__note">
							{t("registrationForm:alreadyHaveAccount")}
							<a
								href="#"
								onClick={() => (formInPopup ? showLoginPopup() : toggleLogged())}
							>
								{t("logIn")}
							</a>
						</div>
					</form>
				</div>
			)}
		</Formik>
	);
};

export default RegistrationForm;
