import React, { useContext } from 'react';
import { signOut } from 'firebase/auth';
import { firebaseAuth } from 'utils/firebase';

import classNames from 'classnames';
import { UiContext } from 'context/ui';
import { AuthContext } from 'context/auth';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigurationDataContext } from 'context/configuration';
import { toast } from 'react-toastify';
import { ReactComponent as CloseIcon } from 'i/icons/close_circle.svg';
import { USER_ROLES } from 'utils/constant';
import PageNavigationButton from './PageNavigationButton';

const PageNavigation = ({
	showOnlyOnMobile,
	showOnlyOnDesktop,
	hasBottomOffset,
	showConfiguration,
}) => {
	const { currentUser, currentUserRole } = useContext(AuthContext);
	const { showPopupByKey } = useContext(UiContext);
	const { setConfiguration } = useContext(ConfigurationDataContext);
	const { t } = useTranslation('loginForm');
	const navigate = useNavigate();
	const location = useLocation();

	const pageNavClasses = classNames('page_navigation__list end_mod', {
		desktop_mod: showOnlyOnDesktop,
		mob_mod: showOnlyOnMobile,
		offset_mod: hasBottomOffset,
	});

	const goToPageHandler = url => {
		if (!url) return;

		navigate(url);
	};

	const showMyCodePopup = () => {
		showPopupByKey('configurationCode');
	};

	const showLoginPopup = () => {
		showPopupByKey('login');
	};

	const logoutHandler = () => {
		signOut(firebaseAuth).then(() => {
			toast.success(t('successfullyLoggedOut'), {
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
			});

			navigate('/');

			setConfiguration(null);
		}).catch((error) => {
			toast.error(error.message, {
				autoClose: 2000,
				hideProgressBar: true,
				closeOnClick: false,
				closeButton: false,
				icon: <CloseIcon />,
			});
		});
	};

	return (
		<div className="page_navigation">
			<ul className={pageNavClasses}>
				{showConfiguration && 	(
					<li className="page_navigation__item">
						<PageNavigationButton
							url="/"
							i18nLabel="configurator"
							onClickHandler={goToPageHandler}
						/>
					</li>
				)}
				{!currentUser ? (
					<li className="page_navigation__item">
						<PageNavigationButton i18nLabel="login" onClickHandler={showLoginPopup} />
					</li>
				) : null}
				{
					location.pathname !== '/dashboard'
						&& location.pathname !== '/account'
						&& !location.pathname.includes('/preview')
						&& !currentUser
						? (
							<li className="page_navigation__item">
								<PageNavigationButton i18nLabel="myCode" onClickHandler={showMyCodePopup} />
							</li>
						) : null
				}
				{currentUser ? (
					<>
						{location.pathname !== '/dashboard' && location.pathname !== '/admin-dashboard' ? (
							<li className="page_navigation__item">
								<PageNavigationButton
									url="/dashboard"
									i18nLabel="dashboard"
									onClickHandler={goToPageHandler}
								/>
							</li>
						) : null}
						{location.pathname === '/dashboard' || location.pathname === '/admin-dashboard' ? (
							<li className="page_navigation__item">
								<PageNavigationButton
									url="/account"
									i18nLabel="account"
									onClickHandler={goToPageHandler}
								/>
							</li>
						) : null}
						{
							currentUserRole
								&& (currentUserRole === USER_ROLES.admin || currentUserRole === USER_ROLES.superAdmin)
								&& location.pathname !== '/admin-dashboard'
								? (
									<li className="page_navigation__item">
										<PageNavigationButton
											url="/admin-dashboard"
											i18nLabel="adminDashboard"
											onClickHandler={goToPageHandler}
										/>
									</li>
								) : null
						}
						<li className="page_navigation__item">
							<PageNavigationButton i18nLabel="logout" onClickHandler={logoutHandler} />
						</li>
					</>
				) : null}
			</ul>
		</div>
	);
};

export default PageNavigation;
