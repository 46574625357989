import React, { useContext, useRef, useState } from 'react';
import {
	getStorage,
	ref,
	uploadBytes,
	getDownloadURL,
} from 'firebase/storage';
import { ConfigurationDataContext } from 'context/configuration';
import {
	checkFileType,
	formatFileSize,
	generateUniqueId,
} from 'utils';
import { useTranslation } from 'react-i18next';
import {
	FIREBASE_CONFIGURATIONS_STORAGE_PATH,
	PROMISE_STATES,
} from 'utils/constant';
import { Spinner } from 'components/Spinner';
import ToastifyHandler from 'utils/ToastifyHandler';

const UploadedDocumentsArea = ({
	uploadedDocuments,
}) => {
	const { t } = useTranslation(['notifications', 'firebaseErrorMessages']);
	const { configuration } = useContext(ConfigurationDataContext);
	const [loadingFileStatus, setLoadingFileStatus] = useState(PROMISE_STATES.default);
	const notificationHandler = useRef(new ToastifyHandler());

	const addNewFileToConfiguration = ({
		fileName,
		fileUrl,
		fileSize,
	}) => {
		const transacted = uploadedDocuments.transact();

		transacted.push({
			name: fileName,
			size: fileSize,
			url: fileUrl,
			id: generateUniqueId(),
		});

		uploadedDocuments.run();
	};

	const onInputFileChange = async (e) => {
		if (loadingFileStatus === PROMISE_STATES.pending) {
			return;
		}

		const file = e.target.files[0];
		if (!file) {
			return;
		}

		const isFileTypeValid = checkFileType(file.type);
		const isFileSizeValid = file.size / 1024 / 1024; // in MiB

		if (isFileSizeValid > 10) {
			alert(t('errorLargeFileSizeFmt').replace('{}', '10MB'));
			return;
		}

		if (isFileTypeValid) {
			const configurationCode = configuration.code;
			const fileName = file.name;
			const fileSize = formatFileSize(file.size);

			const storageRef = getStorage();

			const fileRef = ref(storageRef, `${FIREBASE_CONFIGURATIONS_STORAGE_PATH}/${configurationCode}/${fileName}`);

			setLoadingFileStatus(PROMISE_STATES.pending);

			try {
				await uploadBytes(fileRef, file);
				const url = await getDownloadURL(fileRef);

				addNewFileToConfiguration({
					fileName,
					fileSize,
					fileUrl: url,
				});

				setLoadingFileStatus(PROMISE_STATES.fulfilled);
			} catch (error) {
				const { code } = error;
				notificationHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
				setLoadingFileStatus(PROMISE_STATES.rejected);
			}
		} else {
			window.alert(t('errorAddingFileWrongFormat'));
		}
	};

	return (
		<form className="uploaded_doc_area">
			<label className="uploaded_doc_area__label" htmlFor="uploadDocumentArea">
				<input
					className="uploaded_doc_area__input"
					type="file"
					id="uploadDocumentArea"
					onChange={onInputFileChange}
					disabled={loadingFileStatus === PROMISE_STATES.pending}
					accept="
						.pdf,
						image/jpeg,
						image/gif,
						image/png,
					"
				/>
				{loadingFileStatus === PROMISE_STATES.pending ? (
					<div className="uploaded_doc_area__spinner">
						<Spinner darkTheme />
					</div>
				) : (
					<div className="uploaded_doc_area__in">
						<div className="uploaded_doc_area__title">{t('uploadDocAreaDragAndDrop')}</div>
						<div className="uploaded_doc_area__subtitle">{t('uploadDocAreaOr')}</div>
						<div className="uploaded_doc_area__link">{t('uploadDocAreaBrowseFiles')}</div>
						<div className="uploaded_doc_area__size_note">{t('uploadDocAreaMaxFileSizeFmt').replace('{}', '10MB')}</div>
					</div>
				)}
			</label>
		</form>
	);
};

export default UploadedDocumentsArea;
