import React, {
	useState,
	useEffect,
	useRef,
	useContext,
	useMemo,
} from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import 'styles/blocks/configurator_page.scss';
import 'styles/plugins/_slick.scss';

import { AdditionalPageNavigation } from 'components/AdditionalPageNavigation';
import {
	GlassProductSidebar,
	GlassProductCanvas,
	GlassProductSelector,
	GlassProductSliderControl,
} from 'components/GlassProduct';
import { TABLET_MEDIA_POINT } from 'utils/constant';

import { Api } from 'components/ThreeJs/assets/index.module';
import { Filters } from 'components/ThreeJs/assets/filter/main';
import { UiContext } from 'context/ui';
import { useLocation } from 'react-router';
import { AuthContext } from 'context/auth';
import { getParameterByName } from 'utils';
import { useTranslation } from 'react-i18next';
import GlassConfigUtil from 'components/GlassConfig/GlassConfigUtil';
import HighlightComponent from 'components/FX/HighlightComponent';
import WarningUIElement from 'components/ThreeJs/assets/filter/elementsUI/WarningUIElement';

const HomePage = ({ key }) => {
	const location = useLocation();

	const sectionClassNames = classNames('section full_mod section--home_page_mod', {
		'section--home_page_hidden_state': location.pathname !== '/',
	});

	const [bottomFilter, setBottomFilter] = useState({});
	const [rightFilter, setRightFilter] = useState({});
	const uiContext = useContext(UiContext);
	const { showPopupByKey } = uiContext;
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [flatGlassStructure, setFlatGlassStructure] = useState(null);
	const [screenshotDataUrl, setScreenshotDataUrl] = useState(null);
	const [configuratorLoaded, setConfiguratorLoaded] = useState(false);

	const viewApiRef = useRef(null);
	const uiHandlerRef = useRef(null);

	const onResize = () => {
		setWindowWidth(window.innerWidth);
	};

	const splitObjects = filterObject => {
		let bottom = {
			manufacturer: filterObject.manufacturer,
			fireResistanceClass: filterObject.fireResistanceClass,
			structure: filterObject.structure,
			insulation: filterObject.insulation,
			application: filterObject.application,
			whiteGlass: filterObject.whiteGlass,
			resistanceClass: filterObject.resistanceClass,
			buttJoints: filterObject.buttJoints,
			sound: filterObject.sound,
		};

		let right = {
			fireResistant: filterObject.fireResistant,
			spacer1: filterObject.spacer1,
			gas1: filterObject.gas1,
			middleGlass: filterObject.middleGlass,
			spacer2: filterObject.spacer2,
			gas2: filterObject.gas2,
			outsideGlass: filterObject.outsideGlass,
		};

		return { bottom, right };
	};

	window.loaded = false;

	const bottomKeys = Object.keys(bottomFilter);
	const rightKeys = Object.keys(rightFilter);

	const sliderSettings = {
		dots: false,
		infinite: false,
		variableWidth: true,
		arrows: true,
		draggable: false,
		swipe: false,
		nextArrow: <GlassProductSliderControl type="next" />,
		prevArrow: <GlassProductSliderControl type="prev" />,
	};

	const handlerUrlParameter = () => {
		// Get the action to complete.
		const mode = getParameterByName('mode');

		if (mode === 'resetPassword') {
			showPopupByKey('resetPassword');
		}
	};

	function useCustomTranslation(namespace) {
		const { t, i18n } = useTranslation(namespace);
		return { t, i18n };
	}

	const { t: tVisualizerComponent, i18n } = useCustomTranslation('visualizerComponent');
	const { t: tGlassCharacteristics } = useCustomTranslation('glassCharacteristics');

	const checkIsConfiguratorLoaded = () => {
		if (window.glass_ViewApi.areAllModelsLoaded()) {
			const langLowerCase = i18n.language.toLowerCase();
			const lang = (langLowerCase === 'en' ? 'en_US' : langLowerCase);
			uiHandlerRef.current.i18n.setLanguage(lang);
			setConfiguratorLoaded(true);
			uiContext.setIsModelLoaded(true);
		} else {
			setTimeout(checkIsConfiguratorLoaded, 200);
		}
	};

	// get glass screenshot
	useEffect(() => {
		if (!flatGlassStructure || !uiHandlerRef.current.isDbLoaded || !configuratorLoaded) {
			return;
		}

		window.glass_ViewApi.take_screenshot((blob) => {
			if (blob) {
				// Create a data URL from the Blob
				const reader = new FileReader();
				reader.readAsDataURL(blob);
				reader.onloadend = () => {
					setScreenshotDataUrl(reader.result);
				};
			} else {
				setScreenshotDataUrl(null);
			}
		});
	}, [flatGlassStructure, configuratorLoaded]);

	const glassConfig = useMemo(() => {
		if (screenshotDataUrl && flatGlassStructure) {
			let lang = i18n.language === 'en' ? 'en_US' : i18n.language;
			let translatedGlassConfig = GlassConfigUtil.convertFlatGlassStructureToGlassConfig(flatGlassStructure, uiHandlerRef, lang);
			if (translatedGlassConfig) {
				translatedGlassConfig.screenshot = screenshotDataUrl;
			}
			return translatedGlassConfig;
		}
		return {};
	}, [screenshotDataUrl, flatGlassStructure, i18n.language]);

	useEffect(() => {
		if (!uiHandlerRef.current) {
			return;
		}
		const langLowerCase = i18n.language.toLowerCase();
		const lang = (langLowerCase === 'en' ? 'en_US' : langLowerCase);

		uiHandlerRef.current.i18n.setLanguage(lang);

		if (window.filters_handler) {
			window.filters_handler.ui.i18n.setLanguage(lang);
		}
	}, [i18n.language]);

	useEffect(() => {
		window.addEventListener('resize', onResize);

		setTimeout(() => {
			if (!window.loaded) {
				viewApiRef.current = Api;

				viewApiRef.current.load({
					initial_cam_pos: {
						zoom: 8, orientation: 120, tilt: 10, position: { x: 1.0, y: 1.0, z: -1.0 },
					},
					invert_mouse: true,
					lang: {
						MeasurementArrows_label_format: tVisualizerComponent('measurementArrowsLabelFormat'),
					},
				});

				window.viewApi = viewApiRef.current;
				window.loaded = true;

				window.filters_handler = new Filters({ translation: tGlassCharacteristics, i18n }, setFlatGlassStructure);
				uiHandlerRef.current = window.filters_handler.ui;
				uiHandlerRef.current.setUiContext(uiContext);
				setTimeout(checkIsConfiguratorLoaded, 500);
			}
		}, 1000);
		handlerUrlParameter();

		const { bottom, right } = splitObjects({});
		setBottomFilter(bottom);
		setRightFilter(right);
	}, []);

	return (
		<section id={key} className={sectionClassNames}>
			<div className="section__in">
				<div className="glass_product">
					<div className="glass_product__nav">
						<AdditionalPageNavigation />
					</div>
					<div className="glass_product__row">
						<GlassProductCanvas />

						<GlassProductSidebar
							rightKeys={rightKeys}
							glassConfig={glassConfig}
							uiHandlerRef={uiHandlerRef}
						/>
					</div>
				</div>
			</div>
			<div className="glass_product_bottom">
				<div className="glass_product_category">
					{windowWidth >= TABLET_MEDIA_POINT ? (
						<HighlightComponent delay={2000}>
							<div className="instruction_side_wrap">
								<div className="instruction instruction_bottom">
									<strong>1.</strong>
									{tGlassCharacteristics('commonConfigurationAction:firstSelectProperties')}
								</div>
								<div className="glass_product_category_list glass_product_category_list--style_mod">
									{bottomKeys.map(f => (
										<GlassProductSelector name={f} uiHandlerRef={uiHandlerRef} wrapperClass="glass_product_category_item_v2" labelClass="glass_product_category_label_v2" />
									))}
								</div>
							</div>
						</HighlightComponent>
					) : (
						<Slider className="glass_product_category_list" {...sliderSettings}>
							<div className="instruction instruction_bottom">
								{tGlassCharacteristics(
									"commonConfigurationAction:firstSelectProperties",
								)}
							</div>
							{bottomKeys.map((f) => (<GlassProductSelector name={f} uiHandlerRef={uiHandlerRef} />))}
						</Slider>
					)}
				</div>
				<WarningUIElement uiHandlerRef={uiHandlerRef} />
			</div>
		</section>
	);
};

export default HomePage;
