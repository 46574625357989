import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

import { updateUserData } from 'domain/user';
import { AuthContext } from 'context/auth';
import { USERS_API } from 'api';
import FormCheckbox from './FormCheckbox';

const HideStructureChangeCheckbox = ({ title }) => {
	const { currentUser, currentUserAdditionalData, setCurrentUserAdditionalData } = useContext(AuthContext);
	const [hideMessageStatus, setHideMessageStatus] = useState(null);
	const uiHandlerRef = useRef(null);

	useEffect(() => {
		if (currentUserAdditionalData) {
			setHideMessageStatus(currentUserAdditionalData.hideStructureChangeMessage);
			if (!uiHandlerRef.current && window.filters_handler && window.filters_handler.ui) {
				uiHandlerRef.current = window.filters_handler.ui;
			}
			if (uiHandlerRef.current) {
				uiHandlerRef.current.hideStructureChangeMessage = true;
			}
		}
	}, [currentUserAdditionalData]);

	useEffect(() => {
		uiHandlerRef.current = window.filters_handler.ui;
	}, []);

	const hideMessageHandler = async () => {
		const updatedUserData = updateUserData(currentUserAdditionalData, !hideMessageStatus);
		await USERS_API.updateUserData(currentUser.uid, {
			...updatedUserData,
		});
		setCurrentUserAdditionalData(updatedUserData);
		setHideMessageStatus(!hideMessageStatus);
		if (uiHandlerRef.current) {
			uiHandlerRef.current.hideStructureChangeMessage = true;
		}
	};

	return (
		<FormCheckbox
			id="HideStructureChangeCheckbox"
			name="HideStructureChangeCheckbox"
			title={title}
			isUppercase
			defaultChecked={hideMessageStatus}
			onChange={hideMessageHandler}
		/>
	);
};

export default HideStructureChangeCheckbox;
