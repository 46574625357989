/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import 'styles/blocks/sideOption.scss';
import UIGenericGlass from 'components/ThreeJs/assets/filter/glassUI/UIGenericGlass';
import SpacerUIElement from 'components/ThreeJs/assets/filter/elementsUI/SpacerUIElement';
import GasUIElement from 'components/ThreeJs/assets/filter/elementsUI/GasUIElement';
import camelToDash from './camelToDash';

const GlassProductSideOption = ({
	name,
	uiHandlerRef,
}) => {
	const { t } = useTranslation('configurationCategory');
	const dashkey = name ? camelToDash(name) : '';

	return (
		<div className="glass_product_selection">
			<label className="glass_product_selection_title" htmlFor={dashkey.concat('_input')}>
				{t(name)}
			</label>
			{
				(dashkey === 'fire-resistant' || dashkey === 'middle-glass' || dashkey === 'outside-glass') ? (
					<UIGenericGlass isDisabled id={dashkey} uiHandlerRef={uiHandlerRef} defaultVisible={dashkey !== 'fire-resistant'} />
				) : null
			}
			{
				(dashkey === 'gas1' || dashkey === 'gas2') ? (
					<GasUIElement id={dashkey} isDisabled={dashkey === 'gas2'} uiHandlerRef={uiHandlerRef} />
				) : null
			}
			{
				(dashkey === 'spacer1' || dashkey === 'spacer2') ? (
					<SpacerUIElement id={dashkey} isDisabled={dashkey === 'spacer2'} uiHandlerRef={uiHandlerRef} />
				) : null
			}
		</div>
	);
};

export default GlassProductSideOption;
