import AbstractFilter from './AbstractFilter.js'
import SoundDampening from "../Glass/SoundDampeningValues";

export default class SoundFilter extends AbstractFilter {

	constructor() {
		super("SoundFilter", false);

		this.i18n_prefix = "sound_";
		this.filterOptions = {
			// "45 (-1; -3)": "format",
			"NPD": "format_npd",
		}
	}

	setSoundValues(values) {
		// Object.keys(this.filterOptions).forEach((key) => delete this.filterOptions[key]);
		values.forEach(v => (this.filterOptions[v] = "format"));
	}

	applyFilter(dbView) {
		super.applyFilter(dbView);
		if (this.selectedFilter === null || this.selectedFilter === 'NPD') {
			return;
		}

		// Comparison needs to be as follows, but filtering has to happen all over the glass-structure (glasses and spacers)
		// if (SoundDampening.getSoundValue(dbView['frg'][i]) == this.selectedFilter) {

		for (var i = 0; i < dbView['frg'].length; ++i) {
			if (dbView['frg'][i] == this.selectedFilter) {
				dbView['frgFiltered'].push(dbView['frg'].splice(i, 1)[0]);
				i -= 1;
			}
		}
	}
}
