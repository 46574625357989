import React from 'react';

// import { ReactComponent as KeyIcon } from 'i/icons/key.svg';
// import { ReactComponent as ConfigCodeIcon } from 'i/icons/config_code.svg';
// import { ReactComponent as DocumentIcon } from 'i/icons/document.svg';
// import { ReactComponent as OrdersIcon } from 'i/icons/orders.svg';
// import { ReactComponent as PlusIcon } from 'i/icons/plus.svg';
// import { ReactComponent as UserIcon } from 'i/icons/user.svg';
// import { ReactComponent as DubleArrowIcon } from 'i/icons/duble_arrow.svg';

import ConfigCodeIcon from 'i/icons/config_code.png';
import DubleArrowIcon from 'i/icons/duble_arrow.png';
import GearIcon from 'i/icons/gear.png';
import DocumentIcon from 'i/icons/document.png';
import EqualsIcon from 'i/icons/equals.png';

import {
	Text,
	View,
	Image,
} from '@react-pdf/renderer';

import styles from './styles';

const PdfBlockHeading = ({
	title = 'Title',
	iconName,
	blockIndex,
}) => {
	const renderHeadingIcon = () => {
		if (iconName === 'config_code') {
			return <Image src={ConfigCodeIcon} style={styles.blockHeadingIconImage} />;
		}

		if (iconName === 'duble_arrow') {
			return <Image src={DubleArrowIcon} style={styles.blockHeadingIconImage} />;
		}

		if (iconName === 'document') {
			return <Image src={DocumentIcon} style={styles.blockHeadingIconImage} />;
		}

		if (iconName === 'gear') {
			return <Image src={GearIcon} style={styles.blockHeadingIconImage} />;
		}

		if (iconName === 'equals') {
			return <Image src={EqualsIcon} style={styles.blockHeadingIconImage} />;
		}

		return null;
	};

	return (
		<View style={styles.blockHeading}>
			<View style={styles.blockHeadingIcon}>
				{blockIndex ? (
					<Text style={styles.blockHeadingIndex}>{`${blockIndex}.`}</Text>
				) : (
					renderHeadingIcon()
				)}
			</View>
			<Text style={styles.blockHeadingTitle}>{title}</Text>
		</View>
	);
};

export default PdfBlockHeading;
