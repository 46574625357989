import React, { useContext, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'i/icons/close.svg';
import { CONFIGURATIONS_API } from 'api';
import { BlockHeading } from 'components/BlockHeading';
import { resetFilters } from 'components/ThreeJs/assets/main';

import { UiContext } from 'context/ui';
import { clearAllBrowserUrlParameter } from 'utils';
import { ConfigurationDataContext } from 'context/configuration';
import { AuthContext } from 'context/auth';
import { useNavigate } from 'react-router-dom';
import ToastifyHandler from 'utils/ToastifyHandler';
import { PROMISE_STATES } from 'utils/constant';

const ResetConfigurationPopup = ({
	isOpen,
}) => {
	const { t } = useTranslation(['resetConfigurationForm', 'firebaseErrorMessages']);
	const { showPopupByKey } = useContext(UiContext);
	const { configuration, setConfiguration } = useContext(ConfigurationDataContext);
	const { guestUser, setGuestUser } = useContext(AuthContext);
	const navigate = useNavigate();
	const notificationsHandler = useRef(new ToastifyHandler());
	const [deletionStatus, setDeletionStatus] = useState(PROMISE_STATES.default);

	const closePopup = () => {
		showPopupByKey();
	};

	const onPopupEntering = () => {
		document.body.classList.add('filter_mod');
	};

	const onPopupExit = () => {
		document.body.classList.remove('filter_mod');

		clearAllBrowserUrlParameter();
	};

	const resetConfiguration = () => {
		setConfiguration(null);
		resetFilters();

		if (guestUser) {
			setGuestUser(null);
		}

		navigate('/');
		closePopup();
	};

	const deleteConfiguration = async () => {
		const { code } = configuration;

		notificationsHandler.current.pending(t('notifications:configurationDeletionInProgress'));
		setDeletionStatus(PROMISE_STATES.pending);

		try {
			await CONFIGURATIONS_API.deleteConfiguration(code);

			notificationsHandler.current.success(t('notifications:configurationWasDeletedSuccessfully'));
			setDeletionStatus(PROMISE_STATES.fulfilled);

			resetConfiguration();
		} catch (error) {
			notificationsHandler.current.rejected(t(error.code, { ns: 'firebaseErrorMessages' }));
			setDeletionStatus(PROMISE_STATES.rejected);
		}
	};

	return (
		<CSSTransition
			in={isOpen}
			timeout={{
				enter: 500,
				exit: 350,
			}}
			classNames="popup"
			onEntering={onPopupEntering}
			onExit={onPopupExit}
			mountOnEnter
			unmountOnExit
		>
			<div className="popup">
				<div className="popup_in reset_configuration_popup">
					<button className="popup_close" type="button" onClick={closePopup}>
						<CloseIcon className="icon icon-close size_mod" />
					</button>
					<div className="reset_configuration">
						<BlockHeading title={t('title')} iconName="pensil" />
						{configuration ? (
							<div className="reset_configuration_content">
								<div className="reset_configuration_text">{t('text')}</div>
								<div className="reset_configuration_code">
									<div className="reset_configuration_code_title">{t('configurationCode')}</div>
									<div className="reset_configuration_code_value">{configuration.code}</div>
								</div>
								<div className="reset_configuration_btn	">
									<button
										type="button"
										className="btn_base"
										onClick={resetConfiguration}
									>
										{t('resetButton')}
									</button>
								</div>
								<div className="reset_configuration_or">{t('or')}</div>
								<div className="reset_configuration_text_new">{t('textNew')}</div>
								<div className="reset_configuration_btn	">
									<button
										type="button"
										className="btn_base"
										onClick={deleteConfiguration}
										disabled={deletionStatus === PROMISE_STATES.pending}
									>
										{t('resetDeleteButton')}
									</button>
								</div>
							</div>
						) : ( // configuration does not exist, just show a button to reset the configurator
							<>
								<div className="popup_note">{t('configurationDoesNotExist')}</div>
								<div className="reset_configuration_btn	">
									<button
										type="button"
										className="btn_base"
										onClick={resetConfiguration}
									>
										{t('resetButton')}
									</button>
								</div>
							</>
						)}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

export default ResetConfigurationPopup;
