import { BlockHeading } from 'components/BlockHeading';
import React, {
	useContext, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import jsonAppData from 'utils/jsonAppData';
import { FormField } from 'components/Form';
import { useNavigate, useParams } from 'react-router-dom';
import { COMPANIES_API } from 'api';
import { PROMISE_STATES } from 'utils/constant';
import { Spinner } from 'components/Spinner';
import { Formik } from 'formik';
import {
	emailValidation,
	fieldValidation,
	nameValidation,
	validateConfirmPassword,
	validatePassword,
} from 'utils';
import ToastifyHandler from 'utils/ToastifyHandler';
import { AuthContext } from 'context/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const RegisterNewCompanyUserForm = ({
	limitContainerMod,
}) => {
	const { t } = useTranslation(['baseFormPlaceholders', 'firebaseErrorMessages']);
	const { currentUserAdditionalData } = useContext(AuthContext);
	const { registrationNewCompanyUserForm } = jsonAppData;
	const [loadingStatus, setLoadingStatus] = useState(PROMISE_STATES.pending);
	const [errorMessage, setErrorMessages] = useState(null);
	const { companyId } = useParams();
	const formikRef = useRef();
	const [initialFormValues, setInitialFormValues] = useState(null);
	const notificationsHandler = useRef(new ToastifyHandler());
	const navigate = useNavigate();

	const registrationFormClasses = classNames('registration_form', {
		'registration_form--limit-mod': limitContainerMod,
	});

	const validate = {
		userFirstName: firstName => nameValidation(t('userFirstNamePlaceholder'), firstName),
		userLastName: lastName => nameValidation(t('userLastNamePlaceholder'), lastName),
		companyName: name => null,
		userEmail: email => emailValidation(email),
		userPassword: password => validatePassword(t('userPasswordPlaceholder'), password),
		userRepeatPassword: repeatPasswordValue => validateConfirmPassword(formikRef.current.values.userPassword, repeatPasswordValue),
		userGender: (value) => fieldValidation(t("userGenderPlaceholder"), value),
		userFunction: () => {
			return null;
		},
	};

	const createInitialFormValues = (companyName) => {
		const initialValues = {
			userEmail: '',
			companyName,
			userPassword: '',
			userRepeatPassword: '',
			userFirstName: '',
			userLastName: '',
			userGender: '',
			userFunction: '',
		};

		setInitialFormValues(initialValues);
	};

	const getCompanyData = async () => {
		try {
			const companyRes = await COMPANIES_API.getCompany(companyId);

			if (!companyRes) return;

			if (companyRes) {
				setLoadingStatus(PROMISE_STATES.fulfilled);
				createInitialFormValues(companyRes.name);

				return;
			}

			setErrorMessages(t('notifications:companyNotFound'));
			setLoadingStatus(PROMISE_STATES.rejected);
		} catch (error) {
			setLoadingStatus(PROMISE_STATES.rejected);
			setErrorMessages(error.message);
		}
	};

	const createNewUserData = ({
		userEmail,
		userFirstName,
		userLastName,
		userPassword,
		userGender,
		userFunction,
	}) => {
		return {
			email: userEmail,
			name: userFirstName,
			lastName: userLastName,
			password: userPassword,
			gender: userGender,
			function: userFunction,
			companyId,
		};
	};

	useEffect(() => {
		if (companyId) {
			getCompanyData();
		}
	}, []);

	return (
		<div className={registrationFormClasses}>
			<BlockHeading
				title={t('registrationForm:title')}
			/>
			<div className="registration_form__in">
				{loadingStatus === PROMISE_STATES.fulfilled && initialFormValues ? (
					<Formik
						initialValues={initialFormValues}
						innerRef={formikRef}
						mapPropsToValues={() => {
							return {
								...initialFormValues,
							};
						}}
						validate={values => Object.keys(values).reduce((errors, field) => {
							const error = validate[field](values[field]);
							return {
								...errors,
								...(error && { [field]: error }),
							};
						}, {})}
						onSubmit={async (values) => {
							notificationsHandler.current.pending(t('notifications:accountCreationInProgress'));

							try {
								setLoadingStatus(PROMISE_STATES.pending);

								const functions = getFunctions();
								const createNewUserForCompany = httpsCallable(functions, 'createNewUserForCompany');

								const newUser = createNewUserData(values);

								await createNewUserForCompany(newUser);

								notificationsHandler.current.success(t('notifications:accountCreatedSuccessfully'));

								setLoadingStatus(PROMISE_STATES.fulfilled);
								navigate('/account');
							} catch (error) {
								const { code } = error;
								notificationsHandler.current.rejected(t(code, { ns: 'firebaseErrorMessages' }));
								setLoadingStatus(PROMISE_STATES.rejected);
							}
						}}
						validateOnChange={false}
					>
						{({
							handleSubmit,
							handleBlur,
							handleChange,
							values,
							errors,
							touched,
							setFieldTouched,
							setFieldValue,
						}) => (
							<form onSubmit={handleSubmit}>
								<ul className="form_fields">
									{registrationNewCompanyUserForm.map(({
										keyForI18n,
										id,
										type,
										iconName,
										disabled,
										options,
										isRequired,
									}) => {
										const i8nLabel = t(`${keyForI18n}`);
										let i8nOptions;

										if (options) {
											i8nOptions = options.map((option) => ({
												...option,
												label: t(`${option.label}`),
											}));
										}
										const placeholder = isRequired ? `${i8nLabel} *` : i8nLabel;

										return (
											<li className="form_fields__item" key={id}>
												<FormField
													id={id}
													placeholder={placeholder}
													name={id}
													type={type}
													required={isRequired}
													iconName={iconName}
													values={values}
													disabled={disabled}
													handleBlur={handleBlur}
													handleChange={handleChange}
													options={i8nOptions}
													setFieldTouched={setFieldTouched}
													setFieldValue={setFieldValue}
												/>
												<div className="form_fields__error">
													{touched[id] && errors[id]}
												</div>
											</li>
										);
									})}
								</ul>
								<button
									className="registration_form__button"
									type="submit"
									disabled={loadingStatus === PROMISE_STATES.pending}
								>
									<div className="btn_base">{t('registrationForm:registerButton')}</div>
								</button>
							</form>
						)}
					</Formik>
				) : (
					loadingStatus === PROMISE_STATES.pending ? (
						<Spinner darkTheme />
					) : <div className="registration_form__error">{errorMessage}</div>
				)}
			</div>
		</div>
	);
};

export default RegisterNewCompanyUserForm;
