export default class Insulation {
    static uValueTable = {
        '2-glazed': [
            // Default spacer is 14mm, keep those on top
            // Prefer argon over other gases, keep those on top
            { spacer1: 14, gas: "argon", uValue: 1.1, },
            { spacer1: 8, gas: "argon", uValue: 1.6, },
            { spacer1: 10, gas: "argon", uValue: 1.4, },
            { spacer1: 12, gas: "argon", uValue: 1.3, },
            { spacer1: 16, gas: "argon", uValue: 1.1, },
            { spacer1: 18, gas: "argon", uValue: 1.1, },
            { spacer1: 20, gas: "argon", uValue: 1.1, },

            // Default spacer is 14mm
            { spacer1: 14, gas: "krypton", uValue: 1.1, },
            { spacer1: 14, gas: "mixedGas", uValue: 1.2, },

            { spacer1: 8, gas: "krypton", uValue: 1.2, },
            { spacer1: 8, gas: "mixedGas", uValue: 1.4, },
            { spacer1: 10, gas: "krypton", uValue: 1.0, },
            { spacer1: 10, gas: "mixedGas", uValue: 1.2, },
            { spacer1: 12, gas: "krypton", uValue: 1.1, },
            { spacer1: 12, gas: "mixedGas", uValue: 1.1, },
            { spacer1: 16, gas: "krypton", uValue: 1.1, },
            { spacer1: 16, gas: "mixedGas", uValue: 1.2, },
            { spacer1: 18, gas: "krypton", uValue: 1.1, },
            { spacer1: 18, gas: "mixedGas", uValue: 1.2, },
            { spacer1: 20, gas: "krypton", uValue: 1.1, },
            { spacer1: 20, gas: "mixedGas", uValue: 1.2, },
        ],
        '3-glazed': [
            // Default spacer is 14mm, keep those on top
            // Prefer argon over other gases, keep those on top
            { spacer1: 14, spacer2: 12, gas: "argon", uValue: 0.7, },
            { spacer1: 14, spacer2: 14, gas: "argon", uValue: 0.6, },
            { spacer1: 14, spacer2: 16, gas: "argon", uValue: 0.6, },
            { spacer1: 8, spacer2: 8, gas: "argon", uValue: 1.0, },
            { spacer1: 8, spacer2: 10, gas: "argon", uValue: 0.9, },
            { spacer1: 10, spacer2: 8, gas: "argon", uValue: 0.9, },
            { spacer1: 10, spacer2: 10, gas: "argon", uValue: 0.8, },
            { spacer1: 10, spacer2: 12, gas: "argon", uValue: 0.8, },
            { spacer1: 12, spacer2: 10, gas: "argon", uValue: 0.8, },
            { spacer1: 12, spacer2: 12, gas: "argon", uValue: 0.7, },
            { spacer1: 12, spacer2: 14, gas: "argon", uValue: 0.7, },
            { spacer1: 16, spacer2: 14, gas: "argon", uValue: 0.6, },
            { spacer1: 16, spacer2: 16, gas: "argon", uValue: 0.6, },

            { spacer1: 14, spacer2: 12, gas: "krypton", uValue: 0.5, },
            { spacer1: 14, spacer2: 12, gas: "mixedGas", uValue: 0.6, },
            { spacer1: 14, spacer2: 14, gas: "krypton", uValue: 0.5, },
            { spacer1: 14, spacer2: 14, gas: "mixedGas", uValue: 0.5, },
            { spacer1: 14, spacer2: 16, gas: "krypton", uValue: 0.5, },
            { spacer1: 14, spacer2: 16, gas: "mixedGas", uValue: 0.5, },

            { spacer1: 8, spacer2: 8, gas: "krypton", uValue: 0.7, },
            { spacer1: 8, spacer2: 8, gas: "mixedGas", uValue: 0.8, },
            { spacer1: 8, spacer2: 10, gas: "krypton", uValue: 0.6, },
            { spacer1: 8, spacer2: 10, gas: "mixedGas", uValue: 0.8, },
            { spacer1: 10, spacer2: 8, gas: "krypton", uValue: 0.6, },
            { spacer1: 10, spacer2: 8, gas: "mixedGas", uValue: 0.8, },
            { spacer1: 10, spacer2: 10, gas: "krypton", uValue: 0.6, },
            { spacer1: 10, spacer2: 10, gas: "mixedGas", uValue: 0.7, },
            { spacer1: 10, spacer2: 12, gas: "krypton", uValue: 0.5, },
            { spacer1: 10, spacer2: 12, gas: "mixedGas", uValue: 0.6, },
            { spacer1: 12, spacer2: 10, gas: "krypton", uValue: 0.5, },
            { spacer1: 12, spacer2: 10, gas: "mixedGas", uValue: 0.6, },
            { spacer1: 12, spacer2: 12, gas: "krypton", uValue: 0.5, },
            { spacer1: 12, spacer2: 12, gas: "mixedGas", uValue: 0.6, },
            { spacer1: 12, spacer2: 14, gas: "krypton", uValue: 0.5, },
            { spacer1: 12, spacer2: 14, gas: "mixedGas", uValue: 0.6, },
            { spacer1: 16, spacer2: 14, gas: "krypton", uValue: 0.5, },
            { spacer1: 16, spacer2: 14, gas: "mixedGas", uValue: 0.5, },
            { spacer1: 16, spacer2: 16, gas: "krypton", uValue: 0.5, },
            { spacer1: 16, spacer2: 16, gas: "mixedGas", uValue: 0.5, },
            { spacer1: 18, spacer2: 18, gas: "krypton", uValue: 0.5, },
            { spacer1: 18, spacer2: 18, gas: "mixedGas", uValue: 0.5, },
            { spacer1: 20, spacer2: 20, gas: "krypton", uValue: 0.5, },
            { spacer1: 20, spacer2: 20, gas: "mixedGas", uValue: 0.5, },
        ]
    }

    static getUValueRangeForSpacer(glass_structure, spacer_mm) {
        if (glass_structure === '1-glazed') {
            glass_structure = '2-glazed';
        }
        let uVals = Insulation.uValueTable[glass_structure].filter(val => {
            if (glass_structure === '2-glazed') {
                return val.spacer1 == spacer_mm;
            } else if (glass_structure === '3-glazed') {
                return val.spacer1 == spacer_mm && val.spacer2 == spacer_mm;
            }
        });
        return {
            min: Math.min(...uVals.map(o => o.uValue)),
            max: Math.max(...uVals.map(o => o.uValue)),
        };
    }

    static getUValueRangeForGas(glass_structure, gas) {
        if (glass_structure === '1-glazed') {
            glass_structure = '2-glazed';
        }
        let uVals = Insulation.uValueTable[glass_structure].filter(val => {
            return val.gas == gas;
        });
        return {
            min: Math.min(...uVals.map(o => o.uValue)),
            max: Math.max(...uVals.map(o => o.uValue)),
        };
    }

    static getUValueRangeForStructure(glass_structure) {
        if (glass_structure === '1-glazed') {
            return { min: 5.4, max: 5.4 };
        }
        return {
            min: Math.min(...Insulation.uValueTable[glass_structure].map(o => o.uValue)),
            max: Math.max(...Insulation.uValueTable[glass_structure].map(o => o.uValue)),
        };
    }

    static calculateUValueForGlass(spacer1, spacer2, gas) {
        try {
            if (!spacer1) {
                return 0;
            } else if (!spacer2) {
                return Insulation.uValueTable['2-glazed'].filter(val => val.spacer1 === spacer1 && val.gas === gas)[0].uValue;
            } else {
                return Insulation.uValueTable['3-glazed'].filter(val => val.spacer1 === spacer1 && val.spacer2 === spacer2 && val.gas === gas)[0].uValue;
            }
        } catch (e) {
            return -1;
        }
    }
}
