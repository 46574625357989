import React from 'react';
import { AccountCompanyForm, UpdateUserNameForm, UpdateUserPasswordForm } from 'components/Form/Account';
import { CompanyUsers } from 'components/CompanyUsers';
import { PageNavigation } from 'components/PageNavigation';
import { BlockHeading } from 'components/BlockHeading';
import { useTranslation } from 'react-i18next';

const AccountPage = () => {
	const { t } = useTranslation('accountPage');

	return (
		<section className="section">
			<div className="section__in">
				<PageNavigation />
				<div className="auth_list">
					<div className="auth_block">
						<AccountCompanyForm />
					</div>
					<div className="auth_block">
						<div className="account_form">
							<BlockHeading
								title={t('userFormTitle')}
							/>
							<div className="account_form__in">
								<UpdateUserNameForm />
								<UpdateUserPasswordForm />
							</div>
						</div>
					</div>
					<div className="auth_block">
						<CompanyUsers />
					</div>
				</div>
			</div>
		</section>
	);
};

export default AccountPage;
