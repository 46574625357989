import React from 'react';

const ConfigurationBlockCell = ({
	title,
	text,
	onClickHandler,
	sortType,
}) => {
	const preparedText = !text ? '#' : text;

	return (
		<div className="configuration_block__cell">
			{title && (
				<button
					type="button"
					className="configuration_block__title"
					onClick={onClickHandler ? () => onClickHandler(sortType) : null}
				>
					{title}
				</button>
			)}
			{preparedText && (
				<div className="configuration_block__text">{preparedText}</div>
			)}
		</div>
	);
};

export default ConfigurationBlockCell;
