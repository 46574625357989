/* eslint-disable indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
import React from "react";
import { useTranslation } from "react-i18next";
import ConfigurationBlockCell from "./ConfigurationBlockCell";
import ConfigurationBlockControls from "./ConfigurationBlockControls";

const ConfigurationBlock = ({
  reference,
  code,
  buildingReference,
  amountGlassTypes,
  created,
  lastEdited,
  type,
  status,
  uploadedDocuments,
  isOrderPage,
  fullConfiguration,
  handleClickOnSortByDateButton,
  handleClickOnSortByReferenceButton,
}) => {
  const { t } = useTranslation("commonAppValues");

  return (
    <div className="configuration_block">
      <div className="configuration_block__col configuration_block__col--reference_state">
        <ConfigurationBlockCell
          title={t("configurationCodeLabel")}
          text={code}
        />
      </div>
      <div className="configuration_block__col configuration_block__col--reference_state">
        <ConfigurationBlockCell
          title={t("reference")}
          text={reference}
          sortType="reference"
          onClickHandler={handleClickOnSortByReferenceButton}
        />
      </div>
      <div className="configuration_block__col configuration_block__col--building_state">
        <ConfigurationBlockCell
          title={t("building")}
          text={buildingReference}
        />
      </div>
      <div className="configuration_block__col configuration_block__col--types_state">
        <ConfigurationBlockCell title={t("types")} text={amountGlassTypes} />
      </div>
      {!isOrderPage ? (
        <>
          <div className="configuration_block__col configuration_block__col--created_state">
            <ConfigurationBlockCell
              title={t("created")}
              text={created}
              sortType="created"
              onClickHandler={handleClickOnSortByDateButton}
            />
          </div>
          <div className="configuration_block__col configuration_block__col--edited_state">
            <ConfigurationBlockCell
              title={t("lastEdited")}
              sortType="lastEdited"
              text={lastEdited}
              onClickHandler={handleClickOnSortByDateButton}
            />
          </div>
          <div className="configuration_block__col configuration_block__col--controls_state">
            <ConfigurationBlockControls
              configurationCode={code}
              configurationType={type}
              configurationStatus={status}
              uploadedDocuments={uploadedDocuments}
              configuration={fullConfiguration}
            />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default ConfigurationBlock;
