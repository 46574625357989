import React, { useState, useRef } from "react";
import Select from "react-select";

import { ReactComponent as EyeOpenIcon } from "i/icons/eye.svg";
import { ReactComponent as EyeCrossedIcon } from "i/icons/eye_w_line.svg";

import { ReactComponent as MailIcon } from "i/icons/mail.svg";
import { ReactComponent as LockIcon } from "i/icons/lock.svg";

const FormField = ({
	id,
	placeholder = "placeholder",
	name,
	type = "text",
	required = false,
	iconName = false,
	values,
	handleChange,
	handleBlur,
	defaultValue,
	disabled = false,
	options,
	setFieldTouched,
	setFieldValue,
}) => {
	const [passwordShown, setPasswordShown] = useState(false);
	const selectRef = useRef(null);
	let optionIdx;

	if (options) {
		optionIdx = options.findIndex((option) => option.value === values[id]);
	}

	const renderFieldIcon = () => {
		if (iconName === "lock") {
			return <LockIcon className="icon icon-lock size_mod" />;
		}

		if (iconName === "mail") {
			return <MailIcon className="icon icon-mail size_mod" />;
		}

		return null;
	};

	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	};

	const selectStyles = {
		control: (base, state) => ({
			...base,
			width: "100%",
			height: "4rem",
			padding: "0 0.6rem",
			backgroundColor: "#fbfbfb",
			borderRadius: "2px",
			borderColor: state.isFocused ? "#5486a4" : "transparent",
			border: "1px",
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		option: (base, state) => ({
			...base,
			backgroundColor: state.isSelected ? "#e5e5e5" : "#fbfbfb",
			":hover": {
				backgroundColor: "#EDEDED",
			},
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		placeholder: (base) => ({
			...base,
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
		singleValue: (base) => ({
			...base,
			fontFamily: "DINNextLTPro",
			fontSize: "1.3rem",
			color: "#575757",
		}),
	};

	return (
		<div className="form_field">
			{iconName && (
				<div className="form_field__col icon_mod">
					<label className="form_field__label" htmlFor={id}>
						{renderFieldIcon()}
					</label>
				</div>
			)}
			{options ? (
				<Select
					className="react-select"
					id={id}
					name={name}
					ref={selectRef}
					components={{
						IndicatorSeparator: () => null,
					}}
					placeholder={placeholder}
					onChange={(selectedOption) => {
						setFieldValue(id, selectedOption.value);
						setFieldTouched(name, false);
					}}
					options={options}
					onBlur={() => {
						const selectedOption = selectRef.current.getValue()[0]?.value;
						if (!selectedOption) {
							setFieldTouched(name);
						} else {
							setFieldTouched(name, false);
						}
					}}
					required={required}
					defaultValue={options[optionIdx]}
					disabled={disabled}
					styles={selectStyles}
				/>
			) : (
				<input
					className="form_field__input"
					id={id}
					type={type === "password" && passwordShown ? "text" : type}
					name={name}
					value={values ? values[name] : undefined}
					defaultValue={defaultValue}
					placeholder={placeholder}
					required={required}
					onBlur={handleBlur}
					onChange={handleChange}
					disabled={disabled}
				/>
			)}
			{type === "password" ? (
				<button
					className="form_field_btn"
					type="button"
					onClick={togglePassword}
				>
					{passwordShown ? (
						<EyeOpenIcon className="icon icon-eye size_mod show_mod" />
					) : (
						<EyeCrossedIcon className="icon icon-eye_w_line size_mod hide_mod" />
					)}
				</button>
			) : null}
		</div>
	);
};

export default FormField;
