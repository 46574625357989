import React, { useState, useEffect, useContext } from 'react';
import { AppDataContext } from 'context/appData';
import { filterByCategoryId } from 'utils';
import APP_DATA from 'utils/jsonAppData';

import FaqItem from './FaqItem';
import FaqNav from './FaqNav';
import FaqSearch from './FaqSearch';
import FaqResults from './FaqResults';
import FaqNotFoundResult from './FaqNotFoundResult';

const FaqContainer = () => {
	const {
		allFaqArchive,
		allFaqCategories,
		langApp,
	} = useContext(AppDataContext);

	const [filteredFaqArchive, setFilteredFaqArchive] = useState(null);
	const [isSearchApplied, setSearchApplied] = useState(false);
	const [inputSearchValue, setInputSearchValue] = useState('');

	const resetSearchFilter = () => {
		const categoryId = allFaqCategories[0].id;
		const filteredByCategory = filterByCategoryId(allFaqArchive, categoryId);

		setFilteredFaqArchive(filteredByCategory);
		setSearchApplied(false);
		setInputSearchValue('');
	};

	useEffect(() => {
		if (allFaqArchive) {
			setFilteredFaqArchive([...allFaqArchive]);
		}
	}, [allFaqArchive]);

	return (
		allFaqArchive && allFaqArchive.length ? (
			<>
				<FaqSearch
					inputSearchValue={inputSearchValue}
					setInputSearchValue={setInputSearchValue}
					setFilteredFaqArchive={setFilteredFaqArchive}
					setSearchApplied={setSearchApplied}
				/>
				{isSearchApplied ? (
					<button
						className="btn_v2"
						type="button"
						onClick={resetSearchFilter}
					>
						{APP_DATA.faqPage.buttonBackFromSearch[langApp]}
					</button>
				) : null}
				{filteredFaqArchive && filteredFaqArchive.length ? (
					isSearchApplied ? (
						<FaqResults
							searchResults={filteredFaqArchive}
						/>
					) : (
						<>
							<FaqNav
								filteredFaqArchive={filteredFaqArchive}
								setFilteredFaqArchive={setFilteredFaqArchive}
							/>
							<div className="popup_tabs_wrap">
								<div className="faq_content">
									{filteredFaqArchive.map(({
										id,
										answer,
										question,
									}) => {
										return (
											<FaqItem
												key={id}
												title={question}
												text={answer}
											/>
										);
									})}
								</div>
							</div>
						</>
					)
				) : null}
				{filteredFaqArchive && !filteredFaqArchive.length && isSearchApplied ? (
					<FaqNotFoundResult />
				) : null}
			</>
		) : null
	);
};

export default FaqContainer;
