import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import { BlockHeading } from 'components/BlockHeading';
import { resetFilters } from 'components/ThreeJs/assets/main';

const AddNewType = ({ isDashboardPage }) => {
	const { t } = useTranslation('cartPage');

	const handleClick = () => {
		resetFilters();
	};

	return (
		<div className="add_new_type">
			<BlockHeading
				title={isDashboardPage ? t('createFirstConfiguration') : t('addNewTypeTitle')}
				iconName="plus"
				showMenu
			/>
			<div className="add_new_type__container">
				<div className="add_new_type__heading">
					<div className="add_new_type__title">{t('addNewTypePlaceholderTitle')}</div>
					<div className="add_new_type__descr">{t('addNewTypePlaceholderValue')}</div>
				</div>
				<div className="add_new_type__btn">
					<NavLink to="/" className="btn_base" onClick={handleClick}>
						{t('addNewTypeBtn')}
					</NavLink>
				</div>
			</div>
		</div>
	);
};

export default AddNewType;
