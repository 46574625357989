import React from 'react';
import FilterUIElement from 'components/ThreeJs/assets/filter/elementsUI/FilterUIElement';
import { useTranslation } from 'react-i18next';
import 'styles/blocks/selector.scss';
import camelToDash from './camelToDash';

const GlassProductSelector = ({
	name,
	uiHandlerRef,
	wrapperClass,
	labelClass,
}) => {
	const dashkey = camelToDash(name);
	const { t } = useTranslation('configurationCategory');
	let labelNormParts = t(name).split(/(\(EN.*\))/);
	labelNormParts[1] = labelNormParts[1] || (<>&nbsp;</>);

	return (

		<label htmlFor={dashkey.concat('_input')} className={wrapperClass}>
			<FilterUIElement isDisabled id={dashkey} uiHandlerRef={uiHandlerRef} defaultVisible={dashkey === 'manufacturer' || dashkey === 'clear-glass' || dashkey === 'resistance-class'} />
			<div style={{ fontVariant: 'small-caps' }} className={labelClass}>
				{labelNormParts[0]}
				<br />
				{labelNormParts[1]}
			</div>
		</label>
	);
};

export default GlassProductSelector;
