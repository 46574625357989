export default class FireResistantGlass {
  constructor(id, glasstype, id_app, id_veras, fireResistanceClass, thickness, white_glass, uv_protection, application, structure, manufacturer, u_value, sound, weight, butt_joints, miter, resistanceClass, max_width, max_height, order, layers) {
    this.dbIndex = -1;
    this.id = id;
    this.glasstype = glasstype;
    this.id_app = id_app;
    this.id_veras = id_veras;
    this.fireResistanceClass = fireResistanceClass;
    this.thickness = parseFloat(thickness ?? thickness.replace(",", "."));
    this.white_glass = white_glass === 'Yes';
    this.uv_protection = uv_protection;
    this.application = application;
    this.structure = structure;
    this.manufacturer = manufacturer;
    this.u_value = parseFloat(u_value.replace(",", "."));
    this.sound = parseFloat(sound);
    this.weight = parseFloat(weight.replace(",", "."));
    this.butt_joints = butt_joints === 'Yes';
    this.miter = miter === 'Yes';
    this.resistanceClass = resistanceClass;
    this.max_width = parseFloat(max_width);
    this.max_height = parseFloat(max_height);
    this.order = order;
    this.layers = layers ? JSON.parse(layers) : (white_glass ? [this.thickness + ':1'] : [this.thickness + ':0']);

    let sum = this.layers.reduce((acc, cur) => acc + parseFloat(cur.split(":")[0]), 0.0);
    if (sum !== this.thickness) {
      console.warn(`${this.id}: Glass ${this.glasstype}: Layers (${sum}) don't add up to glass thickness (${this.thickness})`);
    }
  }
}

