import { firebaseDB } from 'utils/firebase';
import {
	setDoc,
	getDoc,
	doc,
	deleteDoc,
	getDocs,
	query,
	collection,
} from 'firebase/firestore';

const COLLECTION_NAME = 'companies';

const addNewCompany = async (company) => {
	if (!company) return null;

	const res = await setDoc(doc(firebaseDB, COLLECTION_NAME, company.id), company);

	return res;
};

const getCompany = async (companyId) => {
	if (!companyId) return null;

	const snap = await getDoc(doc(firebaseDB, COLLECTION_NAME, companyId));

	if (snap.exists()) {
		return snap.data();
	}

	return null;
};

const getClientCompanies = async () => {
	const companiesQuery = query(collection(firebaseDB, COLLECTION_NAME));

	const querySnapshot = await getDocs(companiesQuery);

	const res = [];

	querySnapshot.forEach((docSnap) => {
		res.push(docSnap.data());
	});

	return res;
};

const deleteCompany = async (companyId) => {
	if (!companyId) return null;

	const res = await deleteDoc(doc(firebaseDB, COLLECTION_NAME, companyId));

	return res;
};

const COMPANIES_API = {
	addNewCompany,
	getCompany,
	getClientCompanies,
	deleteCompany,
};

export default COMPANIES_API;
