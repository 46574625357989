/* eslint-disable indent */
export const TRANSLATION_DE = {
  visualizerComponent: {
    measurementArrowsLabelFormat: "ED {}",
  },
  baseFormPlaceholders: {
    userEmailPlaceholder: "E-Mail",
    userPasswordPlaceholder: "Passwort",
    repeatPasswordPlaceholder: "Passwort wiederholen",
    newPasswordPlaceholder: "Neues Passwort",
    repeatNewPasswordPlaceholder: "Neues Passwort wiederholen",
    userGenderPlaceholder: "GESCHLECHT",
    userCompanyPlaceholder: "Firma",
    userFirstNamePlaceholder: "Vorname",
    userLastNamePlaceholder: "Nachname",
    userFunctionPlaceholder: "FUNKTION",
    userCompanyEmailPlaceholder: "Email",
    userStreetPlaceholder: "Strasse & Hausnummer",
    userZIPPlaceholder: "PLZ",
    userCityPlaceholder: "Stadt",
    userPhonePlaceholder: "Telefon",
    searchField: "Suchen",
    hideMessage: 'Nachricht "Strukturänderung" im Konfigurator ausblenden',
    userGenderMale: "Männlich",
    userGenderFemale: "Weiblich",
    userGenderDiv: "Divers",
    userFunctionOwner: "Inhaber/-in",
    userFunctionCoOwner: "Mitinhaber/-in",
    userFunctionManagement: "Verwaltung",
    userFunctionExecutiveManagement: "Geschäftsleitung",
    userFunctionPurchasingProcurement: "Einkauf/Beschaffung",
    userFunctionSales: "Verkauf",
    userFunctionProjectManagement: "Projektleitung",
    userFunctionCalculationCosting: "Kalkulation",
    userFunctionAdministrativeProcessing: "Sachbearbeiter",
    userFunctionOther: "Andere",
  },
  commonAppValues: {
    reference: "Referenz 1",
    building: "Referenz 2",
    types: "# Typen",
    created: "Erstellt",
    lastEdited: "Zuletzt bearbeitet",
    configuration: "Konfiguration",
    company: "Unternehmen",
    orders: "Aufträge",
    openOrders: "Offene Aufträge",
    openRequests: "Offene Anfragen",
    drafts: "Entwürfe",
    requests: "Anfragen",
    addPosition: "Position hinzufügen",
    companies: "Unternehmen",
    configurationCodeLabel: "Code",
  },
  notifications: {
    loadConfiguration: "Konfiguration wird geladen...",
    configurationUpdatedSuccessfully: "Konfiguration gespeichert.",
    configurationIsSavedInTheCompany:
      "Die Konfiguration wurde in Ihrem Unternehmen gespeichert!",
    configurationUpdatedError:
      "Es ist ein Fehler aufgetreten, die Konfiguration wurde nicht aktualisiert",
    configurationNotFound: "Die Konfiguration wurde nicht gefunden!",
    confirmationOfPositionDeletion:
      "Sind Sie sicher, dass Sie diese Position löschen wollen?",
    confirmationOfGlassDeletion:
      "Sind Sie sicher, dass Sie diese Glaskonfiguration löschen wollen?",
    confirmationOfGlassEdit: "Wollen Sie diese Glaskonfiguration bearbeiten?",
    errorAddingFileWrongFormat: "Sie können nur Bilder und PDFs hinzufügen",
    confirmationOfDocumentDeletion:
      "Sind Sie sicher, dass Sie dieses Dokument löschen wollen?",
    confirmationOfConfigurationDeletion:
      "Sind Sie sicher, dass Sie diese Konfiguration löschen wollen?",
    errorLargeFileSize: "Maximale Dateigrösse {}",
    accountCreationInProgress: "Kontoerstellung im Gange",
    accountCreatedSuccessfully: "Sie haben sich erfolgreich angemeldet",
    emailSendedSuccessfully: "Ihre Bestellung wurde abgeschickt. Vielen Dank.",
    emailSendingInProgress: "Bitte warten Sie, die Nachricht wird gesendet.",
    confirmationOfResetingConfiguration:
      "Sind Sie sicher, dass Sie diese Konfiguration mit allen hochgeladenen Dateien löschen möchten?",
    configurationDeletionInProgress:
      "Die Löschung der Konfiguration ist im Gange",
    configurationWasDeletedSuccessfully:
      "Die Konfiguration wurde erfolgreich gelöscht",
    passwordResetInProgress: "Kennwortrücksetzung in Bearbeitung",
    passwordHasBeenSuccessfullyChanged: "Passwort wurde erfolgreich geändert",
    pleaseWait: "Bitte warten...",
    companyDataWasUpdated: "Die Firmendaten wurden aktualisiert!",
    emailWithInstuctionsToResetPasswordHasBeenSent:
      "Eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts wurde an Sie gesendet.",
    confirmationOfChangeTypeConfiguration:
      "Sind Sie sicher, dass Sie den Typ dieser Konfiguration ändern möchten?",
    configurationTypeChangeInProgress:
      "Die Änderung des Konfigurationstyps ist im Gange",
    configTypeChangedSuccessfully: "Konfigurationstyp erfolgreich geändert!",
    configurationStatusChangeInProgress:
      "Änderung des Konfigurationsstatus in Bearbeitung",
    configurationStatusChangedSuccessfully:
      "Konfigurationsstatus erfolgreich geändert!",
    duplicatingConfigWithAllFilesInProgress:
      "Bitte warten. Duplizieren einer Konfiguration mit allen Dateien im Gange",
    configurationDuplicationCompletedSuccessfully:
      "Konfiguration erfolgreich dupliziert",
    confirmationOfClientCompanyDeletion:
      "Sind Sie sicher, dass Sie dieses Kundenunternehmen mit seinen Benutzern und Konfigurationen löschen möchten?",
    clientCompanyDeletionInProgress: "Löschung des Kundenunternehmens läuft",
    clientCompanyWasDeletedSuccessfully:
      "Kundenunternehmen wurde erfolgreich gelöscht",
    companyNotFound: "Firma nicht gefunden!",
    loadingCompanyData: "Laden der Firmendaten...",
    dataLoadedSuccessfully: "Daten erfolgreich geladen!",
    dataHasBeenSuccessfullyUpdated:
      "Die Daten wurden erfolgreich aktualisiert!",
    userHasBeenSuccessfullyRemovedFromTheCompany:
      "Der Benutzer wurde erfolgreich aus dem Unternehmen entfernt!",
    confirmationOfUserDeletion:
      "Sind Sie sicher, dass Sie diesen Benutzer aus dem Unternehmen entfernen wollen?",
    invitationLinkHasBeenCopied: "Link wurde in die Zwischenablage kopiert!",
    anErrorHasOccurred: "Ein Fehler ist aufgetreten",
    loadingDataOfAllCompaniesInProgress:
      "Laden der Daten aller Unternehmen läuft",
    uploadDocAreaDragAndDrop: "Dateien hierher ziehen & ablegen",
    uploadDocAreaOr: "oder",
    uploadDocAreaBrowseFiles: "Dateien auf Ihrem Computer suchen",
    uploadDocAreaMaxFileSizeFmt: "Maximale Dateigrösse {}",
  },
  formNotifications: {
    emailRequired: "E-Mail ist erforderlich",
    enterValidEmail: "Bitte geben Sie eine gültige E-Mail ein",
    fieldIsRequired: "Ist erforderlich",
    nameFieldInvalidCharacters: "Ungültige Zeichen",
    nameFieldCharactersLength: "Muss aus mindestens drei Zeichen bestehen",
    passwordFieldCharactersLong: "Das Passwort muss 8 Zeichen lang sein",
    passwordFieldMustContainNumber:
      "Ungültiges Passwort. Muss eine Zahl enthalten.",
    passwordNotMatched: "Kennwort stimmt nicht überein",
  },
  configurationCategory: {
    glassType: "Glastyp",
    fireResistant: "Brandschutzglas",
    spacer1: "Abstandshalter 1",
    gas1: "Gas",
    middleGlass: "Mittleres Glas (Silverstar EN2plus)",
    spacer2: "Abstandshalter 2",
    gas2: "Gas",
    outsideGlass: "Äusseres Glas (Silverstar EN2plus)",
    structure: "Struktur",
    manufacturer: "Hersteller",
    fireResistanceClass: "Feuerwiderstand (EN 13501-2)",
    insulation: "U-Wert [W/m²K] (EN 673)",
    application: "Anwendung",
    whiteGlass: "Weissglas",
    resistanceClass: "Widerstandsklasse (EN 1627)",
    buttJoints: "Stossfugen",
    sound: "Schalldämmung Rw (EN 140-3)",
  },
  filterName: {
    StructureFilter: "Struktur",
    ManufacturerFilter: "Hersteller",
    FireClassFilter: "Feuerwiderstand",
    InsulationFilter: "U-Wert",
    ApplicationFilter: "Anwendung",
    WhiteGlassFilter: "Weissglas",
    ResistanceClassFilter: "Widerstandsklasse",
    ButtJointsFilter: "Stossfugen",
    SoundFilter: "Schalldämmung",
  },
  commonConfigurationAction: {
    addToCart: "In den Warenkorb",
    updateInCart: "Im Warenkorb aktualisieren",
    resetGlass: "Glas zurücksetzen",
    resetConfiguration: "Warenkorb zurücksetzen",
    saveAsPdf: "Als PDF speichern",
    glassDotPdfFmt: "CDR_Glas_{date}.pdf",
    configurationDotPdfFmt: "CDR_Konfiguration_{date}.pdf",
    continue: "Fortsetzen",
    delete: "Löschen",
    complete: "Abschliessen",
    duplicate: "Duplizieren",
    edit: "Bearbeiten",
    order: "Bestellen",
    duplicateAsDraft: "Duplizieren als Entwurf",
    firstSelectProperties: "Eigenschaften Auswählen",
    secondCustomize: "Anpassen",
    addYourGlassToCart: "Fügen Sie Ihr Glas dem Warenkorb hinzu",
    sizeValidationError:
      "Bitte stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind: Breite, Höhe und Menge.",
    selectProductToContinue: "Wählen Sie ein Produkt aus, um fortzufahren.",
  },
  configurationTableHeading: {
    length: "Breite",
    width: "Höhe",
    quantity: "Menge",
    price: "Preis",
    form: "Modell",
    formNote:
      "Für spezielle Formgläser hinterlassen Sie bitte eine Notiz oder laden Sie ein Dokument mit den Spezifikationen am unteren Rand hoch",
    notes: "Notiz",
    cantonalInsurance: "VKF",
    cantonalInsuranceLink: "https://www.bsronline.ch/de/registersuche/",
    total: "Gesamt",
    totalCart: "Gesamtwarenkorb",
    pos: "pos",
    pc: "stk",
  },
  registrationForm: {
    title: "Registrierung",
    registerButton: "Registrieren",
    alreadyHaveAccount: "Sie haben bereits ein Konto? ",
    logIn: "Anmelden",
  },
  loginForm: {
    title: "Anmeldung",
    dontHaveAccount: "Sie haben noch kein Konto?",
    loginButton: "Anmelden",
    lostPassword: "Passwort verloren",
    signUp: "Registrieren",
    successfullyLoggedIn: "Sie sind erfolgreich eingeloggt!",
    loggingInProgress: "Logging im Gange",
    successfullyLoggedOut: "Sie haben sich erfolgreich abgemeldet",
  },
  lostPasswordForm: {
    dontHaveAccount: "Sie haben kein Konto?",
    title: "Passwort verloren",
    submitButton: "Passwort zurücksetzen",
    signUp: " Registrieren",
    loggingInProgress: "Reset im Gange",
    successfullyLoggedIn: "Zurücksetzen erfolgreich",
  },
  resetConfigurationForm: {
    title: "Konfiguration zurücksetzen",
    text: "Möchten Sie eine neue Konfiguration starten und die aktuelle behalten? Der Konfigurationscode ist 30 Tage lang gültig.",
    configurationCode: "Konfigurationscode",
    resetButton: "Zurücksetzen",
    or: "oder",
    textNew:
      "Wollen Sie eine neue Konfiguration starten und alle Inhalte und Dateien löschen?",
    resetDeleteButton: "Zurücksetzen und löschen",
    configurationDoesNotExist: "Die Konfiguration existiert nicht",
  },
  resetGlassForm: {
    title: "Glas zurücksetzen",
    text: "Möchten Sie das aktuelle Glas und die Filter zurücksetzen?",
    resetButton: "Zurücksetzen",
  },
  confirmFilterChangesForm: {
    titleStructureChanged: "Strukturänderung",
    textStructureChanged:
      "Diese Option ändert die Glasstruktur von {oldStruct} in {newStruct} und kann andere Filter beeinflussen. Möchten Sie fortfahren?",
    titleFiltersReset: "Filter zurücksetzen",
    textFiltersReset:
      "Diese Option setzt die Filter {filters} zurück. Möchten Sie fortfahren?",
    yesChange:
      "Ja, ändern Sie die Glasstruktur und setzen Sie die erforderlichen Filter zurück",
    noAbort: "Nein, brechen wir ab und versuchen etwas anderes",
    hideMessage: "Nachricht nicht mehr anzeigen",
  },
  guestForm: {
    title: "Gast",
    additionalInfo: "Zusätzliche Infos",
    continue: "Fortsetzen",
  },
  accountCompanyForm: {
    title: "Unternehmen",
  },
  accountPage: {
    companyFormTitle: "Firma",
    userFormTitle: "Benutzer",
    usersInCompanyTitle: "Benutzer im Unternehmen",
    registerNewUser: "Neuen Benutzer für dieses Unternehmen registrieren",
    saveButton: "Speichern",
  },
  configurationCodeForm: {
    title: "Konfigurationscode",
    enterYourCodePlaceholder: "Geben Sie Ihren Code ein",
    overwriteConfigurationCheckbox:
      "Damit wird Ihre aktuelle Konfiguration überschrieben",
    submitButton: "laden",
  },
  resetPasswordForm: {
    title: "Passwort vergessen",
    submitButton: "Passwort zurücksetzen",
  },
  homePage: {
    myGlassButton: "Mein Glas",
  },
  cartPage: {
    configurationCodeTitle: "Konfigurationscode",
    configurationCodeLabel: "Code",
    configurationCodeDescription:
      "Dies ist der Code für Ihre Konfiguration, wenn Sie diese später ohne Anmeldung abrufen möchten. Er ist 30 Tage lang gültig.",
    yourReference: "Ihre Referenz",
    yourReferenceInputLabel: "Referenz 1",
    yourReferenceOptionalTitle: "Optional",
    buildingReferenceInputLabel: "Referenz 2",
    buildingReferenceInputLabelPlaceholder: "Gebäude XYZ",
    uploadedDocuments: "Hochgeladene Dokumente", // Corrected
    addNewTypeTitle: "Neuen Glastyp hinzufügen", // Corrected
    createFirstConfiguration: "Erstellen Sie Ihre erste Konfiguration",
    addNewTypePlaceholderTitle: "Glasart",
    addNewTypePlaceholderValue: "Brandschutzglas",
    addNewTypeBtn: "Hinzufügen",
    cartIsEmpty: "Warenkorb ist leer",
    termsText:
      "Ich akzeptiere die <a href='#' target='_blank'>Allgemeinen Geschäftsbedingungen</a> und den <a href='#' target='_blank'>Haftungsausschluss</a>",
  },
  pageNavigation: {
    login: "Anmelden",
    myCode: "Mein Code",
    dashboard: "Dashboard",
    account: "Konto",
    logout: "Abmelden",
    adminDashboard: "Admin Dashboard", // Consider if translation needed
    configurator: "Konfigurator",
  },
  additionalPageNavigation: {
    configure: "Konfigurieren",
    cart: "Warenkorb",
    inquireOrder: "Zusammenfassung",
  },
  requestOrOrderPage: {
    request: "Anfrage",
    order: "Bestellen",
  },
  orderConfirmationPage: {
    orderConfirmationTitle: "Bestellbestätigung",
    formSubmittedText: "Ihre Bestellung wurde abgeschickt. Vielen Dank.",
  },
  adminDashboardPage: {
    clientsTitle: "Kunden",
  },
  contactCompanyInformation: {
    company: "Firma",
    address: "Adresse",
    zip: "PLZ",
    city: "Ort",
    tel: "Tel",
    email: "Email",
  },
  deletePositionPopup: {
    title: "Position löschen",
    description: "Sind Sie sicher, dass Sie diese Position löschen wollen?",
    deleteButton: "Löschen",
  },
  deleteAttachmentPopup: {
    title: "Anhang löschen",
    description: "Sind Sie sicher, dass Sie diesen Anhang löschen wollen?",
    deleteButton: "Löschen",
  },
  supportedDevicesPopup: {
    title: "Mobil",
    description:
      "Dieser Konfigurator wird derzeit auf mobilen Geräten nicht unterstützt. Bitte verwenden Sie einen Desktop-Computer oder passen Sie die Grösse des Browsers an.",
  },
  glassCharacteristics: {
    glassStructure: "Struktur",
    fireResistance: "Feuerwiderstand",
    uValue: "U-Wert",
    thickness: "Dicke",
    clearGlass: "Weissglas",
    resistanceClass: "Widerstandsklasse",
    buttJoints: "Geeignet für Stossfugenverglasung",
    application: "Geeignete Anwendungen",
    sound: "Schalldämmung",
    valid: "Gültige Konfiguration",
    glassMaxSizeWarningFormat:
      "Glas ist beschränkt auf {max_width_mm} x {max_height_mm} mm",
    filteredCategoryLabel: "Wegen filter nicht verfügbar",
  },
  filters: {
    changesUValue: "Ändert den U-Wert",
    gas_argon: "Argon",
    gas_krypton: "Krypton",
    gas_mixedGas: "Mischgas",
    gas_expensive:
      "Der Preis für dieses Gas ist derzeit sehr volatil und teuer",
    spacer_format: "{} mm",
    structure_1Glazed: "1-fach verglast (Mono)",
    structure_2Glazed: "2-fach verglast (Iso)",
    structure_3Glazed: "3-fach verglast (Iso)",
    manufacturer_indep: "Herstellerunabhängig",
    manufacturer_AGC: "AGC",
    manufacturer_GlasTroesch: "Glas Trösch",
    fireResistanceClass_EI30: "EI30",
    fireResistanceClass_EI60: "EI60",
    fireResistanceClass_EI90: "EI90",
    insulation_UValueFormat: "U-Wert {}",
    application_in: "Innen",
    application_inOut: "Innen/Aussen",
    application_outOut: "Aussen/Aussen",
    whiteGlass_yes: "Ja",
    whiteGlass_no: "Nein",
    resistanceClass_none: "Ohne",
    resistanceClass_rc2: "RC2 (WK2 / P4A)",
    resistanceClass_rc3: "RC3 (WK3 / P5A)",
    resistanceClass_rc4: "RC4 (WK4 / P6B)",
    buttJoints_yes: "Ja",
    buttJoints_no: "Nein",
    sound_format: "{} dB",
    sound_format_npd: "NPD",
  },
  pdfPage: {
    pageFooterFmt: "Seite {pageNum} von {numPages}",
  },
    firebaseErrorMessages: {
    // Fehler für Cloud Storage
    "storage/unknown": "Ein unbekannter Fehler ist aufgetreten.",
    "storage/object-not-found": "Kein Objekt existiert unter dem gewünschten Bezug.",
    "storage/bucket-not-found": "Für den Cloud-Speicher ist kein Bucket konfiguriert.",
    "storage/project-not-found": "Für den Cloud-Speicher ist kein Projekt konfiguriert.",
    "storage/quota-exceeded": "Das Kontingent für Ihren Cloud-Speicher-Bucket wurde überschritten. Wenn Sie sich im kostenlosen Tarif befinden, wechseln Sie zu einem kostenpflichtigen Plan. Wenn Sie sich in einem kostenpflichtigen Tarif befinden, kontaktieren Sie den Firebase-Support.",
    "storage/unauthenticated": "Benutzer ist nicht authentifiziert, bitte authentifizieren Sie sich und versuchen Sie es erneut.",
    "storage/unauthorized": "Der Benutzer ist nicht berechtigt, die gewünschte Aktion durchzuführen, überprüfen Sie Ihre Sicherheitsregeln, um sicherzustellen, dass sie korrekt sind.",
    "storage/retry-limit-exceeded": "Die maximale Zeitgrenze für eine Operation (Upload, Download, Löschen usw.) wurde überschritten. Versuchen Sie erneut hochzuladen.",
    "storage/invalid-checksum": "Die Datei auf dem Client stimmt nicht mit der Prüfsumme der vom Server empfangenen Datei überein. Versuchen Sie erneut hochzuladen.",
    "storage/canceled": "Der Benutzer hat die Operation abgebrochen.",
    "storage/invalid-event-name": "Ungültiger Ereignisname angegeben. Muss einer von [`running`, `progress`, `pause`] sein.",
    "storage/invalid-url": "Ungültige URL an refFromURL() übergeben. Muss in der Form sein: gs://bucket/objekt oder https://firebasestorage.googleapis.com/v0/b/bucket/o/objekt?token=<TOKEN>",
    "storage/invalid-argument": "Das an put() übergebene Argument muss eine `Datei`, ein `Blob` oder ein `UInt8`-Array sein. Das an putString() übergebene Argument muss ein roher, `Base64`- oder `Base64URL`-String sein.",
    "storage/no-default-bucket": "In den Eigenschaften storageBucket Ihrer Konfiguration wurde kein Bucket festgelegt.",
    "storage/cannot-slice-blob": "Tritt häufig auf, wenn sich die lokale Datei geändert hat (gelöscht, erneut gespeichert usw.). Versuchen Sie erneut hochzuladen, nachdem Sie überprüft haben, dass die Datei sich nicht geändert hat.",
    "storage/server-file-wrong-size": "Die Datei auf dem Client entspricht nicht der Grösse der vom Server empfangenen Datei. Versuchen Sie erneut hochzuladen.",

    // Fehler der Authentifizierungs-API
    "auth/claims-too-large": "Die Claims-Ladung, die setCustomUserClaims() bereitgestellt wurde, überschreitet die maximal zulässige Grösse von 1000 Bytes.",
    "auth/email-already-exists": "Die angegebene E-Mail wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige E-Mail-Adresse haben.",
    "auth/id-token-expired": "Das bereitgestellte Firebase-ID-Token ist abgelaufen.",
    "auth/id-token-revoked": "Das Firebase-ID-Token wurde widerrufen.",
    "auth/insufficient-permission": "Das Zertifikat, das zur Initialisierung des Admin SDK verwendet wurde, hat nicht die notwendigen Berechtigungen, um auf die angeforderte Authentifizierungsressource zuzugreifen. Siehe Konfigurieren eines Firebase-Projekts für die Dokumentation zur Generierung eines Zertifikats mit den entsprechenden Berechtigungen und dessen Verwendung zur Authentifizierung der Admin SDKs.",
    "auth/internal-error": "Der Authentifizierungsserver hat beim Versuch, die Anfrage zu verarbeiten, einen unerwarteten Fehler festgestellt. Die Fehlermeldung sollte die Antwort des Authentifizierungsservers enthalten, die weitere Informationen enthält. Wenn der Fehler weiterhin besteht, melden Sie das Problem bitte unserem Bug Report-Supportkanal.",
    "auth/invalid-argument": "Ein ungültiges Argument wurde einer Authentifizierungsmethode bereitgestellt. Die Fehlermeldung sollte weitere Informationen enthalten.",
    "auth/invalid-claims": "Die bereitgestellten benutzerdefinierten Claim-Attribute für setCustomUserClaims() sind ungültig.",
    "auth/invalid-continue-uri": "Die Fortsetzungs-URL muss ein gültiger URL-String sein.",
    "auth/invalid-creation-time": "Die Erstellungszeit muss ein gültiger UTC-Datumsstring sein.",
    "auth/invalid-credential": "Das zur Authentifizierung der Admin SDKs verwendete Zertifikat kann nicht für die gewünschte Aktion verwendet werden. Einige Authentifizierungsmethoden wie createCustomToken() und verifyIdToken() erfordern, dass das SDK mit einem Zertifikat als Refresh-Token oder einem Standardanwendungstoken initialisiert wird. Siehe die Dokumentation zur Initialisierung des SDK, wie man die Admin SDKs mit einem Zertifikat authentifiziert.",
    "auth/invalid-disabled-field": "Der bereitgestellte Wert für die deaktivierte Benutzereigenschaft ist ungültig. Es muss ein Boolescher Wert sein.",
    "auth/invalid-display-name": "Der bereitgestellte Wert für die Benutzereigenschaft displayName ist ungültig. Es muss ein nicht leerer String sein.",
    "auth/invalid-dynamic-link-domain": "Der bereitgestellte dynamische Link-Domain ist für das aktuelle Projekt nicht konfiguriert oder autorisiert.",
    "auth/invalid-email": "Der bereitgestellte Wert für die Benutzereigenschaft E-Mail ist ungültig. Es muss eine E-Mail-Adresse sein.",
    "auth/invalid-email-verified": "Der bereitgestellte Wert für die Benutzereigenschaft emailVerified ist ungültig. Es muss ein Boolescher Wert sein.",
    "auth/invalid-hash-algorithm": "Das Hashing-Algorithmus muss mit einem der Strings aus der Liste der unterstützten Algorithmen übereinstimmen.",
    "auth/invalid-hash-block-size": "Die Hash-Blockgrösse muss eine gültige Zahl sein.",
    "auth/invalid-hash-derived-key-length": "Die abgeleitete Schlüssellänge des Hashes muss eine gültige Zahl sein.",
    "auth/invalid-hash-key": "Der Hash-Schlüssel muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-hash-memory-cost": "Die Hash-Speicherkosten müssen eine gültige Zahl sein.",
    "auth/invalid-hash-parallelization": "Die Hash-Parallelisierung muss eine gültige Zahl sein.",
    "auth/invalid-hash-rounds": "Die Anzahl der Hash-Runden muss eine gültige Zahl sein.",
    "auth/invalid-hash-salt-separator": "Das Salzseparatorfeld des Hash-Algorithmen muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-id-token": "Das bereitgestellte ID-Token ist kein gültiges Firebase-ID-Token.",
    "auth/invalid-last-sign-in-time": "Die letzte Anmeldezeit muss ein gültiger UTC-Datumsstring sein.",
    "auth/invalid-page-token": "Das in listUsers() bereitgestellte nächste Seitentoken ist ungültig. Es muss ein gültiger, nicht leerer String sein.",
    "auth/invalid-password": "Der bereitgestellte Wert für die Benutzereigenschaft Passwort ist ungültig. Es muss ein String mit mindestens sechs Zeichen sein.",
    "auth/invalid-password-hash": "Der Passwort-Hash muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-password-salt": "Das Passwort-Salz muss ein gültiger Byte-Puffer sein.",
    "auth/invalid-phone-number": "Der bereitgestellte Wert für die Telefonnummer ist ungültig. Es muss ein String sein, der dem E.164-Standard entspricht.",
    "auth/invalid-photo-url": "Der bereitgestellte Wert für die Benutzereigenschaft photoURL ist ungültig. Es muss ein URL-String sein.",
    "auth/invalid-provider-data": "providerData muss ein gültiges Array von UserInfo-Objekten sein.",
    "auth/invalid-provider-id": "providerId muss ein gültiger String eines unterstützten Anbieter-Identifikators sein.",
    "auth/invalid-oauth-responsetype": "Nur ein OAuth-Antworttyp sollte auf true gesetzt sein.",
    "auth/invalid-session-cookie-duration": "Die Dauer des Session-Cookies muss eine gültige Zahl in Millisekunden zwischen 5 Minuten und 2 Wochen sein.",
    "auth/invalid-uid": "Die bereitgestellte UID muss ein nicht leerer String von höchstens 128 Zeichen sein.",
    "auth/invalid-user-import": "Der zu importierende Benutzerdatensatz ist ungültig.",
    "auth/maximum-user-count-exceeded": "Die maximal zulässige Anzahl von zu importierenden Benutzern wurde überschritten.",
    "auth/missing-android-pkg-name": "Ein Android-Paketname muss bereitgestellt werden, wenn die Android-App installiert werden soll.",
    "auth/missing-continue-uri": "Eine gültige Fortsetzungs-URL muss in der Anfrage bereitgestellt werden.",
    "auth/missing-hash-algorithm": "Das Importieren von Benutzern mit Passwort-Hashes erfordert die Bereitstellung des Hashing-Algorithmus und seiner Parameter.",
    "auth/missing-ios-bundle-id": "In der Anfrage fehlt eine Bundle-ID.",
    "auth/missing-uid": "Eine UID ist für den aktuellen Vorgang erforderlich.",
    "auth/missing-oauth-client-secret": "Das Clientgeheimnis der OAuth-Konfiguration ist erforderlich, um den OIDC-Codefluss zu aktivieren.",
    "auth/operation-not-allowed": "Der bereitgestellte Anmeldeanbieter ist für Ihr Firebase-Projekt deaktiviert. Aktivieren Sie ihn im Abschnitt Anmeldemethode in der Firebase-Konsole.",
    "auth/phone-number-already-exists": "Die bereitgestellte Telefonnummer wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige Telefonnummer haben.",
    "auth/project-not-found": "Für das Zertifikat, das zur Initialisierung der Admin SDKs verwendet wurde, wurde kein Firebase-Projekt gefunden. Siehe Konfigurieren eines Firebase-Projekts für die Dokumentation zur Generierung eines Zertifikats für Ihr Projekt und dessen Verwendung zur Authentifizierung der Admin SDKs.",
    "auth/reserved-claims": "Einer oder mehrere der bereitgestellten benutzerdefinierten Benutzeransprüche für setCustomUserClaims() sind reserviert. Zum Beispiel sollten OIDC-spezifische Ansprüche wie (sub, iat, iss, exp, aud, auth_time usw.) nicht als Schlüssel für benutzerdefinierte Ansprüche verwendet werden.",
    "auth/session-cookie-expired": "Das bereitgestellte Firebase-Session-Cookie ist abgelaufen.",
    "auth/session-cookie-revoked": "Das Firebase-Session-Cookie wurde widerrufen.",
    "auth/too-many-requests": "Die Anzahl der Anfragen überschreitet das maximal erlaubte Limit.",
    "auth/uid-already-exists": "Die bereitgestellte UID wird bereits von einem bestehenden Benutzer verwendet. Jeder Benutzer muss eine einzigartige UID haben.",
    "auth/unauthorized-continue-uri": "Das Domain der Fortsetzungs-URL ist nicht auf der Whitelist. Fügen Sie das Domain zur Firebase-Konsole hinzu.",
    "auth/user-not-found": "Es gibt keinen Benutzerdatensatz, der dem bereitgestellten Identifikator entspricht.",
    "auth/wrong-password": "Falsches Passwort",

    // other errors
    ok: "Operation erfolgreich",
    cancelled: "Operation abgebrochen",
    unknown: "Unbekannter Fehler",
    "invalid-argument": "Ungültiges Argument",
    "deadline-exceeded": "Frist überschritten",
    "not-found": "Nicht gefunden",
    "already-exists": "Existiert bereits",
    "permission-denied": "Zugriff verweigert",
    unauthenticated: "Benutzer nicht authentifiziert",
    "resource-exhausted": "Ressource erschöpft",
    "failed-precondition": "Fehlgeschlagene Vorbedingung",
    aborted: "Operation abgebrochen",
    "out-of-range": "Ausserhalb des Bereichs",
    unimplemented: "Nicht implementiert",
    internal: "Interner Fehler",
    unavailable: "Nicht verfügbar",
    "data-loss": "Datenverlust",
  },
};
