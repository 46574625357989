import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BurgerIcon } from 'i/icons/burger.svg';
import jsonAppData from 'utils/jsonAppData';
import { cloneObjectWithoutReference, generateUniqueId, getCurrentDate } from 'utils';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfGlassDocument } from 'components/PdfComponents';
import { ConfigurationDataContext } from 'context/configuration';

const GlassConfigDropdown = ({
	parent,
	attrKey,
	glassData,
}) => {
	const { t } = useTranslation('commonConfigurationAction');
	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const { editConfiguration, totalGlassSizes } = useContext(
		ConfigurationDataContext,
	);

	const toggleDropdownState = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const removeGlassItem = () => {
		if (parent.constructor === Array) {
			// eslint-disable-next-line no-alert
			if (confirm(t('notifications:confirmationOfGlassDeletion'))) {
				parent.splice(attrKey, 1);
			}
		}
	};

	const editGlassItem = () => {
		// editing item {glassData} with index {attrKey}
		// TODO: make sure to replace the dialog (copied from delete)
		if (parent.constructor === Array) {
			// eslint-disable-next-line no-alert
			if (confirm(t('notifications:confirmationOfGlassEdit'))) {
				editConfiguration(glassData);
			}
		}
	};

	const onClickOptionHandler = action => {
		if (action === 'delete') {
			removeGlassItem();
		} else if (action === 'edit') {
			editGlassItem();
		} else if (action === 'duplicate') {
			const duplicatedGlass = cloneObjectWithoutReference(parent[attrKey]);

			duplicatedGlass.id = generateUniqueId();

			let trans = parent.transact();

			if (duplicatedGlass.positions) {
				duplicatedGlass.positions.forEach(position => {
					// eslint-disable-next-line no-param-reassign
					position.id = generateUniqueId();
				});
			}

			trans.push(duplicatedGlass);

			parent.run();
		}
	};

	const onClickBurgerHandler = () => {
		toggleDropdownState();
	};

	const onBlurBurgerButtonHandler = () => {
		setTimeout(() => {
			setDropdownOpen(false);
		}, 200);
	};

	return (
		<div className="glass_config_dropdown">
			<button
				className="glass_config_dropdown__burger"
				type="button"
				onClick={onClickBurgerHandler}
				onBlur={onBlurBurgerButtonHandler}
			>
				<BurgerIcon className="icon icon-burger" />
			</button>
			{isDropdownOpen ? (
				<ul className="glass_config_dropdown__options v2_mod">
					{jsonAppData.glassConfigDropdown.map(({
						label,
					}, index) => {
						const currentDate = getCurrentDate();
						const documentFileName = t('glassDotPdfFmt').replace('{date}', currentDate); // TODO: maybe add glass description / config code;
						return (
							<li className="glass_config_dropdown__item" key={index}>
								{label === "pdf" && glassData ? (
									<PDFDownloadLink
										document={(
											<PdfGlassDocument
												glassData={glassData}
												totalValue={totalGlassSizes?.[attrKey]}
											/>
										)}
										fileName={documentFileName}
										className="glass_config_dropdown__button"
									>
										{() => {
											return t(label);
										}}
									</PDFDownloadLink>
								) : (
									<button
										className="glass_config_dropdown__button"
										type="submit"
										onClick={() => onClickOptionHandler(label)}
									>
										{t(label)}
									</button>
								)}
							</li>
						);
					})}
				</ul>
			) : null}

		</div>
	);
};

export default GlassConfigDropdown;
