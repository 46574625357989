import React from 'react';

const MainMenuOpeningDecor = React.forwardRef(({
	mod,
}, $ref) => {
	return (
		<div className="menu_bg_w_svg opening_mod">
			<svg preserveAspectRatio="xMinYMid slice" className="menu_bg_svg" id="bb5dedde-0bca-482e-9075-fdf7f5d21212" data-name="ac43bf30-760b-46db-954e-cf64809d0355" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
				<defs>
					<clipPath id="ee193943-1c69-4fb4-8270-db731af3de98">
						<path ref={$ref} d="M1366.81-316.47C1372.16,72,1555,458.7,2138.88,334.09c23.12,90.71-73.68-227.84-192.37-227.84S1731.6,10,1731.62-108.72,1280.74-342.63,1366.81-316.47Z" style={{ fill: 'none', clipRule: 'evenodd' }} />
					</clipPath>
					<radialGradient id="eda140c9-7f1a-4e5b-b30e-14517824bcd6" cx={260.36} cy={939.63} r="0.5" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1920, 0, 0, -1080, -498940, 1015340)">
						<stop offset={0} stopColor="rgb(86,135,164)" />
						<stop offset={1} stopColor="rgb(72,115,141)" />
					</radialGradient>
				</defs>
				<g style={{ clipPath: 'url(#ee193943-1c69-4fb4-8270-db731af3de98)' }}>
					<rect width={1920} height={1080} style={{ fill: 'url(#eda140c9-7f1a-4e5b-b30e-14517824bcd6)' }} />
				</g>
			</svg>
		</div>
	);
});

export default MainMenuOpeningDecor;
