import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as BurgerIcon } from 'i/icons/burger.svg';
import ClientBlockInfo from './ClientBlockInfo';
import ClientBlockDropdown from './ClientBlockDropdown';

const ClientBlock = ({
	address,
	city,
	email,
	id,
	name,
	tel,
	zip,
	fetchAllClientsCompanies,
}) => {
	const { t } = useTranslation('contactCompanyInformation');
	const [isDropdownOpen, setDropdownOpen] = useState(false);

	const toggleDropdownPopup = () => {
		setDropdownOpen(!isDropdownOpen);
	};

	const hideDropdownPopup = () => {
		setTimeout(() => {
			setDropdownOpen(false);
		}, 200);
	};

	return (
		<div className="client_block">
			<div className="client_block__col client_block__col--company_state">
				<ClientBlockInfo
					title={t('company')}
					text={name}
				/>
			</div>
			<div className="client_block__col">
				{/* <div className="data_item_addres_w">
					<div className="data_item_title">addres</div>
					<div className="data_item_text center_mod">Hinterer Shermen 29</div>
				</div> */}
				<ClientBlockInfo
					title={t('address')}
					text={address}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					title={t('zip')}
					text={zip}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					title={t('city')}
					text={city}
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					title={t('tel')}
					text={tel}
					type="tel"
				/>
			</div>
			<div className="client_block__col">
				<ClientBlockInfo
					title={t('email')}
					type="mailto"
					text={email}
				/>
			</div>
			<div className="client_block__col client_block__col--dropdown_state">
				<div className="client_block__col_in">
					<button
						className="client_block__btn_dropdown configuration_block_controls__button--menu_state"
						onClick={toggleDropdownPopup}
						onBlur={hideDropdownPopup}
						type="button"
					>
						<BurgerIcon className="icon icon-burger" />
					</button>
					{isDropdownOpen ? (
						<ClientBlockDropdown clientCompanyId={id} fetchAllClientsCompanies={fetchAllClientsCompanies} />
					) : null}
				</div>

			</div>
			{/* <div className="data_icons_list v2_mod">
				<div className="data_item_options_w">
					<svg className="icon icon-burger ">
						<use xlinkHref="i/sprite/sprite.svg#burger" />
					</svg>
					<ul className="options_list">
						<li className="options_list_item">
							<div className="options_list_text">complete</div>
						</li>
						<li className="options_list_item">
							<div className="options_list_text">delete</div>
						</li>
					</ul>
				</div>
			</div> */}
		</div>

	);
};

export default ClientBlock;
