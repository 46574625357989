import React, {
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useGlassSize } from 'hooks';
import Freezer from 'freezer-js';
import { AdditionalPageNavigation } from 'components/AdditionalPageNavigation';
import { ConfigurationCode } from 'components/ConfigurationCode';
import { PageNavigation } from 'components/PageNavigation';
import { YourReference } from 'components/YourReference';
import { GlassConfig } from 'components/GlassConfig';
import { UploadedDocuments } from 'components/UploadedDocuments';
import { SaveAsSection } from 'components/SaveAsSection';
import { AddNewType } from 'components/AddNewType';
import { TotalInfo } from 'components/TotalInfo';
import { ConfigurationDataContext } from 'context/configuration';
import { cloneObjectWithoutReference, getCurrentDate } from 'utils';
import { useTranslation } from 'react-i18next';
import GlassConfigUtil from 'components/GlassConfig/GlassConfigUtil';
import i18n from 'utils/i18n';

const CartPage = () => {
	const {
		configuration,
		totalGlassSizes,
		totalGlassSizesSum,
		setConfiguration,
		loadConfigurationToFirebase,
	} = useContext(ConfigurationDataContext);
	const { t } = useTranslation('cartPage');

	const [configurationJsonData, setConfigurationJsonData] = useState(null);
	const [isListenerInitialized, setListenerInitialized] = useState(false);

	const currentDateRef = useRef(getCurrentDate());
	const uiHandlerRef = useRef(null);

	const frozenRef = useRef();

	useGlassSize(configurationJsonData);

	useEffect(() => {
		if (configuration && !frozenRef.current) {
			frozenRef.current = new Freezer({ json: configuration });

			setConfigurationJsonData(frozenRef.current.get().json);
			uiHandlerRef.current = window.filters_handler.ui;
		}
	}, [configuration]);

	// every time when object changes, update state
	useEffect(() => {
		if (configurationJsonData && !isListenerInitialized) {
			const listener = configurationJsonData.getListener();

			listener.on('update', currentState => {
				setConfigurationJsonData(currentState);

				const clonedConfiguration = cloneObjectWithoutReference(currentState);

				clonedConfiguration.amountGlassTypes = clonedConfiguration.glassTypes.length;
				clonedConfiguration.lastEdited = currentDateRef.current;
				clonedConfiguration.lastEditedDateInMilliseconds = Date.now();

				setConfiguration(clonedConfiguration);
				loadConfigurationToFirebase(clonedConfiguration, false);
			});

			setListenerInitialized(true);
		}
	}, [configurationJsonData, isListenerInitialized]);

	useEffect(() => {
		const langLowerCase = i18n.language.toLowerCase();
		const lang = (langLowerCase === 'en' ? 'en_US' : langLowerCase);

		if (configurationJsonData && configurationJsonData.glassTypes && configurationJsonData.glassTypes.length) {
			let newConfig = cloneObjectWithoutReference(configurationJsonData);
			newConfig.glassTypes.map((glassData) => {
				Object.assign(glassData, GlassConfigUtil.translate(glassData, lang, uiHandlerRef));
				return glassData;
			});
			setConfigurationJsonData(newConfig);
		}
		// TODO: CHECK: this is done in the i18n.language handler in Homepage.js - probably doesn't need to be done again
		// if (window.filters_handler) {
		// 	window.filters_handler.ui.i18n.setLanguage(lang);
		// }
	}, [i18n.language]);

	return (
		configuration ? (
			<section className="section">
				<div className="section__in">
					<div className="section__block">
						<div className="page_head">
							<AdditionalPageNavigation />
							<PageNavigation />
						</div>
					</div>

					{configurationJsonData ? (
						<>
							<div className="section__block">
								<ConfigurationCode
									code={configuration.code}
								/>
							</div>
							<div className="section__block">
								<YourReference
									parent={configurationJsonData}
									values={{
										reference: configurationJsonData.reference,
										cantonalInsurance: configurationJsonData.cantonalInsurance,
										buildingReference: configurationJsonData.buildingReference,
									}}
								/>
							</div>
							{configurationJsonData.glassTypes && configurationJsonData.glassTypes.length ? (
								configurationJsonData.glassTypes.map((glassData, index) => {
									return (
										<div className="section__block" key={glassData.id || index}>
											<GlassConfig
												parent={configurationJsonData.glassTypes}
												totalValues={totalGlassSizes[index]}
												glassData={glassData}
												attrKey={index}
												glassIndex={index + 1}
												showHeadingDropdownMenu
											/>
										</div>
									);
								})
							) : null}
							<div className="section__block">
								<AddNewType />
							</div>
							<div className="section__block">
								<TotalInfo {...totalGlassSizesSum} />
							</div>
							<div className="section__block">
								<UploadedDocuments uploadedDocuments={configurationJsonData.uploadedDocuments} />
								<SaveAsSection isCartPage />
							</div>
						</>
					) : null}
				</div>
			</section>
		) : (
			<section className="section section--full_screen">
				<h1 className="section__title section__title--center_hr_state">{t('cartIsEmpty')}</h1>
			</section>
		)
	);
};

export default CartPage;
