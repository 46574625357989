import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from 'context/auth';
import { USER_ROLES } from 'utils/constant';

const PrivateAdminRoute = ({ children }) => {
	const { currentUserRole } = useContext(AuthContext);

	return currentUserRole && (currentUserRole === USER_ROLES.admin || currentUserRole === USER_ROLES.superAdmin) ? children : <Navigate to="/" />;
};

export default PrivateAdminRoute;
